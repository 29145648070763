/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { AuthLoader } from '@authentication'
import { useAuth0 } from '@auth0/auth0-react'
import {
  setChannelConnectedToAnotherCC,
  setChannelIsConnected,
  setConnectedChannelInfo,
  setConnectionError,
  setMeta400Error,
  setModalContent,
  setNoWebsiteToConnectError,
  setShowFirstChannelModal,
  setShowModal,
  setMetaUserData,
} from '../../components/common/modals/slices/connectChannelsSlice'

import axios from 'axios'
import getApolloLink from '../../utils/apollo-link'
import { batch } from 'react-redux'
import { sha1 } from 'js-sha1'
import { useFlags } from 'launchdarkly-react-client-sdk'

const {
  VITE_SUNSHINE_CONNECT_URI,
  VITE_OFFICE365_CONNECT_URI,
  VITE_NYLAS_HOST,
  VITE_AUTH0_REDIRECT_URI,
} = import.meta.env

const Oauth = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const { user, getAccessTokenSilently } = useAuth0()
  const client = useApolloClient()
  const { toggleNylasV3 } = useFlags()

  const hardReloadAuthToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently({ cacheMode: 'off' })
      const link = getApolloLink(accessToken)
      client.setLink(link)
    } catch (error) {
      console.error('Error calling hardReloadAuthToken function:', error)
    }
  }

  const onError = (provider) => {
    console.error('here error connecting ', provider)

    dispatch(setConnectionError(true))
    dispatch(setModalContent(1))

    if (localStorage.getItem('onboarding') === 'true') {
      navigate('/')
      dispatch(setShowModal(false))
      dispatch(setConnectedChannelInfo({ provider }))
    } else {
      navigate('/inbox')
      dispatch(setShowModal(true))
    }
  }

  useEffect(() => {
    ;(async () => {
      let res
      let channelIsConnected = true

      const token = await getAccessTokenSilently()

      if (window.location.href.includes('oauth/callback?code')) {
        const code = searchParams.get('code')
        let url = `${VITE_NYLAS_HOST}/recieve_hosted_auth_callback?` // This is Nylas v2
        if (toggleNylasV3) {
          url = `${VITE_NYLAS_HOST}/receive_hosted_auth_vthree_callback?` // This is how we'll handle Nylas v3
        }
        res = await axios({
          url,
          method: 'post',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            code: code,
            ccid: user?.cc_id,
            directoryCode: user?.dirCode,
            redirectURI: `${VITE_AUTH0_REDIRECT_URI}/oauth/callback`,
          },
        }).catch(() => {
          onError('nylas')
        })
        channelIsConnected = !res.data.connectedToOtherCC
        dispatch(setChannelConnectedToAnotherCC(res.data.connectedToOtherCC))
      } else if (window.location.href.includes('oauth=office?code')) {
        const code = searchParams.get('code')
        res = await axios({
          url: `${VITE_OFFICE365_CONNECT_URI}`,
          method: 'post',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            code: code,
            ccid: user?.cc_id,
            directoryCode: user?.dirCode,
          },
        }).catch(() => {
          onError('office')
        })
      } else if (window.location.href.includes('oauth=meta?code')) {
        const code = searchParams.get('code')
        res = await axios({
          url: VITE_SUNSHINE_CONNECT_URI,
          method: 'post',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            code: code,
            businessId: user.businessId,
            channelType: localStorage.getItem('sunshineapp'),
            ccid: user?.cc_id,
            direcotryCode: user?.dirCode,
            agentId: user?.staffUID,
            authType: 'desktop',
            selectedPageAccessToken: '',
            pageName: '',
          },
        }).catch((error) => {
          if (error?.response?.status === 400) {
            dispatch(setMeta400Error(error?.response?.data))
            dispatch(setModalContent(1))
            dispatch(setShowModal(true))
            if (localStorage.getItem('onboarding') === 'true') {
              navigate('/')
            } else {
              navigate('/inbox')
            }
          } else {
            onError('sunshine')
          }
        })
      } else if (window.location.href.includes('oauth=webchat?webtype')) {
        const webType = searchParams.get('webtype')
        res = await axios({
          url: VITE_SUNSHINE_CONNECT_URI,
          method: 'post',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            businessId: user?.businessId,
            ccid: user?.cc_id,
            direcotryCode: user?.dirCode,
            agentId: user?.staffUID,
            websiteType: webType,
            businessIconUrl: '',
            email: user?.email,
            country: user?.country,
            channelType: 'webChat',
            authType: 'desktop',
          },
        }).catch(() => {
          onError('webchat')
        })
        channelIsConnected = res.data?.internalWebsite
      } else {
        if (localStorage.getItem('onboarding') === 'true') {
          navigate('/')
        } else {
          navigate('/inbox')
        }
        throw new Error('Unhandled OAuth.')
      }

      setLoading(false)
      await hardReloadAuthToken()
      dispatch(setConnectedChannelInfo(res.data))
      if (localStorage.getItem('onboarding')) {
        navigate('/')
        return
      }
      navigate('/inbox')

      res.data.connectedToOtherCC === true //checks channel isnt connected to another CC
        ? batch(() => {
            dispatch(setShowModal(true))
            dispatch(setModalContent(1))
          })
        : res?.data?.internalWebsite === false //checks webchat channel has a website to connect to
          ? batch(() => {
              dispatch(setShowModal(true))
              dispatch(setModalContent(1))
              dispatch(setNoWebsiteToConnectError(true))
            })
          : res?.data?.reauth === true && res?.data?.selectPage === false // if only reatuh is true display reauth modal for meta
            ? batch(() => {
                dispatch(setMetaUserData(res.data))
                dispatch(setModalContent(10))
              })
            : res?.data?.reauth === true && res?.data?.selectPage === true // if both are true display select page modal for meta
              ? batch(() => {
                  dispatch(setMetaUserData(res.data))
                  dispatch(setModalContent(11))
                })
              : dispatch(setShowFirstChannelModal(true))

      if (channelIsConnected) {
        // invoke impact conversion function when channel is connected
        if (window.ire && !Array.isArray(window.ire)) {
          const { cc_id, email } = user
          if (email && cc_id) {
            window.ire(
              'trackConversion',
              43455,
              {
                orderId: 'IR_AN_64', // passing this value will create a UUID in impact
                customerId: cc_id,
                customerEmail: sha1(email),
              },
              {
                verifySiteDefinitionMatch: true,
              },
            )
          }
        }
        dispatch(setChannelIsConnected(true))
      }
    })()
  }, [window.location.href])

  return loading && <AuthLoader width="full" height="full" />
}

export default Oauth
