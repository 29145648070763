import { useState, useEffect, useRef, forwardRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

import { ParagraphText } from '@thryvlabs/maverick'
import { Icon } from '@thryvlabs/maverick'

import { setOpenCalendar, setCurrentDate } from '../../slices/inboxSlice'

const HorizontalLine = ({ visible }) => {
  return (
    <hr
      className={`h-px text-thryv-gray-light-400 w-full  ${
        visible ? 'opacity-100' : 'opacity-0'
      }`}
    />
  )
}

const ChevronRightIcon = () => (
  <Icon
    type="solid"
    variant="chevronRight"
    height="10.12"
    width="5.63"
    color="#4D4D4D"
  />
)

export const dateMethods = function (date) {
  const providedDate = moment(date)
  const todayDate = moment().startOf('day')
  if (providedDate.isSame(todayDate, 'Day')) {
    return 'Today'
  } else if (providedDate.isSame(todayDate.clone().subtract(1, 'day'), 'day')) {
    return 'Yesterday'
  } else {
    return moment(date).format('dddd, MMMM Do')
  }
}

export const DaySeparator = forwardRef(
  (
    { className, date, title = null, subTitle, messageContainer, hideSeparator },
    ref,
  ) => {
    const dispatch = useDispatch()
    const [isNotSticky, setIsNotSticky] = useState(false)
    const separatorRef = useRef(null)
    const { openCalendar } = useSelector((state) => state.inbox)
    useEffect(() => {
      if (!messageContainer) return
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsNotSticky(entry.isIntersecting)
        },
        {
          root: messageContainer.current,
          threshold: 0.5,
          rootMargin: `-52px 0px 0px 0px`,
        },
      )
      if (messageContainer) {
        observer.observe(separatorRef.current)

        return () => {
          observer.disconnect()
        }
      }
    }, [])
    const handleDatePicker = () => {
      if (!isNotSticky) {
        dispatch(setCurrentDate(moment(subTitle)))
        dispatch(setOpenCalendar(!openCalendar))
      }
    }
    const handleScroll = () => {
      if (messageContainer?.current.scrollTop < 64) {
        separatorRef?.current?.classList.add('opacity-0')
      } else {
        separatorRef?.current?.classList.remove('opacity-0')
      }
    }

    useEffect(() => {
      if (hideSeparator) {
        if (
          messageContainer?.current.scrollHeight <=
          messageContainer?.current.clientHeight
        ) {
          separatorRef?.current?.classList.add('hidden')
        } else {
          messageContainer?.current?.addEventListener('scroll', handleScroll)
          return () => {
            messageContainer?.current?.removeEventListener('scroll', handleScroll)
          }
        }
      }
    }, [hideSeparator])

    const init = () => {
      const text = subTitle ? dateMethods(subTitle) : dateMethods(date)
      return text
    }

    const text = init()
    const renderDaySeparator = () => (
      <div
        className="flex items-center w-full transition-opacity duration-300"
        ref={separatorRef}
      >
        <HorizontalLine visible={isNotSticky} />
        <div
          className={`flex items-center justify-center px-4 h-[36px] rounded-[40px] border-[1px] border-[#CCCCCC] bg-white gap-2 ${
            !isNotSticky && 'hover:bg-[#F8F9Fb] cursor-pointer'
          } `}
          id={`day-separator-${moment(subTitle).format('YYYY-MM-DD')}`}
          onClick={handleDatePicker}
        >
          <p
            className="font-montserrat font-semibold text-[12px] leading-[20px] whitespace-nowrap"
            ref={(el) => {
              if (el) {
                el.date = moment(subTitle).toISOString()
                if (typeof ref === 'function') {
                  ref(el)
                } else if (ref) {
                  ref.current = el
                }
              }
            }}
          >
            {text}
          </p>
          <div className={`${isNotSticky ? 'hidden' : ''}`}>
            <ChevronRightIcon />
          </div>
        </div>
        <HorizontalLine visible={isNotSticky} />
      </div>
    )

    const renderTitleContent = () => (
      <div className="flex flex-col items-center mt-2">
        <ParagraphText className="font-montserrat font-semibold" variant="lg">
          {title}
        </ParagraphText>
        <ParagraphText
          className="!text-[11px] leading-[13px] font-semibold"
          variant="sm"
          color="thryv-gray-medium-500"
        >
          {moment(date).format('dddd, MMM D, YYYY [at] LT')}
        </ParagraphText>
      </div>
    )

    return (
      <div className={className} data-testid="day-separator">
        {/* checks to make sure we dont render the day and line for the first separator on the conversation */}
        {date && title ? null : renderDaySeparator()}
        {title && renderTitleContent()}
      </div>
    )
  },
)
