import { useEffect, useState } from 'react'
import { useRedux } from '@hooks'
import { searchContactByNameOrEmail, searchContactByPhone } from '@redux-toolkit'
import { Button } from '@thryvlabs/maverick'
import ContactsList from './contacts-list'
import Keypad from '@calls/keypad/keypad'
import SearchContact from './search-contact'
import OutgoingCall from '@calls/outgoing-call'
import DefaultScreen from '../recent-and-voicemail/recent-and-voicemail-components/default-screens/DefaultScreenMain'
import { ActivateKeypadIcon, ArrowUpArrowDownIcon } from '@icons'
import * as style from './contact-list-view/constact-list-view-styles'
import ContactInfoView from './contact-info-view'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import formatContactData from '../../inbox/hooks/formatContactData'
import { useConnectPhoneModal } from '../../common/modals/phone-certification-modal/connect-phone-modal/ConnectPhoneModal'
import IncomingCall from '../incoming-call'
import { setOnOutgoingCall, setPhoneValue } from '../slices/callSlice'
import CustomModal from '../../common/CustomModal/Modal'
import OutOfMinutes from '../add-minutes/OutOfMinutes'

const KEYPAD_KEYS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '*', '#']

const ContactsListView = ({
  setOpenAddMoreMinutes,
  contactInfoView,
  setContactInfoView,
  sortedOrder,
  setSortedOrder,
}) => {
  const [
    {
      contacts: { contactsList, connection, filteredContacts, phoneNumber },
    },
    dispatch,
  ] = useRedux('contacts')

  const [{ minutesRemaining: timeRemaining }] = useRedux('meetings')
  const {
    notificationPreferences: { doNotDisturb },
  } = useSelector((state) => state.userPreferences)
  const [
    { prepopulatedNumber, phoneValue, prepopulatedNumberClicked, vonageClient },
  ] = useRedux('calls')
  const [{ countryIso2 }] = useRedux('countryCode')
  const [showKeypad, setShowKeypad] = useState(false)
  const [callAllowed, setCallAllowed] = useState(false)
  const [isOpenOutOfMinutesModal, setIsOpenOutOfMinutesModal] = useState(false)
  const [mobileContactSideSheetOpen, setMobileContactSideSheetOpen] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const { onOutgoingCall, onIncomingCall } = useSelector((state) => state.calls)
  const [openConnectPhoneModal, , ConnectPhoneModal] = useConnectPhoneModal()

  const renderKeypad = showKeypad ? `h-[30vh] min-h-[224px]` : `h-0`
  const renderKeys = showKeypad ? `h-full` : ``
  /**
   * Current Support List                len len-noCounty
   * Australia    +61 (0x)   xxxx-xxxx   11  10
   * New Zealand  +64 (xx)    xxx-xxxx   11  9
   * US           +1  (xxx)   xxx-xxxx   11  10
   * Canada       +1  (xxx)   xxx-xxxx   11  10
   */
  const _formatSearchValueToPhone = (value) => {
    if (!value) return value
    const phone = value.replace(/[^\d]/g, '')

    if (countryIso2 === 'US' || countryIso2 === 'CA') {
      if (phone?.length < 4) return phone
      if (phone?.length < 7) return `(${phone.slice(0, 3)}) ${phone.slice(3)}`
      if (phone?.length === 10) setShowKeypad(true)
      return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)} - ${phone.slice(6, 10)}`
    } else if (countryIso2 === 'AU') {
      if (phone?.length < 3) return phone
      if (phone?.length < 7) return `${phone.slice(0, 2)} ${phone.slice(2)}`
      if (phone?.length === 10) setShowKeypad(true)
      if (phone?.length === 10 && phone?.startsWith('04')) {
        return `${phone.slice(0, 4)}  ${phone.slice(4, 7)}  ${phone.slice(7, 10)}`
      } else {
        return `${phone.slice(0, 2)}  ${phone.slice(2, 6)}  ${phone.slice(6, 10)}`
      }
    } else if (countryIso2 === 'NZ') {
      if (phone?.length < 3) return phone
      if (phone?.length < 6) return `(${phone.slice(0, 2)}) - ${phone.slice(2)}`
      if (phone?.length === 10) setShowKeypad(true)
      return `(${phone.slice(0, 2)}) - ${phone.slice(2, 5)} - ${phone.slice(5, 9)}`
    }
  }

  const _handleInputWithKeyboard = (e) => {
    if (e.persist) e?.persist()
    const numbersOnly = e.target.value?.replace(/[^\d]/g, '')
    if (numbersOnly?.length) {
      if (Number.isInteger(parseInt(numbersOnly))) {
        return dispatch(setPhoneValue(_formatSearchValueToPhone(numbersOnly)))
      }
    }

    return dispatch(setPhoneValue(e.target.value))
  }

  const _handleAddInputFromKeypad = (key) =>
    dispatch(setPhoneValue(_formatSearchValueToPhone(phoneValue + key)))

  const _deleteInputFromKeypad = () => {
    dispatch(setPhoneValue(phoneValue.substring(0, phoneValue?.length - 1)))
  }

  const onChange = ({ e, key, type }) => {
    if (e) {
      _handleInputWithKeyboard(e)
      const number = e.target.value.replace(/\D/g, '')
      if (/[a-zA-Z]/.test(e.target.value)) {
        dispatch(searchContactByNameOrEmail(e.target.value))
      } else {
        dispatch(searchContactByPhone(number))
      }
    }
    if (!e && key && KEYPAD_KEYS.includes(key) && type === 'key' && showKeypad) {
      _handleAddInputFromKeypad(key)
      const number = (phoneValue + key).replace(/\D/g, '')
      dispatch(searchContactByPhone(number))
    }
    if ((!key || !e) && type === 'delete') _deleteInputFromKeypad()
  }

  useEffect(() => {
    let isPhone = true
    if (phoneValue)
      isPhone = Number.isInteger(parseInt(phoneValue.replace(/[^\d]/g, '')))
    const length = phoneValue.length
    // 17 for mobile
    if (isPhone && length >= 9) {
      setCallAllowed(true)
    }

    if (isPhone && length < 9) {
      setCallAllowed(false)
    }
  }, [phoneValue, setCallAllowed])

  const formatPrepopulatedNumber = (number) => {
    //remove country code from preformatted number
    if (number[0] === '+') number = number.slice(1)
    if (countryIso2 === 'US' || countryIso2 === 'CA') return number.slice(1)
    if (countryIso2 === 'AU' || countryIso2 === 'NZ') return number.slice(2)
  }

  const populateSearchWithPrepopulatedNumber = () => {
    //Remove the country code from prepopulated number
    onChange({
      e: { target: { value: formatPrepopulatedNumber(prepopulatedNumber) } },
    })
    // _setPhoneValue(prepopulatedNumber)
    setShowKeypad(false)
  }

  useEffect(() => {
    if (prepopulatedNumber.length > 0) populateSearchWithPrepopulatedNumber()
  }, [prepopulatedNumber, prepopulatedNumberClicked])

  useEffect(() => {
    if (timeRemaining <= 0 && onOutgoingCall && vonageClient.isInit) {
      setIsOpenOutOfMinutesModal(true)
      dispatch(setOnOutgoingCall(false))
    }
  }, [onOutgoingCall, timeRemaining, vonageClient])

  const handleOnKeyDownSearchContact = (e) => {
    if (!showKeypad) {
      return
    }

    if (e.key === 'Enter' && callAllowed) {
      setShowKeypad(false)
      dispatch(setOnOutgoingCall(true))
    }
  }

  const renderOutgoinCall =
    //phoneValue.length >= 10 &&
    timeRemaining > 0 && vonageClient.isInit && onOutgoingCall && !onIncomingCall ? (
      <OutgoingCall phoneValue={phoneValue} isMobile={isMobile} />
    ) : null

  const renderIncomingCall =
    vonageClient.isInit && !onOutgoingCall ? (
      <IncomingCall
        isMobile={isMobile}
        timeRemaining={timeRemaining}
        setShowKeypad={setShowKeypad}
      />
    ) : null

  const renderAddNumber = !connection ? (
    <Button
      variant="text"
      level={1}
      onClick={openConnectPhoneModal}
      className="text-base font-montserrat"
    >
      Get Your Number
    </Button>
  ) : null

  const renderPhoneNumber =
    connection && phoneNumber ? (
      <span className={style.phoneNumber}>
        Your number: {formatContactData(phoneNumber)}
      </span>
    ) : null

  const renderContactsList =
    !onOutgoingCall &&
    (!onIncomingCall || timeRemaining <= 0 || doNotDisturb) &&
    connection ? (
      <div className={style.container}>
        <div className={style.header}>
          <div className="w-full flex justify-between items-start  font-montserrat font-semibold text-sm text-[#231F20] z-10">
            Contacts
          </div>
          <button
            onClick={() => {
              if (sortedOrder === 'asc') {
                setSortedOrder('desc')
              } else {
                setSortedOrder('asc')
              }
            }}
          >
            {contactsList?.length > 0 && (
              <ArrowUpArrowDownIcon width="24" height="24" />
            )}
          </button>
        </div>
        <ContactsList
          contactsList={filteredContacts}
          setShowKeypad={setShowKeypad}
          connection={connection}
          setMobileContactSideSheetOpen={setMobileContactSideSheetOpen}
          setContactInfoView={setContactInfoView}
        />
      </div>
    ) : null

  const renderSearch = (
    <SearchContact
      callAllowed={callAllowed}
      onChange={onChange}
      value={phoneValue}
      setShowKeypad={setShowKeypad}
      onKeyDown={handleOnKeyDownSearchContact}
    />
  )

  const renderKeypadCTA = (
    <div
      className={`absolute bottom-0  h-auto w-[334px] mx-2 ${
        showKeypad ? 'bg-[#F8F9FB] scale-y-1' : 'bg-none scale-y-0'
      } origin-bottom rounded-t-[15px] flex flex-col items-center justify-start z-10 pt-8 pb-3 px-[41px] transition-all duration-200 ease-in-out`}
    >
      <div
        className={`${renderKeypad}  w-full  transition-all duration-200 ease-in-out`}
      >
        <Keypad
          renderKeys={renderKeys}
          onChange={onChange}
          setShowKeypad={setShowKeypad}
          callAllowed={callAllowed}
        />
      </div>
    </div>
  )

  if (contactsList.length === 0 && isMobile) {
    return <DefaultScreen tabSelected="recent" isMobile={isMobile} />
  }

  if (mobileContactSideSheetOpen && isMobile) {
    return (
      <div className="relative w-[350px] md:w-full h-full flex flex-col justify-between bg-white md:border-none border-r border-[#F1F1F5]">
        <div id="test" className="">
          <div className="">
            <ContactInfoView
              connection={true}
              setMobileContactSideSheetOpen={setMobileContactSideSheetOpen}
              setContactInfoView={setContactInfoView}
              contactInfoView={contactInfoView}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="relative w-[350px] md:w-full h-full flex flex-col justify-between bg-white md:border-none border-r border-[#F1F1F5]">
        <div>
          <div className="md:hidden w-full h-[82px] p-6 flex items-start justify-between font-montserrat font-semibold text-2xl border-b border-[#ECEEF1]">
            <span>Calls</span>
            {connection && (
              <Button
                variant="primary"
                className="flex px-2 py-3px"
                onClick={() => setShowKeypad(!showKeypad)}
              >
                <ActivateKeypadIcon className={'my-auto'} />
                <span className="ml-[10px] my-auto font-open-sans text-white font-semibold text-[13px] leading-[14px]">
                  Dialpad
                </span>
              </Button>
            )}
          </div>
          <CustomModal
            isModalForm={false}
            showTitle={false}
            hasCrossIcon={true}
            isOpen={isOpenOutOfMinutesModal}
            setIsOpen={setIsOpenOutOfMinutesModal}
            close={() => setIsOpenOutOfMinutesModal(false)}
            width={'500px'}
          >
            <OutOfMinutes
              setOpenAddMoreMinutes={setOpenAddMoreMinutes}
              close={() => setIsOpenOutOfMinutesModal(false)}
            />
          </CustomModal>
          <div
            className={`md:hidden w-full h-[72px] p-6 flex items-start font-montserrat font-semibold text-2xl ${
              !connection || 'border-b'
            } border-[#ECEEF1]`}
          >
            {renderAddNumber}
            {renderPhoneNumber}
          </div>
          <div className="w-full h-auto">
            {renderSearch}
            <div className="w-full h-auto mt-[18px] z-0">
              {renderOutgoinCall}
              {renderIncomingCall}
              {renderContactsList}
            </div>
          </div>
          {renderKeypadCTA}
        </div>
      </div>
      {ConnectPhoneModal}
    </>
  )
}

export default ContactsListView
