// userSlice.js

import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    fname: '',
    lname: '',
    email: '',
    role: '',
    language: '',
    translate: false,
  },
  reducers: {
    setUserInfo: (state, action) => {
      const {
        fname,
        lname,
        email,
        role,
        language = state.language,
        translate = state.translate,
      } = action.payload
      state.fname = fname
      state.lname = lname
      state.email = email
      state.role = role
      state.language = language
      state.translate = translate
    },
  },
})

export const { setUserInfo } = userSlice.actions
export const selectUser = (state) => state.user

export default userSlice.reducer
