import { useEffect, useState, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { Button } from '@thryvlabs/maverick'
import { Transition } from '@headlessui/react'

import {
  setSearchingDate,
  setOpenCalendar,
  setCurrentDate,
  setNumOfDates,
} from '../../slices/inboxSlice'
import { DatePickerHeader } from './date-picker-header'
import { buildDatePicker, styleDates } from './helpers'
import { getPrevMonth, getNextMonth } from './utils'

import { FETCH_SK1_BY_THREAD_ID } from '../../../../graphql/queries'

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const DatePicker = ({ messageContentRef }) => {
  const dispatch = useDispatch()

  const { currentDate, openCalendar } = useSelector((state) => state.inbox)
  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )
  const numOfDates = useSelector((state) => state.inbox.numOfDates)

  const [initialDate] = useState(currentDate)
  const [calendarMoment, setCalendarMoment] = useState()
  const [focusedDay, setFocusedDay] = useState(currentDate || moment())
  const [calendar, setCalendar] = useState([])
  const [cellIsFocused, setCellIsFocused] = useState(false)
  const [dates, setDates] = useState([])
  const [hasNextToken, setHasNextToken] = useState(true)
  const [disableSelect, setDisableSelect] = useState(false)

  const calendarCellRef = useRef(null)
  const datePickerRef = useRef(null)

  const { data, refetch } = useQuery(FETCH_SK1_BY_THREAD_ID, {
    variables: {
      threadid: selectedThreadId,
      first: numOfDates,
    },
    skip: !selectedThreadId || selectedThreadId === '',
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      if (data?.queryMessagesByThread?.nextToken) {
        setHasNextToken(true)
      } else {
        setHasNextToken(false)
      }
    },
  })

  useEffect(() => {
    if (data) {
      const dates = data.queryMessagesByThread.items.map((item) => item.sk1)
      setDates(dates)
    }
  }, [data])

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    if (!calendarMoment && setCalendarMoment) {
      setCalendarMoment(currentDate || moment())
    }
    if (calendarMoment) {
      setCalendar(buildDatePicker(moment(calendarMoment)))
    }
  }, [calendarMoment])

  useEffect(() => {
    fetchMoreDatesIfNeeded()
  }, [calendar, dates, hasNextToken])

  const handleClose = () => {
    dispatch(setOpenCalendar(false))
  }

  const handleOutsideClick = (e) => {
    const daySeparatorEl = document.getElementById(
      `day-separator-${currentDate.format('YYYY-MM-DD')}`,
    )
    const rect = daySeparatorEl.getBoundingClientRect()
    const isInBounds =
      e.clientX >= rect.left &&
      e.clientX <= rect.right &&
      e.clientY >= rect.top &&
      e.clientY <= rect.bottom
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(e.target) &&
      !isInBounds
    ) {
      dispatch(setOpenCalendar(false))
    }
  }

  const handleOnChange = (day) => {
    if (
      dates.some((date) => day.isSame(date, 'day')) ||
      day.isSame(moment(), 'day')
    ) {
      setDisableSelect(false)
    } else {
      setDisableSelect(true)
    }
    dispatch(setCurrentDate(day))
  }

  const fetchMoreDatesIfNeeded = () => {
    if (calendar.length > 0 && dates.length > 0) {
      const firstDateInCalendar = calendar[0]?.[0]
      const earliestDateInList = moment(dates.at(-1))
      if (firstDateInCalendar.isBefore(earliestDateInList, 'day') && hasNextToken) {
        dispatch(setNumOfDates(numOfDates + 100))
        refetch()
      }
    }
  }

  const handleDateSelection = (day) => {
    dispatch(setOpenCalendar(false))
    dispatch(setSearchingDate({ searchingDate: day, initialDate: initialDate }))
  }

  const handleDateSelectionKB = (date) => {
    setFocusedDay(date)
  }
  const setPreviousDay = (day) => {
    const previousDay = day.subtract(1, 'days')
    handleDateSelectionKB(previousDay)
  }
  const setNextDay = (day) => {
    const nextDay = day.add(1, 'days')
    handleDateSelectionKB(nextDay)
  }
  const setPreviousWeek = (day) => {
    const previousWeek = day.subtract(1, 'weeks')
    handleDateSelectionKB(previousWeek)
  }
  const setNextWeek = (day) => {
    const nextWeek = day.add(1, 'weeks')
    handleDateSelectionKB(nextWeek)
  }

  // e.preventDefault() placed on certain keys due to the need for preventing particular default behaviors while other default behaviors for keys like "tab" are necessary.
  const handleCalendarKeyPress = (e, day) => {
    const key = e.key
    const momentDay = moment(day) // day is wrapped in moment, styling functionalities won't work if moment wrapper is removed.
    switch (key) {
      case 'Enter': // Enter
        e.preventDefault()
        handleDateSelection(momentDay)
        return
      case 'Escape': // Esc
        dispatch(setOpenCalendar(false))
        return
      case 'Esc': // Esc
        dispatch(setOpenCalendar(false))
        return
      case ' ': // Space
        e.preventDefault()
        handleDateSelection(momentDay)
        return
      case 'Spacebar': // Space
        e.preventDefault()
        handleDateSelection(momentDay)
        return
      case 'ArrowLeft': // left
        e.preventDefault()
        setPreviousDay(momentDay)
        return
      case 'ArrowUp': // up
        e.preventDefault()
        setPreviousWeek(momentDay)
        return
      case 'ArrowRight': // right
        e.preventDefault()
        setNextDay(momentDay)
        return
      case 'ArrowDown': // down
        e.preventDefault()
        setNextWeek(momentDay)
        return
    }
  }

  // makes sure the calendar changes months when you go from one month to another while navigating through the calendar cells
  useEffect(() => {
    if (openCalendar) {
      if (focusedDay?.isAfter(calendarMoment, 'month')) {
        if (calendarMoment && setCalendarMoment) {
          setCalendarMoment(getNextMonth(calendarMoment))
        }
      }
      if (focusedDay?.isBefore(calendarMoment, 'month')) {
        if (calendarMoment && setCalendarMoment) {
          setCalendarMoment(getPrevMonth(calendarMoment))
        }
      }
    }
  }, [focusedDay])

  useEffect(() => {
    if (openCalendar && calendarCellRef.current && cellIsFocused)
      calendarCellRef.current.focus()
  })

  // built for when the user hits an arrow button to navigate to a new month. KB will focus the 1st date of the month when focused
  useEffect(() => {
    const calendarMonth = calendarMoment?.month()
    const calendarYear = calendarMoment?.year()
    if (calendarMonth && calendarYear && calendarMonth !== focusedDay?.month()) {
      if (
        currentDate &&
        calendarMonth === currentDate.month() &&
        calendarYear === currentDate.year()
      ) {
        setFocusedDay(currentDate)
      } else {
        const newFocusedDate = moment()
          .year(calendarYear)
          .month(calendarMonth)
          .date(1)
        setFocusedDay(newFocusedDate)
      }
    }
  }, [calendarMoment])

  useEffect(() => {
    let scrollCount = 0

    const handleScroll = () => {
      if (scrollCount > 30) {
        dispatch(setOpenCalendar(false))
      } else {
        scrollCount += 1
      }
    }

    const handleTouchMove = () => {
      if (scrollCount > 30) {
        dispatch(setOpenCalendar(false))
      } else {
        scrollCount += 1
      }
    }

    messageContentRef?.current.addEventListener('wheel', handleScroll)
    messageContentRef?.current.addEventListener('touchmove', handleTouchMove)

    return () => {
      messageContentRef?.current.removeEventListener('wheel', handleScroll)
      messageContentRef?.current.addEventListener('touchmove', handleTouchMove)
    }
  }, [])

  useEffect(() => {
    if (!cellIsFocused) {
      let scrollCount = 0
      const handleKeyDown = (event) => {
        if (event.key === 'ArrowUp') {
          if (scrollCount > 1) {
            dispatch(setOpenCalendar(false))
          } else {
            scrollCount += 1
          }
        } else if (event.key == 'ArrowDown') {
          if (scrollCount < -1) {
            dispatch(setOpenCalendar(false))
          } else {
            scrollCount -= 1
          }
        }
      }

      document.addEventListener('keydown', handleKeyDown)

      return () => {
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [cellIsFocused])

  return (
    <Transition
      show={openCalendar}
      enter="transition-all ease-in duration-200"
      enterFrom="opacity-100"
      enterTo="opacity-100"
      leave="transition-all ease-out duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className="bg-white max-w-[285px] w-full left-1/2 transform -translate-x-1/2 shadow-md shadow-thryv-gray-light-600 relative top-2"
        ref={datePickerRef}
      >
        <div className="p-5 pb-1">
          <DatePickerHeader
            calendarMoment={calendarMoment}
            setCalendarMoment={setCalendarMoment}
            setFocusedDay={setFocusedDay}
          />
          <div className="flex justify-between items-center gap-x-2 mt-3 mb-3">
            {daysOfWeek.map((d, i) => (
              <div
                className="font-open-sans flex items-center justify-around w-full text-thryv-gray-medium-500 font-normal text-day-of-week"
                key={i}
              >
                {d}
              </div>
            ))}
          </div>
          <>
            {calendar.map((week, i) => (
              <div
                key={i}
                className="flex justify-start items-center gap-2 mt-2 mb-2"
              >
                {week.map((day, j) => {
                  return (
                    <div
                      key={j}
                      onClick={() => {
                        handleOnChange(day)
                      }}
                      className="w-full h-[25px]"
                      data-datepicker-date={`${day.format('YYYY-MM-DD')}`}
                      aria-label={`${day.format('YYYY-MM-DD')}`}
                      ref={
                        focusedDay?.isSame(day, 'day') &&
                        calendarMoment?.isSame(day, 'month')
                          ? calendarCellRef
                          : undefined
                      }
                      tabIndex={focusedDay?.isSame(day, 'day') ? 0 : -1}
                      onKeyDown={(e) => handleCalendarKeyPress(e, day)}
                      onFocus={() => setCellIsFocused(true)}
                      onBlur={() => setCellIsFocused(false)}
                    >
                      {calendarMoment && (
                        <span
                          className={`${styleDates({
                            day,
                            value: currentDate,
                            currentMonth: calendarMoment?.format('MMMM'),
                            dates,
                          })} font-open-sans font-semibold text-calendar-date flex justify-around w-full h-full items-center`}
                        >
                          {day.format('D')}
                        </span>
                      )}
                    </div>
                  )
                })}
              </div>
            ))}
          </>
        </div>
        <div className="flex justify-end items-center py-[15px] pr-[18px] border-t border-thryv-gray-light-400">
          <div className="flex items-center gap-[23px]">
            <Button
              variant="text"
              type="button"
              level={2}
              onClick={handleClose}
              style={{ color: '#808080' }}
            >
              Cancel
            </Button>
            <Button
              disabled={disableSelect}
              variant="primary"
              type="button"
              onClick={() => {
                handleDateSelection(currentDate)
              }}
            >
              Select
            </Button>
          </div>
        </div>
      </div>
    </Transition>
  )
}
