import { useDispatch, useSelector, batch } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import MarkAsMenu from './mark-as-menu'
import moment from 'moment'
import {
  setSelectedThread,
  setTimeframe,
  setShowInboxNotification,
  setSelectedThreadCompleted,
  setSelectedThreadTrash,
  setSelectedThreadSpam,
  setMostRecentThread,
  setShowMarkAsMenu,
  setUnreadThreadIds,
  setBulkSelectAllBtnChecked,
} from '../slices/inboxThreadsSlice'
import { useInboxMenuConversations } from './hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import {
  midnight,
  yesterdayMidnightTime,
  startOfLastWeekMidnightTime,
  startOfLastMonthMidnightTime,
  startOfLastYearMidnightTime,
} from './helper-functions/getTimeThreads'
import { setSelectedInboxMobilePage } from '../slices/inboxSlice'
import { createSearchParams, useNavigate } from 'react-router-dom'
import formatContactData from '../hooks/formatContactData'
import { FETCH_THREADS } from '../../../graphql/queries/inbox-queries'
import { UPDATE_THREAD_FIELD } from '../../../graphql'
import { useMediaQuery } from 'react-responsive'
import {
  setInboxNotifications,
  setCallsNotifications,
} from '../../../redux-toolkit/slices/notifications/notifications-slice'

// Components
import { InboxMenuResizeWrapper } from './inbox-menu-resize-wrapper'
import { InboxMenuHeader } from './inbox-menu-header'
import { ConversationRow, ConversationTable } from './conversation-components'
import { Notification } from '../../common/notification'
import { Transition } from '@headlessui/react'
import RoundedActionButton from '../common/rounded-action-button'
import {
  handleStartNewMessage,
  setConversationLimit,
  setNumOfDates,
  setSearchingDate,
  setOpenCalendar,
  setCurrentDate,
} from '../slices/inboxSlice'
import { setEditorView } from '../slices/messageComposeSlice'
import {
  setShowFormattingToolbar,
  setSendButtonClicked,
} from '../slices/messageComposeSlice'
import { ConversationSkeleton, SeeMoreConversationsButton } from './components'
import { Icon, TableFormAlert } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../command-center-icon'
import CustomModal from '../../common/CustomModal/Modal'
import RemoveConversationModal from './modals/remove-conversation-modal'
import PinConversationModal from './modals/pin-conversation-modal'
import { SearchView } from './components/search-view/search-view'
import { GET_MESSAGES_BY_PHONE_CHANNEL } from '../../../graphql'

export const InboxMenu = () => {
  const { initMessageThreads, initPinnedMessageThreads, createVariables } =
    useInboxMenuConversations()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const markAsRef = useRef(null)
  const {
    todayMessageThreads,
    yesterdayMessageThreads,
    lastWeekMessageThreads,
    lastMonthMessageThreads,
    lastYearMessageThreads,
    overOneYearMessageThreads,
  } = useSelector((state) => state.inbox.timeThreads)

  const { customMessageThreads, pinnedMessageThreads, hideThreadsMenu, isLoading } =
    useSelector((state) => state.inbox)

  const {
    inboxFilter,
    inboxSort,
    inboxAdvancedFilters,
    inboxNotification,
    bulkEditBtnClicked,
    bulkSelectedThreadIds,
    showMarkAsMenu,
    inboxThreadSk1s,
    inboxPinnedThreadSk1s,
  } = useSelector((state) => state.inboxThreads)
  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )
  const { phoneChannelID } = useSelector((state) => state.contacts.contacts)
  const { sendButtonClicked } = useSelector((state) => state.messageCompose)
  const pinnedIds = []
  const [threadsError, setThreadsError] = useState(false)
  const [allThreads, setAllThreads] = useState([])
  const [seeMoreClicked, setSeeMoreClicked] = useState(false)
  const [openRemoveConversationModal, setOpenRemoveConversationModal] =
    useState(false)
  const [openPinConversationModal, setOpenPinConversationModal] = useState(false)
  const hasScrolled = useRef(false)

  const messages = useQuery(FETCH_THREADS, {
    fetchPolicy: 'no-cache',
    variables: createVariables(),
    errorPolicy: 'ignore',
    onCompleted: () => {
      const threads = messages?.data?.queryThreads?.items
      const pinned = messages?.data?.pinned?.items
      initPinnedMessageThreads(pinned)
      setAllThreads(threads)
      initMessageThreads(threads)
      const unreadThreadIds = []
      const threadIds = []
      threads.map((t) => {
        if (!t?.read) {
          unreadThreadIds.push(t?.thread_id)
        }
        threadIds.push(t?.thread_id)
      })
      dispatch(setUnreadThreadIds(unreadThreadIds))
      setSeeMoreClicked(false)
      dispatch(
        setMostRecentThread([
          {
            id: threads?.at(0)?.thread_id,
            name: threads?.at(0)?.thread_name,
          },
          {
            id: threads?.at(1)?.thread_id,
            name: threads?.at(1)?.thread_name,
          },
        ]),
      )

      if (messages.error) {
        setThreadsError(true)
      }
    },
  })

  const sortThreads = () => {
    const pinnedThreads = messages?.data?.queryThreads?.items?.filter(
      (x) => x?.pinsk !== null,
    )
    const threads = messages?.data?.queryThreads?.items?.filter(
      (x) => x?.pinsk === null,
    )
    if (threads?.length) initMessageThreads(threads)
    if (pinnedThreads?.length) initPinnedMessageThreads(pinnedThreads)
  }

  useEffect(() => {
    sortThreads()
  }, [inboxSort])

  const [isFirstRender, setIsFirstRender] = useState(false)

  useEffect(() => {
    if (
      !todayMessageThreads.length &&
      !yesterdayMessageThreads.length &&
      !lastWeekMessageThreads.length &&
      !lastMonthMessageThreads.length &&
      !lastYearMessageThreads.length &&
      !overOneYearMessageThreads.length &&
      !pinnedMessageThreads.length &&
      !customMessageThreads.length &&
      messages.loading
    ) {
      setIsFirstRender(true)
    } else {
      setIsFirstRender(false)
    }
  }, [
    todayMessageThreads,
    yesterdayMessageThreads,
    lastWeekMessageThreads,
    lastMonthMessageThreads,
    lastYearMessageThreads,
    overOneYearMessageThreads,
    pinnedMessageThreads,
    customMessageThreads,
  ])

  const { search } = useSelector((state) => state.inboxSearch)

  /* ----- THREADS DATA  ----- */

  const getCorrectMessagePreview = (messages) => {
    const currMessage = messages[0]
    if (!currMessage || !messages || !currMessage.preview?.length) {
      return '[No Body Content]'
    }

    const cleanedMessage = currMessage.preview
      .replace(/<\/?[^>]+(>|$)/g, ' ')
      .replace(/&nbsp;/g, ' ')
      .replace(/\n/g, ' ')

    if (currMessage.email_snippet) {
      return currMessage.email_snippet.replace(/\n/g, '')
    }

    if (currMessage?.preview === '<' && currMessage.agent_id !== null) {
      return cleanedMessage
    }

    if (currMessage.item_type === 'VOICEMAIL') {
      return '[Voicemail]'
    }

    if (cleanedMessage.length > 85) {
      return cleanedMessage.substring(0, 85) + '...'
    } else {
      return cleanedMessage
    }
  }

  const [getRecentCalls, { data: recentCallsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,

    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        const unreadCalls = recentCallsData.queryMessagesByPhoneChannel.filter(
          (x) => !x.is_read,
        ).length

        dispatch(
          setCallsNotifications({
            calls: unreadCalls || 0,
          }),
        )
      },
    },
  )

  const [getRecentVoicemails, { data: recentVoicemailsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        const unreadVoicemails =
          recentVoicemailsData.queryMessagesByPhoneChannel.filter(
            (x) => !x.is_read,
          ).length

        dispatch(
          setCallsNotifications({
            voicemails: unreadVoicemails || 0,
          }),
        )
      },
    },
  )

  const fetchVoicemailsAndCalls = () => {
    getRecentVoicemails({
      variables: {
        chanpk: phoneChannelID,
        item_type: 'VOICEMAIL',
      },
    })
    getRecentCalls({
      variables: {
        chanpk: phoneChannelID,
        item_type: 'CALL',
      },
    })
  }

  const unreadThreadsData = useQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
    variables: {
      folder: 'unread',
    },
    onCompleted: () => {
      const threads = unreadThreadsData?.data?.queryThreads?.items?.filter(
        (x) => x?.messages?.items?.length > 0,
      )
      const pinned = unreadThreadsData?.data?.pinned?.items?.filter(
        (x) => x?.messages?.items?.length > 0 && x?.read === false,
      )
      dispatch(setInboxNotifications(threads?.length + pinned?.length || 0))
    },
  })

  const [getUnreadThreads, { data }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
    variables: {
      folder: 'unread',
    },
    onCompleted: () => {
      const threads = data?.queryThreads?.items.filter(
        (x) => x.messages.items.length > 0,
      )
      const pinned = data?.pinned?.items.filter(
        (x) => x.messages.items.length > 0 && x.read === false,
      )
      dispatch(setInboxNotifications(threads.length + pinned.length || 0))
    },
  })

  const [updateToRead] = useMutation(UPDATE_THREAD_FIELD, {})

  const updateSelectedThread = (threadId, threadName, threadType, email) => {
    const threads = [...inboxThreadSk1s, ...inboxPinnedThreadSk1s]
    const sk1 = [threads.filter((x) => x.id === threadId)[0]?.sk1]
    const spamsk = email.spamsk
    const trashsk = email.trashsk
    //ADD TRASH HERE FOR TRASH NOTIFICATION
    dispatch(setShowFormattingToolbar(false))
    if (email.completedsk) {
      dispatch(setSelectedThreadCompleted(true))
    } else {
      dispatch(setSelectedThreadCompleted(false))
    }
    if (email.spamsk) {
      dispatch(setSelectedThreadSpam(true))
    } else {
      dispatch(setSelectedThreadSpam(false))
    }
    if (email.trashsk) {
      dispatch(setSelectedThreadTrash(true))
    } else {
      dispatch(setSelectedThreadTrash(false))
    }
    dispatch(
      setSelectedThread({
        id: threadId,
        name: threadName,
        spamsk: spamsk,
        trashsk: trashsk,
      }),
    )

    dispatch(setSelectedInboxMobilePage('message-content'))

    let pathToNavigateTo = '/inbox'

    navigate({
      pathname: pathToNavigateTo,
      search: createSearchParams({ search: search }).toString(),
    })

    if (!email.read) {
      updateToRead({
        variables: {
          sk1s: sk1,
          field: 'read',
        },
        onCompleted: () => {
          messages.refetch()
          getUnreadThreads()
          fetchVoicemailsAndCalls()
        },
      })
    }
  }

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      setAllThreads([...threads, ...allThreads])
      initMessageThreads([...threads, ...allThreads])
      setSeeMoreClicked(false)
    },
  })

  useEffect(() => {
    if (
      threadsData &&
      threadsData?.queryThreads.items.length === 0 &&
      threadsData?.queryThreads?.nextToken
    ) {
      getThreads({
        variables: createVariables(threadsData?.queryThreads?.nextToken, 50),
      })
    }
  }, [threadsData])

  const [getMarkAsThreads, { data: markAsThreadsData }] = useLazyQuery(
    FETCH_THREADS,
    {
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
      variables: createVariables(),
      onCompleted: () => {
        const threads = markAsThreadsData?.queryThreads.items
        initMessageThreads(threads)
        const pinned = markAsThreadsData?.pinned?.items
        initPinnedMessageThreads(pinned)
        const unreadThreadIds = []
        const threadIds = []
        threads.map((t) => {
          if (!t?.read) {
            unreadThreadIds.push(t?.thread_id)
          }
          threadIds.push(t?.thread_id)
        })
        dispatch(setUnreadThreadIds(unreadThreadIds))
      },
    },
  )

  const incrementThreadLimitAndFetch = () => {
    setSeeMoreClicked(true)
    dispatch(setBulkSelectAllBtnChecked(false))
    if (threadsData) {
      getThreads({
        variables: createVariables(threadsData?.queryThreads?.nextToken, 50),
      })
    } else {
      getThreads({
        variables: createVariables(messages?.data?.queryThreads?.nextToken, 50),
      })
    }
  }

  const isMobile = useMediaQuery({ maxWidth: 835 })

  const onComposeClick = () => {
    dispatch(setEditorView('closed'))
    dispatch(handleStartNewMessage(true))

    let pathToNavigateTo = '/inbox'
    navigate({
      pathname: pathToNavigateTo,
      search: createSearchParams({ search: search }).toString(),
    })
  }

  const onMobileContactComposeClick = () => {
    dispatch(setSelectedInboxMobilePage('message-content'))
    dispatch(
      setSelectedThread({
        id: '',
        name: '',
        spamsk: '',
        trashsk: '',
      }),
    )
    // open the contact compose section
    onComposeClick()
  }

  const handleCursor = () => {
    if (bulkSelectedThreadIds.length) {
      return 'hover:cursor-pointer'
    } else {
      return 'hover:cursor-default'
    }
  }

  const handleColorChange = () => {
    if (bulkSelectedThreadIds.length) {
      return `#4D4D4D`
    } else {
      return `#A3A5A7`
    }
  }

  const handleMarkAsClick = () => {
    if (bulkSelectedThreadIds.length) {
      dispatch(setShowMarkAsMenu(!showMarkAsMenu))
    } else {
      dispatch(setShowMarkAsMenu(false))
    }
  }
  const handleDeleteClick = () => {
    if (bulkSelectedThreadIds.length) {
      setOpenRemoveConversationModal(true)
    } else {
      setOpenRemoveConversationModal(false)
    }
  }
  const handlePinClick = () => {
    if (bulkSelectedThreadIds.length) {
      setOpenPinConversationModal(true)
    } else {
      setOpenPinConversationModal(false)
    }
  }

  pinnedMessageThreads.forEach((id) => {
    pinnedIds.push(id.thread_id)
  })

  const [unpinConversation, setUnpinConversation] = useState(false)

  const checkPinned = () => {
    const hasPinned = bulkSelectedThreadIds.some((id) => pinnedIds.includes(id))
    const onlyPinned = bulkSelectedThreadIds.every((id) => pinnedIds.includes(id))

    if (!bulkSelectedThreadIds.length || (!hasPinned && !onlyPinned)) {
      return 'Pin'
    } else if (hasPinned && !onlyPinned) {
      return 'Pin'
    } else if (onlyPinned) {
      return 'Unpin'
    }
  }

  useEffect(() => {
    if (checkPinned() === 'Unpin') {
      setUnpinConversation(true)
    } else {
      setUnpinConversation(false)
    }
  }, [unpinConversation, bulkSelectedThreadIds, pinnedIds])

  const isDateInRange = (dateToCheck, endDate, type) => {
    const momentEndDate = moment(endDate).valueOf()
    if (type === 'Any') return true
    return dateToCheck >= momentEndDate
  }
  // scroll to the selected Thread
  const handleClickToScroll = (tId) => {
    const element = document.getElementById(tId)
    const container = document.getElementById('inbox-menu-scrollable-container')
    if (element && container) {
      const containerScrollTop = container.scrollTop
      const containerRect = container.getBoundingClientRect()
      const elementRect = element.getBoundingClientRect()
      const offsetToContainerTop = elementRect.top - containerRect.top
      container.scrollTo({
        top: containerScrollTop + offsetToContainerTop,
        behavior: 'smooth',
      })
    }
  }
  // make sure DOM is fully updated before performing scroll action
  useEffect(() => {
    if (selectedThreadId && !hasScrolled.current && !isLoading) {
      setTimeout(() => handleClickToScroll(selectedThreadId), 500)
      hasScrolled.current = true
    }
  }, [selectedThreadId, isLoading])

  return (
    <InboxMenuResizeWrapper>
      <InboxMenuHeader />
      <div
        id="inbox-menu-scrollable-container"
        className="border-t-[1px] border-solid border-[#F1F1F5] overflow-y-auto flex-grow sm:overflow-y-auto md:overflow-y-visible md:border-0 !scroll-smooth"
      >
        {bulkEditBtnClicked ? (
          <div className="w-full bg-[#E3E6E8] py-[20px] flex justify-evenly relative">
            {' '}
            <>
              <div
                data-testid="bulk-trash"
                className={`flex gap-1 font-open-sans font-semibold text-[14px] leading-[20px] text-[#4D4D4D]  ${handleCursor()}`}
              >
                <Icon
                  type="regular"
                  variant="altTrash"
                  color={handleColorChange()}
                  height="18"
                  width="16"
                  onClick={handleDeleteClick}
                />
                <span
                  className={`text-[${handleColorChange()}]
          `}
                  onClick={handleDeleteClick}
                >
                  Trash
                </span>
              </div>
              <CustomModal
                isModalForm={true}
                isOpen={openRemoveConversationModal}
                setIsOpen={setOpenRemoveConversationModal}
                handleClose={() => {
                  setOpenRemoveConversationModal(false)
                }}
                width={isMobile ? '300px' : '500px'}
              >
                <RemoveConversationModal
                  setOpenRemoveConversationModal={setOpenRemoveConversationModal}
                />
              </CustomModal>
              <div
                data-testid="bulk-mark-as"
                className={`flex gap-1 font-open-sans font-semibold text-[14px] leading-[20px] text-[#4D4D4D] ${handleCursor()}`}
              >
                <Icon
                  type="regular"
                  variant="checkSquare"
                  color={handleColorChange()}
                  height="18"
                  width="16"
                  onClick={handleMarkAsClick}
                  ref={markAsRef}
                />
                <span
                  className={`text-[${handleColorChange()}]`}
                  onClick={handleMarkAsClick}
                  ref={markAsRef}
                >
                  Mark As
                </span>
                <MarkAsMenu
                  className="absolute top-[45px]"
                  showMarkAsMenu={showMarkAsMenu}
                  markAsRef={markAsRef}
                  getNotifications={fetchVoicemailsAndCalls}
                  getThreads={getMarkAsThreads}
                />
              </div>

              <div
                data-testid="bulk-pin"
                className={`flex gap-1 font-open-sans font-semibold text-[14px] leading-[20px] text-[#4D4D4D] ${handleCursor()}`}
              >
                <CommandCenterIcon
                  type="regular"
                  variant="thumbtack"
                  color={handleColorChange()}
                  height="18"
                  width="16"
                  onClick={handlePinClick}
                />
                <span
                  className={`text-[${handleColorChange()}]
          `}
                  onClick={handlePinClick}
                >
                  {checkPinned()}
                </span>
              </div>
              <CustomModal
                isModalForm={true}
                isOpen={openPinConversationModal}
                setIsOpen={setOpenPinConversationModal}
                handleClose={() => {
                  setOpenPinConversationModal(false)
                }}
                width={isMobile ? '300px' : '500px'}
              >
                <PinConversationModal
                  setOpenPinConversationModal={setOpenPinConversationModal}
                  unpinConversation={unpinConversation}
                />
              </CustomModal>
            </>
          </div>
        ) : null}

        {threadsError ? (
          <TableFormAlert
            variant="danger"
            alertText="Failed to load. Please try again."
            allowClose
            // eslint-disable-next-line no-undef
            onClick={() => setShowWarning(false)}
          />
        ) : null}

        {hideThreadsMenu ? (
          <SearchView />
        ) : isFirstRender ? (
          <div>
            {Array(12)
              .fill(true)
              .map((_, i) => (
                <ConversationSkeleton key={i} />
              ))}
          </div>
        ) : !todayMessageThreads.length &&
          !yesterdayMessageThreads.length &&
          !lastWeekMessageThreads.length &&
          !lastMonthMessageThreads.length &&
          !lastYearMessageThreads.length &&
          !overOneYearMessageThreads.length &&
          !pinnedMessageThreads.length &&
          !customMessageThreads.length &&
          !messages.loading ? (
          <div className="flex justify-center py-6 text-sm font-open-sans font-light text-[gray]">
            <p>NO {inboxFilter.toUpperCase()} MESSAGES TO SHOW</p>
          </div>
        ) : (
          <>
            {/* ----- PINNED MESSAGES ----- */}
            <ConversationTable
              isDropdown
              typeOfConversations="Pinned"
              hideTableFromDOM={
                pinnedMessageThreads?.length === 0 || inboxFilter !== 'Inbox'
              }
            >
              {pinnedMessageThreads?.map((email, i) => {
                return email?.messages?.items?.length ? (
                  <ConversationRow
                    key={i}
                    id={email.thread_id}
                    email={email}
                    threadName={email.thread_name}
                    threadId={email.thread_id}
                    threadCreatedTime={email.sk1}
                    message={{
                      title: formatContactData(email?.thread_name),
                      text: getCorrectMessagePreview(email?.messages?.items),
                      timestamp: moment(email?.compareTime)
                        .local()
                        .format('MM/DD/YY'),
                      chan_type: email?.messages?.items[0]?.chan_type,
                    }}
                    isViewed={email.read}
                    isPinned={true}
                    isSelected={email?.thread_id === selectedThreadId}
                    hasDraft={email?.messages?.draft !== null}
                    time={'pinned'}
                    folderName="All"
                    onClick={() => {
                      handleClickToScroll(email.thread_id)

                      if (!isLoading) {
                        dispatch(setConversationLimit(10))
                        dispatch(setNumOfDates(100))
                        dispatch(
                          setSearchingDate({ searchingDate: '', initialDate: '' }),
                        )
                        dispatch(setOpenCalendar(false))
                        dispatch(setCurrentDate(null))
                        updateSelectedThread(
                          email.thread_id?.split('#')[0],
                          email.thread_name,
                          'pinned',
                          email,
                        )
                        if (sendButtonClicked) {
                          dispatch(setSendButtonClicked(false))
                        }
                      }
                    }}
                  />
                ) : null
              })}
            </ConversationTable>

            {/* ----- TODAY MESSAGES ----- */}
            <ConversationTable
              isDropdown
              typeOfConversations={
                inboxSort.includes('to')
                  ? inboxSort.includes('A to Z')
                    ? 'Sender Name: A to Z'
                    : 'Sender Name: Z to A'
                  : inboxSort.includes('oldest first')
                    ? 'Older Than One Year'
                    : 'Today'
              }
              hideTableFromDOM={
                todayMessageThreads?.length === 0 ||
                todayMessageThreads?.filter(
                  (x) => x.email?.messages?.items?.length > 0,
                )?.length !== 0 ||
                !isDateInRange(
                  todayMessageThreads[0]?.last_message_time,
                  inboxAdvancedFilters.date.dates[1],
                  inboxAdvancedFilters.date?.type,
                )
              }
            >
              {todayMessageThreads?.map((email, i) => {
                return email?.messages?.items?.length ? (
                  <ConversationRow
                    key={i}
                    id={email.thread_id}
                    email={email}
                    threadName={email.thread_name}
                    firstName={email?.thread_name?.split(' ')[0]}
                    lastName={email?.thread_name?.split(' ')[1]}
                    threadId={email.thread_id}
                    threadCreatedTime={email.sk1}
                    user={email?.user ? email.user : null}
                    message={{
                      title: formatContactData(email.thread_name),
                      text: getCorrectMessagePreview(email.messages?.items),
                      timestamp: inboxSort.includes('oldest first')
                        ? moment(email?.compareTime).local().format('MM/DD/YY')
                        : moment(email.compareTime).local().format('h:mm A'),
                      chan_type: email?.messages?.items[0]?.chan_type,
                    }}
                    isViewed={email.read}
                    isSelected={email.thread_id === selectedThreadId}
                    hasDraft={email.messages.draft !== null}
                    folderName="All"
                    onClick={() => {
                      handleClickToScroll(email.thread_id)

                      if (!isLoading) {
                        batch(() => {
                          dispatch(setConversationLimit(10))
                          dispatch(setNumOfDates(100))
                          dispatch(
                            setSearchingDate({ searchingDate: '', initialDate: '' }),
                          )
                          dispatch(setOpenCalendar(false))
                          dispatch(setCurrentDate(null))
                          dispatch(setTimeframe('today'))
                        })
                        updateSelectedThread(
                          email.thread_id,
                          email.thread_name,
                          'today',
                          email,
                        )
                        if (sendButtonClicked) {
                          dispatch(setSendButtonClicked(false))
                        }
                      }
                    }}
                    startTime={midnight}
                    endTime={9999999999999}
                    time={'today'}
                  />
                ) : null
              })}
            </ConversationTable>

            {/* ----- YESTERDAY MESSAGES ----- */}
            <ConversationTable
              isDropdown
              typeOfConversations={
                inboxSort.includes('oldest first') ? 'Past Year' : 'Yesterday'
              }
              hideTableFromDOM={
                yesterdayMessageThreads?.length === 0 ||
                yesterdayMessageThreads?.filter(
                  (x) => x.email?.messages?.items?.length > 0,
                )?.length !== 0 ||
                inboxSort.includes('to') ||
                !isDateInRange(
                  yesterdayMessageThreads[0]?.last_message_time,
                  inboxAdvancedFilters.date?.dates[1],
                  inboxAdvancedFilters.date?.type,
                )
              }
            >
              {yesterdayMessageThreads?.map((email, i) => {
                return email?.messages?.items?.length ? (
                  <ConversationRow
                    key={i}
                    id={email.thread_id}
                    email={email}
                    threadName={email.thread_name}
                    firstName={email.thread_name?.split(' ')[0]}
                    lastName={email.thread_name?.split(' ')[1]}
                    threadId={email.thread_id}
                    threadCreatedTime={email.sk1}
                    message={{
                      title: formatContactData(email.thread_name),
                      text: getCorrectMessagePreview(email.messages.items),
                      timestamp: inboxSort.includes('oldest first')
                        ? moment(email?.compareTime).local().format('MM/DD/YY')
                        : moment(email.compareTime).local().format('h:mm A'),
                      chan_type: email?.messages?.items[0]?.chan_type,
                    }}
                    isViewed={email.read}
                    isSelected={email.thread_id === selectedThreadId}
                    hasDraft={email.messages.draft !== null}
                    folderName="All"
                    onClick={() => {
                      handleClickToScroll(email.thread_id)

                      if (!isLoading) {
                        batch(() => {
                          dispatch(setConversationLimit(10))
                          dispatch(setNumOfDates(100))
                          dispatch(
                            setSearchingDate({ searchingDate: '', initialDate: '' }),
                          )
                          dispatch(setOpenCalendar(false))
                          dispatch(setCurrentDate(null))
                          dispatch(setTimeframe('yesterday'))
                        })
                        updateSelectedThread(
                          email.thread_id,
                          email.thread_name,
                          'yesterday',
                          email,
                        )
                        if (sendButtonClicked) {
                          dispatch(setSendButtonClicked(false))
                        }
                      }
                    }}
                    startTime={yesterdayMidnightTime}
                    endTime={midnight}
                    time={'yesterday'}
                  />
                ) : null
              })}
            </ConversationTable>

            {/* ----- PAST WEEK MESSAGES ----- */}
            <ConversationTable
              isDropdown
              typeOfConversations={
                inboxSort.includes('oldest first') ? 'Past Month' : 'Past Week'
              }
              hideTableFromDOM={
                lastWeekMessageThreads?.length === 0 ||
                lastWeekMessageThreads?.filter(
                  (x) => x.email?.messages?.items?.length > 0,
                )?.length !== 0 ||
                inboxSort.includes('to') ||
                !isDateInRange(
                  lastWeekMessageThreads[0]?.last_message_time,
                  inboxAdvancedFilters.date?.dates[1],
                  inboxAdvancedFilters.date?.type,
                )
              }
            >
              {lastWeekMessageThreads?.map((email, i) => {
                return email?.messages?.items?.length ? (
                  <ConversationRow
                    key={i}
                    id={email.thread_id}
                    email={email}
                    threadName={email.thread_name}
                    firstName={email.thread_name?.split(' ')[0]}
                    lastName={email.thread_name?.split(' ')[1]}
                    threadId={email.thread_id}
                    threadCreatedTime={email.sk1}
                    message={{
                      title: formatContactData(email?.thread_name),
                      text: getCorrectMessagePreview(email?.messages?.items),
                      timestamp: moment(email?.compareTime)
                        .local()
                        .format('MM/DD/YY'),
                      chan_type: email?.messages?.items[0]?.chan_type,
                    }}
                    isViewed={email.read}
                    isSelected={email?.thread_id === selectedThreadId}
                    hasDraft={email?.messages.draft !== null}
                    folderName="All"
                    onClick={() => {
                      handleClickToScroll(email.thread_id)

                      if (!isLoading) {
                        batch(() => {
                          dispatch(setConversationLimit(10))
                          dispatch(setNumOfDates(100))
                          dispatch(
                            setSearchingDate({ searchingDate: '', initialDate: '' }),
                          )
                          dispatch(setOpenCalendar(false))
                          dispatch(setCurrentDate(null))
                          dispatch(setTimeframe('last week'))
                        })
                        updateSelectedThread(
                          email.thread_id,
                          email.thread_name,
                          'last week',
                          email,
                        )
                        if (sendButtonClicked) {
                          dispatch(setSendButtonClicked(false))
                        }
                      }
                    }}
                    startTime={startOfLastWeekMidnightTime}
                    endTime={yesterdayMidnightTime}
                    time={'last week'}
                  />
                ) : null
              })}
            </ConversationTable>

            {/* ----- PAST MONTH MESSAGES ----- */}
            <ConversationTable
              isDropdown
              typeOfConversations={
                inboxSort.includes('oldest first') ? 'Past Week' : 'Past Month'
              }
              hideTableFromDOM={
                lastMonthMessageThreads?.length === 0 ||
                lastMonthMessageThreads?.filter(
                  (x) => x.email?.messages?.items?.length > 0,
                )?.length !== 0 ||
                inboxSort.includes('to') ||
                !isDateInRange(
                  lastMonthMessageThreads[0]?.last_message_time,
                  inboxAdvancedFilters.date?.dates[1],
                  inboxAdvancedFilters.date?.type,
                )
              }
            >
              {lastMonthMessageThreads?.map((email, i) => {
                return email?.messages?.items?.length ? (
                  <ConversationRow
                    key={i}
                    id={email.thread_id}
                    email={email}
                    threadName={email.thread_name}
                    firstName={email.thread_name?.split(' ')[0]}
                    lastName={email.thread_name?.split(' ')[1]}
                    threadId={email.thread_id}
                    threadCreatedTime={email.sk1}
                    message={{
                      title: formatContactData(email?.thread_name),
                      text: getCorrectMessagePreview(email?.messages?.items),
                      timestamp: moment(email?.compareTime)
                        .local()
                        .format('MM/DD/YY'),
                      chan_type: email?.messages?.items[0]?.chan_type,
                    }}
                    isViewed={email.read}
                    isSelected={email?.thread_id === selectedThreadId}
                    hasDraft={email?.messages.draft !== null}
                    folderName="All"
                    onClick={() => {
                      handleClickToScroll(email.thread_id)

                      if (!isLoading) {
                        batch(() => {
                          dispatch(setConversationLimit(10))
                          dispatch(setNumOfDates(100))
                          dispatch(
                            setSearchingDate({ searchingDate: '', initialDate: '' }),
                          )
                          dispatch(setOpenCalendar(false))
                          dispatch(setCurrentDate(null))
                          dispatch(setTimeframe('last month'))
                        })
                        updateSelectedThread(
                          email.thread_id,
                          email.thread_name,
                          'last month',
                          email,
                        )
                        if (sendButtonClicked) {
                          dispatch(setSendButtonClicked(false))
                        }
                      }
                    }}
                    startTime={startOfLastMonthMidnightTime}
                    endTime={startOfLastWeekMidnightTime}
                    time={'last month'}
                  />
                ) : null
              })}
            </ConversationTable>

            {/* ----- PAST YEAR MESSAGES ----- */}
            <ConversationTable
              isDropdown
              typeOfConversations={
                inboxSort.includes('oldest first') ? 'Yesterday' : 'Past Year'
              }
              hideTableFromDOM={
                lastYearMessageThreads?.length === 0 ||
                lastYearMessageThreads?.filter(
                  (x) => x.email?.messages?.items?.length > 0,
                )?.length !== 0 ||
                inboxSort.includes('to') ||
                !isDateInRange(
                  lastYearMessageThreads[0]?.last_message_time,
                  inboxAdvancedFilters.date?.dates[1],
                  inboxAdvancedFilters.date?.type,
                )
              }
            >
              {lastYearMessageThreads?.map((email, i) => {
                return email?.messages?.items?.length ? (
                  <ConversationRow
                    key={i}
                    id={email.thread_id}
                    email={email}
                    threadName={email.thread_name}
                    firstName={email.thread_name?.split(' ')[0]}
                    lastName={email.thread_name?.split(' ')[1]}
                    threadId={email.thread_id}
                    threadCreatedTime={email.sk1}
                    message={{
                      title: formatContactData(email?.thread_name),
                      text: getCorrectMessagePreview(email?.messages?.items),
                      timestamp: inboxSort.includes('oldest first')
                        ? moment(email?.compareTime).local().format('h:mm A')
                        : moment(email.compareTime).local().format('MM/DD/YY'),
                      chan_type: email?.messages?.items[0]?.chan_type,
                    }}
                    isViewed={email.read}
                    isSelected={email?.thread_id === selectedThreadId}
                    hasDraft={email?.messages.draft !== null}
                    folderName="All"
                    onClick={() => {
                      handleClickToScroll(email.thread_id)

                      if (!isLoading) {
                        batch(() => {
                          dispatch(setConversationLimit(10))
                          dispatch(setNumOfDates(100))
                          dispatch(
                            setSearchingDate({ searchingDate: '', initialDate: '' }),
                          )
                          dispatch(setOpenCalendar(false))
                          dispatch(setCurrentDate(null))
                          dispatch(setTimeframe('last year'))
                        })
                        updateSelectedThread(
                          email.thread_id,
                          email.thread_name,
                          'last year',
                          email,
                        )
                        if (sendButtonClicked) {
                          dispatch(setSendButtonClicked(false))
                        }
                      }
                    }}
                    startTime={startOfLastYearMidnightTime}
                    endTime={startOfLastMonthMidnightTime}
                    time={'last year'}
                  />
                ) : null
              })}
            </ConversationTable>

            {/* ----- OVER ONE YEAR MESSAGES ----- */}
            <ConversationTable
              isDropdown
              typeOfConversations={
                inboxSort.includes('oldest first') ? 'Today' : 'Older Than One Year'
              }
              hideTableFromDOM={
                overOneYearMessageThreads?.length === 0 ||
                overOneYearMessageThreads?.filter(
                  (x) => x.email?.messages?.items?.length > 0,
                )?.length !== 0 ||
                inboxSort.includes('to') ||
                !isDateInRange(
                  overOneYearMessageThreads[0]?.last_message_time,
                  inboxAdvancedFilters.date?.dates[1],
                  inboxAdvancedFilters.date?.type,
                )
              }
            >
              {overOneYearMessageThreads?.map((email, i) => {
                return email?.messages?.items?.length ? (
                  <ConversationRow
                    key={i}
                    id={email.thread_id}
                    email={email}
                    threadName={email.thread_name}
                    firstName={email.thread_name?.split(' ')[0]}
                    lastName={email.thread_name?.split(' ')[1]}
                    threadId={email.thread_id}
                    threadCreatedTime={email.sk1}
                    message={{
                      title: formatContactData(email?.thread_name),
                      text: getCorrectMessagePreview(email?.messages?.items),
                      timestamp: inboxSort.includes('oldest first')
                        ? moment(email?.compareTime).local().format('h:mm A')
                        : moment(email.compareTime).local().format('MM/DD/YY'),
                      chan_type: email?.messages?.items[0]?.chan_type,
                    }}
                    isViewed={email.read}
                    isSelected={email?.thread_id === selectedThreadId}
                    hasDraft={email?.messages.draft !== null}
                    folderName="All"
                    onClick={() => {
                      handleClickToScroll(email.thread_id)

                      if (!isLoading) {
                        batch(() => {
                          dispatch(setConversationLimit(10))
                          dispatch(setNumOfDates(100))
                          dispatch(
                            setSearchingDate({ searchingDate: '', initialDate: '' }),
                          )
                          dispatch(setOpenCalendar(false))
                          dispatch(setCurrentDate(null))
                          dispatch(setTimeframe('over one year'))
                        })
                        updateSelectedThread(
                          email.thread_id,
                          email.thread_name,
                          'over one year',
                          email,
                        )
                        if (sendButtonClicked) {
                          dispatch(setSendButtonClicked(false))
                        }
                      }
                    }}
                    startTime={0}
                    endTime={startOfLastYearMidnightTime}
                    time={'over one year'}
                  />
                ) : null
              })}
            </ConversationTable>

            {/* ----- CUSTOM DATE MESSAGES ----- */}
            <ConversationTable
              isDropdown
              typeOfConversations="Custom"
              hideTableFromDOM={
                customMessageThreads?.length === 0 ||
                customMessageThreads?.filter(
                  (x) => x.email?.messages?.items?.length > 0,
                )?.length !== 0 ||
                !isDateInRange(
                  customMessageThreads[0]?.last_message_time,
                  inboxAdvancedFilters.date?.dates[1],
                  inboxAdvancedFilters.date?.type,
                )
              }
            >
              {customMessageThreads?.map((email, i) => {
                return email?.messages?.items?.length ? (
                  <ConversationRow
                    key={i}
                    id={email.thread_id}
                    email={email}
                    threadName={email.thread_name}
                    firstName={email.thread_name?.split(' ')[0]}
                    lastName={email.thread_name?.split(' ')[1]}
                    threadId={email.thread_id}
                    threadCreatedTime={email.sk1}
                    message={{
                      title: formatContactData(email?.thread_name),
                      text: getCorrectMessagePreview(email?.messages?.items),
                      timestamp: moment(email?.compareTime)
                        .local()
                        .format('MM/DD/YY'),
                      chan_type: email?.messages?.items[0]?.chan_type,
                    }}
                    isViewed={email.read}
                    isSelected={email?.thread_id === selectedThreadId}
                    hasDraft={email?.messages?.draft !== null}
                    folderName="All"
                    onClick={() => {
                      handleClickToScroll(email.thread_id)

                      if (!isLoading) {
                        batch(() => {
                          dispatch(setConversationLimit(10))
                          dispatch(setNumOfDates(100))
                          dispatch(
                            setSearchingDate({ searchingDate: '', initialDate: '' }),
                          )
                          dispatch(setOpenCalendar(false))
                          dispatch(setCurrentDate(null))
                          dispatch(setTimeframe('last year'))
                        })
                        updateSelectedThread(
                          email.thread_id,
                          email.thread_name,
                          'last year',
                          email,
                        )
                        if (sendButtonClicked) {
                          dispatch(setSendButtonClicked(false))
                        }
                      }
                    }}
                    startTime={startOfLastYearMidnightTime}
                    endTime={startOfLastMonthMidnightTime}
                    time={'last year'}
                  />
                ) : null
              })}
            </ConversationTable>
            {threadsData ? (
              <>
                {!threadsData.queryThreads?.nextToken ? null : (
                  <SeeMoreConversationsButton
                    onClick={() => incrementThreadLimitAndFetch()}
                    seeMoreClicked={seeMoreClicked}
                    loading={messages.loading}
                  />
                )}
              </>
            ) : (
              <>
                {!messages?.data?.queryThreads?.nextToken ? null : (
                  <SeeMoreConversationsButton
                    onClick={() => incrementThreadLimitAndFetch()}
                    seeMoreClicked={seeMoreClicked}
                    loading={messages.loading}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>

      {/* ----- NOTIFICATION ----- */}
      <div className="flex flex-col absolute bottom-4 left-0 right-0 gap-y-2">
        <Transition
          className="shadow-thryv mx-2 rounded"
          show={inboxNotification.show}
          enter="transition ease-in duration-300 relative"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-out duration-200"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          unmount={false}
          style={{
            transformOrigin: 'center',
          }}
        >
          <Notification
            textToRender="Conversation added to Trash."
            iconType="solid"
            iconVariant="circleExclamation"
            variant="reminder"
            close={() => dispatch(setShowInboxNotification(false))}
          />
        </Transition>
        {/* SPAM TTL NOTIFICATION */}
        <Transition
          className="shadow-thryv mx-2 rounded"
          show={
            inboxFilter.toLowerCase() === 'spam' ||
            inboxFilter.toLowerCase() === 'trash'
          }
          enter="transition ease-in duration-300 relative"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-out duration-200"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
          unmount={false}
          style={{
            transformOrigin: 'center',
          }}
        >
          <Notification
            variant="warning"
            iconType="solid"
            iconClassName="absolute left-0 top-2 pl-1"
            className="relative pl-8"
            textToRender={
              <span>
                {' '}
                Conversations placed in {inboxFilter} will be automatically deleted
                after 30 days.{' '}
              </span>
            }
          />
        </Transition>
      </div>
      {isMobile && (
        <RoundedActionButton
          className={`
          fixed right-[25px] bottom-[25px] bg-[#FF5000] rounded-full flex items-center justify-center !w-[50px] !h-[50px]
        `}
          icon={{
            variant: 'edit',
            type: 'regular',
            width: '25',
            height: '25',
            color: '#ffff',
            hoverColor: '',
          }}
          isMavIcon
          onClick={onMobileContactComposeClick}
        />
      )}
    </InboxMenuResizeWrapper>
  )
}
