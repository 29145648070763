import { useState, useEffect, useRef, useCallback } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import _ from 'lodash'
import {
  FETCH_ATTATCHMENTS_BY_THREAD_ID,
  GET_S3_DOWNLOAD_LINK,
} from '../../../../graphql/queries/inbox-queries'
import { getEpochToFormattedDate } from '../SharedMedia'

const filterByImages = (attachment) => {
  const parsedData = JSON.parse(attachment.body)
  return parsedData.contentType?.startsWith('image/')
}

const sortDescendingTime = (a, b) => parseInt(b.sk1) - parseInt(a.sk1)

export const useSharedPhotos = (selectedThreadId) => {
  const [imagePreviewPhotos, setImagePreviewPhotos] = useState(new Map())
  const client = useApolloClient()
  const prevAttachmentsRef = useRef()
  const { data, loading } = useQuery(FETCH_ATTATCHMENTS_BY_THREAD_ID, {
    variables: { threadid: selectedThreadId },
  })

  let imageAttachmentData = data ? data.queryInboxAttachmentsByThread.items : []

  // Filter for attachments with contentType starting with "image/"
  imageAttachmentData = imageAttachmentData.filter(filterByImages)

  // Sort by time
  imageAttachmentData.sort(sortDescendingTime)

  const groupedAttachments = imageAttachmentData.reduce((groups, attachment) => {
    const date = getEpochToFormattedDate(attachment.sk1)
    if (!groups[date]) {
      groups[date] = []
    }

    groups[date].push(attachment)
    return groups
  }, {})

  const handleAttachmentLink = async (attachment, mappedAttachments) => {
    const { pk1, sk1, body } = attachment
    const pk1Parts = pk1.split('#').slice(0, -1)
    const pk1String = pk1Parts.join('/').replace(/#/g, '/')
    const finalString = `${pk1String}/${sk1}`

    try {
      const { data } = await client.query({
        query: GET_S3_DOWNLOAD_LINK,
        variables: {
          filename: finalString,
        },
      })

      const downloadLink = data.getSafeS3DownloadURL
      const formattedUrl = downloadLink.substring(
        downloadLink.indexOf('=') + 1,
        downloadLink.length - 1,
      )

      mappedAttachments.set(body, formattedUrl)
      return formattedUrl
    } catch (error) {
      // Log the error message
      // console.log('Error fetching download link:', error.message)
    }
  }

  // Define a refetch function
  const refetchAttachments = useCallback(async () => {
    const { data: newData } = await client.query({
      query: FETCH_ATTATCHMENTS_BY_THREAD_ID,
      variables: { threadid: selectedThreadId },
      fetchPolicy: 'network-only',
    })

    let newAttachmentData = newData
      ? newData.queryInboxAttachmentsByThread.items
      : []

    newAttachmentData = newAttachmentData.filter(filterByImages)

    newAttachmentData.sort(sortDescendingTime)

    const mappedAttachments = new Map()
    await Promise.all(
      newAttachmentData.map((attachment) =>
        handleAttachmentLink(attachment, mappedAttachments),
      ),
    )

    setImagePreviewPhotos(mappedAttachments)
  }, [selectedThreadId, client])

  // Update useEffect to use refetch function
  useEffect(() => {
    if (!_.isEqual(prevAttachmentsRef.current, imageAttachmentData)) {
      refetchAttachments()
    }

    prevAttachmentsRef.current = imageAttachmentData
  }, [imageAttachmentData, refetchAttachments])

  return {
    imagePreviewPhotos,
    imageAttachmentData,
    loading,
    refetchAttachments,
    groupedAttachments,
  }
}
