import { useState, useRef, useEffect, useContext } from 'react'
import { ParagraphText, Button } from '@thryvlabs/maverick'
import { batch, useDispatch, useSelector } from 'react-redux'
import { CommandCenterIcon } from '../../../../../../command-center-icon'
import {
  setEditorView,
  setMessage,
  setReplying,
} from '../../../../../slices/messageComposeSlice'
import { BccOrRecipientsSection } from '../../../../common/message-recipient-section/message-recipient-section'
import { UploadedFile as Attachment } from '../../../../message-compose/attachments/uploaded-files'
import { IncomingMessageContext } from '../../../../../../../context/message-context'
import {
  setMessageInModal,
  setViewFullMessageOpen,
} from '../../../../../slices/inboxSlice'
import { ShowMoreButton } from '../../../../message-compose/compose-header/ccSection'
import welcomeVideo from '../../../../../../../assets/welcome-inbox.mp4'
import welcomeVideoPoster from '../../../../../../../assets/inbox-video-poster.jpeg'
import DOMPurify from 'dompurify'
import findYouTubeVideoId from '../../../../../common/youtube-embeds/findYoutubeVideoId'
import YouTubeEmbed from '../../../../../common/youtube-embeds/YoutubeEmbeded'

const DynamicReplyButton = ({ onClick, message, disabled = false }) => (
  <Button
    variant="text"
    level={1}
    className="flex gap-1 items-center"
    onClick={onClick}
    disabled={disabled}
  >
    <CommandCenterIcon
      type="regular"
      variant="replyAll"
      height="14"
      width="18"
      color={disabled ? '#808080' : '#5378FC'}
    />
    {message.emailRecipients.cc?.length > 0 ||
    message.emailRecipients.bcc?.length > 0
      ? 'Reply All'
      : 'Reply'}
  </Button>
)

const MessageSection = ({
  message,
  messageText,
  messageTitle,
  setMessageTitle,
  isDeletedMessage,
  setIsDeletedMessage,
  messageContentRef,
}) => {
  const dispatch = useDispatch()
  const msgRef = useRef(null)
  const incomingMessage = useContext(IncomingMessageContext)

  const { selectedThread } = useSelector((state) => state.inboxThreads)
  const { channelInfo } = useSelector((state) => state.connectChannels)

  const [showMore, setShowMore] = useState(false)
  const [willClamp, setWillClamp] = useState(false)

  useEffect(() => {
    if (messageText && messageText === 'Message deleted.') {
      setMessageTitle('')
      setIsDeletedMessage(true)
      return messageText
    }
  }, [messageText, isDeletedMessage])

  const handleReplyAll = () => {
    dispatch(setEditorView('open'))
    dispatch(setReplying({ replying: true, subject: message?.title || '' }))
    dispatch(setMessage(message))
  }

  useEffect(() => {
    setWillClamp(
      msgRef?.current?.scrollHeight > msgRef?.current?.clientHeight ||
        incomingMessage?.body?.length > 150,
    )
  }, [msgRef])

  const getAttachmentLink = (file_id) => {
    const channelCCID = incomingMessage?.pk1.split('#')[0]
    const channelID = incomingMessage?.chanpk

    return `${channelCCID}/${channelID}/${selectedThread.id}/${file_id}`
  }

  const attachmentsToShow = message?.attachments?.filter(
    (file) => file.messageID === message.sk1,
  )

  const isWelcomeMessage =
    message?.type === 'CC' && message?.title === 'Welcome to Command Center Inbox!'

  const isInternal = message?.type === 'CC'

  useEffect(() => {
    if (messageText) {
      return messageText
    }
  }, [messageText])

  DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    if ('target' in node) {
      node.setAttribute('target', '_blank')
      node.setAttribute('rel', 'noopener')
    }
  })

  const email = `
      <html><head>
      <meta http-equiv="Content-Security-Policy" content="script-src 'none'">
      <base target="_blank">
      </head>
      <body><p class="text-p-sm font-open-sans font-normal text-thryv-black-600">${incomingMessage.body}</p></body>
      </html>
  `

  const handleScroll = () => {
    setTimeout(() => {
      messageContentRef.current.scrollTop = messageContentRef.current.scrollHeight
    }, 10)
  }

  let youtubeEmbedComponent = null
  if (message.text) {
    const youTubeVideoId = findYouTubeVideoId(message.text)
    if (youTubeVideoId) {
      // Make sure youTubeVideoId is not null or undefined
      youtubeEmbedComponent = <YouTubeEmbed videoId={youTubeVideoId} />
    }
  }

  return (
    <>
      {message?.type === 'CC' ? (
        <div className="p-5 sm:max-w-[300px]">
          <div
            className="leading-8 message-modal-content"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(email, { ADD_ATTR: ['target'] }),
            }}
          ></div>
          {isWelcomeMessage && (
            <div className="pt-5 pb-5">
              <video width="377" height="282" controls poster={welcomeVideoPoster}>
                <source src={welcomeVideo} type="video/mp4" />
              </video>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="m-4">
            <div ref={msgRef} className="mb-2 line-clamp-3">
              <ParagraphText
                variant="reg"
                className="font-['Montserrat'] !font-semibold mb-2"
              >
                {messageTitle}
              </ParagraphText>
              <ParagraphText variant="sm">{messageText}</ParagraphText>
            </div>
            {youtubeEmbedComponent}
            {!isDeletedMessage && willClamp && (
              <button
                className="text-sm font-open-sans font-semibold text-utility/text-link-info underline pt-[5px]"
                onClick={() => {
                  batch(() => {
                    dispatch(setViewFullMessageOpen(true))
                    dispatch(setMessageInModal(incomingMessage.body))
                  })
                }}
              >
                View entire message
              </button>
            )}
            {message.emailRecipients.cc && message.emailRecipients.cc.length >= 1 ? ( //checking that there's something to render inside cc array
              <BccOrRecipientsSection
                emailRecipient={message.emailRecipients.cc}
                type="cc"
              />
            ) : null}
            {message.emailRecipients.bcc &&
            message.emailRecipients.bcc.length >= 1 ? (
              <BccOrRecipientsSection
                emailRecipient={message.emailRecipients.bcc}
                type="bcc"
              />
            ) : null}
            {/* 
          the diff between this one and the one in outgoing is the reply all btn
          so find a way to make that render based on if the message is outgoing or incoming
        */}
            {!isDeletedMessage && !isWelcomeMessage && !isInternal ? (
              <div className="h-[30px] flex justify-end">
                <DynamicReplyButton
                  onClick={() => {
                    handleReplyAll()
                    handleScroll()
                  }}
                  message={message}
                  disabled={channelInfo?.length === 0}
                />
              </div>
            ) : null}
          </div>
          {!isDeletedMessage && attachmentsToShow.length > 0 && (
            <div className="flex flex-row flex-wrap bg-white p-3 rounded-b-[10px] border-t border-[#eceef1]">
              {attachmentsToShow?.map((file, i) => (
                <Attachment
                  file={JSON.parse(file.body)}
                  getAttachment={getAttachmentLink}
                  thumbnailUrl={file?.thumbnailUrl}
                  fileId={file.sk1}
                  key={i}
                  style={{ display: showMore || i < 2 ? 'flex' : 'none' }}
                />
              ))}
              {attachmentsToShow?.length > 2 && (
                <ShowMoreButton
                  className="ml-1"
                  number={attachmentsToShow.length - 2}
                  onClick={() => setShowMore(!showMore)}
                  showMore={showMore}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}
export default MessageSection
