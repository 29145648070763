import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useError } from './hooks/use-error'
import { useFormContext } from '../../../right-panel/user-profile-and-activity/contexts/form-context'
import { useDispatch } from 'react-redux'
import { useContactsContext } from '../../../right-panel/user-profile-and-activity/contexts/contacts-context'
import { FETCH_THREADS, UPDATE_CONTACT } from '../../../../graphql'
import { USER_FRIENDLY_ERRORS } from './utils/user-friendly-errors'
import { useMutation } from '@apollo/client'
import { DELETE_MESSAGES } from '../../../../graphql'
import { useLazyQuery } from '@apollo/client'
import { useInboxMenuConversations } from '../../../inbox/inbox-menu/hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import { addCountryCodeToPhone } from './utils/add-country-code-to-phone'
import { shapeContactForSubmission } from './utils/shape-contact-for-submission'
import { useQuery } from '@apollo/client'
import { FETCH_MESSAGES_BY_THREAD_ID } from '../../../../graphql'
import { setSelectedThread } from '../../../inbox/slices/inboxThreadsSlice'
import { formatSelectedThreadName } from './utils/format-selected-thread-name'
import { DELETE_MESSAGES_WITH_MERGE } from '../../../../graphql'
import { setUpdatedContactMergeNotification } from '../../../inbox/slices/inboxSlice'
import { FETCH_CONTACT_BY_THREAD } from '../../../../graphql'

// Components
import { EditContactFormRevised } from './edit-contact-form-revised/EditContactFormRevised'
import { UpdateContactPanel } from './update-contact-panel/UpdateContactPanel'

const EditContactPanelUpdated = ({ show, isMobile }) => {
  const dispatch = useDispatch()
  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()

  const { contact, refetchContact } = useContactsContext()
  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )

  // OPTIONS: 'form', 'confirm-edit-contact'
  const [selectedPanel, setSelectedPanel] = useState('form')
  const [userConfirmedSubmission, setUserConfirmedSubmission] = useState(false)
  const [submissionIsLoading, setSubmissionIsLoading] = useState(false)

  const { countryIso2: countryCode } = useSelector((state) => state.countryCode)
  const { showError, errorMessage, updateErrorMessage, hideErrorMessage } =
    useError()

  const {
    emailIsDirty,
    phoneIsDirty,
    isEmailOrPhoneDirty,
    initDefaultFormValues,
    handleSubmit,
  } = useFormContext(contact)

  const showContactPanel = () => {
    const backButton = document.getElementById('contact-panel-back-button')
    backButton.click()
  }

  // const { createVariables, initMessageThreads, initPinnedMessageThreads } =
  //   useInboxMenuConversations()

  const [deleteMessages] = useMutation(DELETE_MESSAGES)

  const [deleteMessagesWithMerge] = useMutation(DELETE_MESSAGES_WITH_MERGE, {
    onCompleted: () => {
      dispatch(setUpdatedContactMergeNotification(contact.contactsk1))
    },
  })

  const { refetch: refetchMessages } = useQuery(FETCH_MESSAGES_BY_THREAD_ID)

  // This is used to get the threads associated with the new email or phone.
  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    fetchPolicy: 'no-cache',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const handleDeleteMessages = async (newContact) => {
    //Vendor ID 1 for Nylas 2 for Vonage
    await deleteMessages({
      variables: {
        VendorID: newContact.phone === contact.phone ? 1 : 2,
        thread_id: contact.contactsk1,
      },
    })

    // use setTimeout to refetchAllContactInformation, BE needs time to fully update contacts and delete messages
    setTimeout(async () => {
      await refetchAllContactInformation()
      setSubmissionIsLoading()
      showContactPanel()
    }, 3000)
  }

  const handleDeleteMessagesAndMergeContacts = async (newContact, mergeContact) => {
    //Vendor ID 1 for Nylas 2 for Vonage
    await deleteMessagesWithMerge({
      variables: {
        VendorID: newContact.phone === contact.phone ? 1 : 2,
        thread_id: contact?.contactsk1,
        body: mergeContact.body,
        contactsk1: mergeContact.contactsk1,
        contactsk2: mergeContact.contactsk2,
        contactsk3: mergeContact.contactsk3,
        pk1: mergeContact.pk1,
        sk1: mergeContact.sk1,
      },
    })

    // use setTimeout to refetchAllContactInformation, BE needs time to fully update contacts and delete messages
    setTimeout(async () => {
      await refetchAllContactInformation()
      setSubmissionIsLoading()
      showContactPanel()
    }, 3000)
  }

  const refetchAllContactInformation = async () => {
    await refetchContact()
    await refetchMessages({ threadid: contact.contactsk1 })
    await getThreads()
  }

  const [updateContact] = useMutation(UPDATE_CONTACT, {
    errorPolicy: 'all',
    refetchQueries: () => [
      {
        query: FETCH_CONTACT_BY_THREAD,
        variables: { threadid: contact.thread_id },
      },
    ],
  })

  const updateContactInBackend = async (newContact) => {
    const UPDATED_CONTACT = { ...newContact }

    if (UPDATED_CONTACT.phone.length) {
      UPDATED_CONTACT.phone = addCountryCodeToPhone(
        UPDATED_CONTACT.phone,
        countryCode,
      )
    }

    // Submission shape if all values are valid: { body: '', contactsk2: '', contactsk3: '', sk1: ''}
    const shapedContact = shapeContactForSubmission(UPDATED_CONTACT, contact.sk1)

    const response = await updateContact({
      variables: shapedContact,
    })

    return response
  }

  const onSubmit = async (newContact) => {
    setSubmissionIsLoading(true)
    hideErrorMessage()

    if (isEmailOrPhoneDirty() && !userConfirmedSubmission) {
      setSelectedPanel('confirm-edit-contact')
      setSubmissionIsLoading(false)
      return
    }

    try {
      const res = await updateContactInBackend(newContact)

      const requestFailed = res?.errors?.length

      if (requestFailed) {
        const errorMsg = res?.errors[0]?.message || ''
        const errCode = res?.errors[0]?.errorType || ''

        const mergeContact = res?.data?.update_contact?.mergeContact

        if (errCode === 'ERR009') {
          if (mergeContact) {
            // If getting back ERR009 WITH mergeContact need to run deleteMessages with mergeContact
            await handleDeleteMessagesAndMergeContacts(newContact, mergeContact)
          } else {
            // If getting back ERR009 WITHOUT mergeContact need to run deleteMessages without mergeContact
            await handleDeleteMessages(newContact)
          }
        } else {
          // You can update contact first name and last and still reach here...
          if (errorMsg.length) updateErrorMessage(USER_FRIENDLY_ERRORS[errCode])
          await refetchAllContactInformation()
          setSubmissionIsLoading(false)
          showContactPanel()
          return
        }
      } else {
        await refetchAllContactInformation()
        setSubmissionIsLoading(false)
        showContactPanel()
      }

      // If the current page is not calls... Idk how I feel about this code here.
      if (!window.location.href.includes('/calls')) {
        dispatch(
          setSelectedThread({
            id: selectedThreadId,
            name: formatSelectedThreadName(res),
          }),
        )
      }
    } catch (err) {
      setSubmissionIsLoading(false)
      updateErrorMessage('Contact changes not saved. Please try again.')
    }
  }

  useEffect(() => {
    initDefaultFormValues()
    setSelectedPanel('form')
  }, [show])

  return (
    <div className={`${show ? 'block' : 'hidden'}`}>
      <EditContactFormRevised
        submissionIsLoading={submissionIsLoading}
        onSubmit={onSubmit}
        show={selectedPanel === 'form'}
        showError={showError}
        hideErrorMessage={hideErrorMessage}
        errorMessage={errorMessage}
      />

      <UpdateContactPanel
        show={selectedPanel === 'confirm-edit-contact'}
        variant={
          (emailIsDirty() && phoneIsDirty() && 'both') ||
          (emailIsDirty() && 'email') ||
          (phoneIsDirty() && 'phone')
        }
        onCancelClick={() => setSelectedPanel('form')}
        onConfirmClick={() => {
          setSelectedPanel('form')
          setUserConfirmedSubmission(true)
          handleSubmit(onSubmit)
        }}
        isMobile={isMobile}
      />
    </div>
  )
}

export default EditContactPanelUpdated
