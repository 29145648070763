const getPath = (name) => {
  //eslint-disable-next-line
  const env = process.env.NODE_ENV || 'production'
  const paths = {
    'Command Center': {
      production: 'https://command.thryv.com/',
      qa: 'https://qa-comm-center2-fe.staging.thryv.com/',
      development: 'https://dev-comm-center-fe.sandbox.thryv.com/',
    },
    'Marketing Center': {
      production: 'https://marketingcenter.labs.thryv.com/',
      qa: 'https://mc-dev.sandbox.thryv.com/overview', //this is PROD-not qa path available
      development: 'https://mc-dev.sandbox.thryv.com/overview',
    },
    'Business Center': {
      production: 'https://go.thryv.com/',
      qa: 'https://emp.thryv.com/', //this is PROD-not qa path available
      development: 'https://emp.thryv.com/',
    },
    ThryvPay: {
      production: 'http://thryvpayfullstack-prod.thryv.com/',
      qa: 'https://dev-thryvpay-beta.sandbox.thryv.com/',
      development: 'http://thryvpay-staging.thryv.com/',
    },
    // Keap: {
    //   production: 'https://keap.com/',
    //   qa: '',
    //   development: 'https://keap.com/',
    // },

    // 'Reporting Center': {
    //   production: ' https://command.thryv.com/reporting-center/reports',
    //   qa: 'https://qa-reporting-center.staging.thryv.com',
    //   development: 'https://dev-reporting-center.sandbox.thryv.com',
    // },
    // 'Workforce Center': {
    //   production: 'https://workforce-center.labs.thryv.com/',
    //   qa: 'https://qa-workforce-center.staging.thryv.com/',
    //   development: 'https://dev-workforce-center.sandbox.thryv.com/',
    // },
  }

  return paths[name][env]
}

const displayCenters = [
  {
    name: 'Command Center',
    icon: 'cc-subscription',
    defaultPath: 'https://www.thryv.com/command-center/',
  },
  {
    name: 'Marketing Center',
    icon: 'marketingCenterSubscription',
    defaultPath: 'https://www.thryv.com/marketing-center/',
  },
  {
    name: 'Business Center',
    icon: 'businessCenterSubscription',
    defaultPath: 'https://www.thryv.com/business-center/',
  },
  {
    name: 'ThryvPay',
    icon: 'thryvPaySubscription',
    defaultPath: 'https://www.thryv.com/features/thryvpay/',
  },
  // {
  //   name: 'Keap',
  //   icon: 'keapIcon',
  //   defaultPath: 'https://www.thryv.com/command-center/',
  // },

  /*
  these will be available after they are released 
  { name: 'Workforce Center', icon: 'thryvWorkforceCenter' },
  { name: 'Reporting Center', icon: 'thryvReportingCenter' },

  */
].map((center) => ({
  ...center,
  path: getPath(center.name),
}))

export const updateAccessInfo = (userCenters) => {
  const normalize = (str) => str.replace(/\s+/g, ' ').trim().toLowerCase() //using this to prevent from failing in cases where there are extra spaces in the center name

  // using a Set for faster lookup
  const accessibleCenters = new Set(
    userCenters.map((center) => normalize(center.title)),
  )

  return displayCenters.map((center) => ({
    ...center,
    hasAccess: accessibleCenters.has(normalize(center.name)),
  }))
}
