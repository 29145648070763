import { ChannelButton, borderColors } from './ManageChannelButton.styles'
import './ManageChannel.css'
import ChannelsIcon from '../channels-icon/channels-icon'
import { Icon } from '@thryvlabs/maverick'
import useCardData from '../inbox/hooks/useCardData'

const getBorderColorForPhone = (provider) => {
  if (provider !== 'phone') return null

  const { getPhoneCard } = useCardData()
  const [currentPhoneCard] = getPhoneCard()

  if (currentPhoneCard?.connectError) {
    return {
      color: 'red',
      icon: (
        <Icon
          variant={'circleExclamation'}
          width={16}
          height={16}
          type="solid"
          color={borderColors['red']}
          className={`absolute left-[-5px] top-[-7px] bg-white rounded-full`}
        />
      ),
    }
  }

  if (currentPhoneCard?.connectWarning) {
    return {
      color: 'yellow',
      icon: (
        <>
          <div
            className={`w-[5px] h-[10px] absolute left-[0px] top-[-2px] bg-white`}
          />
          <Icon
            variant={'triangleExclamation'}
            width={16}
            height={16}
            type="solid"
            color={borderColors['yellow']}
            className={`absolute left-[-5px] top-[-7px]`}
          />
        </>
      ),
    }
  }

  return null
}

const ProgressBar = ({
  color1,
  color2,
  color3,
  progress,
  radialWidth = 26,
  strokeWidth = 3,
}) => {
  const radius = (radialWidth - 2 * strokeWidth) / 2
  const circumference = 2 * Math.PI * radius

  return (
    <>
      <div>
        <svg
          height={radialWidth}
          width={radialWidth}
          viewBox={`0 0 ${radialWidth} ${radialWidth}`}
        >
          <defs>
            <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor={`${color1 || '#5378FC'}`} />
              <stop offset="100%" stopColor={`${color2 || '#FF5000'}`} />
            </linearGradient>
          </defs>
          <circle
            stroke={`${color3 || '#E3E6E8'}`}
            strokeWidth={`${strokeWidth}`}
            fill="none"
            r={radius}
            cx={radialWidth / 2}
            cy={radialWidth / 2}
          />
          <circle
            stroke="url(#linear)"
            strokeWidth={`${strokeWidth || '4'}`}
            strokeLinecap="round"
            fill="none"
            strokeDasharray={circumference}
            strokeDashoffset={`${circumference - (circumference * progress) / 100}`}
            r={radius}
            cx={radialWidth / 2}
            cy={radialWidth / 2}
          />
        </svg>
      </div>
    </>
  )
}

function ManageChannelButton({
  onClick,
  iconType = '',
  iconName = '',
  showSpinner = false,
  progress = 0,
  channel,
}) {
  const color = getBorderColorForPhone(channel?.Provider)

  return (
    <>
      {showSpinner ? (
        <ChannelButton
          variant="secondary"
          buttonType="outlined"
          onClick={onClick}
          active={'false'}
          data-testid="connect-channel-loading"
        >
          <div className="rounded-full absolute inset-0 inline-flex items-center justify-center ">
            <ProgressBar
              progress={progress}
              color3="#ccc"
              color1={'#FFFFFF'}
              color2={'#FFFFFF'}
            />
          </div>

          {iconType && iconName ? (
            <ChannelsIcon
              variant={iconName}
              height="20px"
              width="20px"
              className="z-0 opacity-100"
            />
          ) : (
            <span className="!text-[1.5rem]">+</span>
          )}
        </ChannelButton>
      ) : (
        <ChannelButton
          variant="secondary"
          buttonType="outlined"
          onClick={onClick}
          active={'true'}
          $Status={channel?.Status}
          data-testid={`${channel?.Provider}-channel`}
          $borderColor={channel?.Provider === 'phone' && color?.color}
        >
          {channel?.Provider === 'phone' && color?.icon}
          {channel?.Status == '98' && channel?.VendorID == '1' && (
            <Icon
              data-testid="circleExclamationIcon"
              variant={'circleExclamation'}
              width={16}
              height={16}
              type="solid"
              color={borderColors['red']}
              className={`absolute left-[-5px] top-[-7px] bg-white rounded-full`}
            />
          )}
          {iconType && iconName ? (
            <ChannelsIcon
              variant={iconName}
              height="20px"
              width="20px"
              className="z-0 opacity-100"
            />
          ) : (
            <span className="!text-[1.5rem]">+</span>
          )}
        </ChannelButton>
      )}
    </>
  )
}

export default ManageChannelButton
