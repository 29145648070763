// Components
import { RoundActionButton } from '../round-action-button'
import { AddStaffModal } from '../../../../../../../components/common/add-staff-modal'
import { withModal } from '@thryvlabs/maverick'

const AddStaffCard = ({ showModal }) => {
  return (
    <div
      className="flex justify-between items-center py-2 px-4 max-w-[600px] rounded cursor-pointer sm:border-none sm:relative"
      onClick={showModal}
    >
      <RoundActionButton
        dataTestId="add-new-staff-btn"
        className="transition-all duration-300 bg-[#FF5000] right-[5px]"
      />
    </div>
  )
}

export const AddStaffCardWithModal = ({ className }) => {
  const CardWithModal = withModal(AddStaffCard, AddStaffModal)

  return (
    <div className={className}>
      <CardWithModal disableBackdropClick />
    </div>
  )
}
