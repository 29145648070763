import { CC_RECURLY_PLAN_CODES } from '../../../../utils/recurly-constants'

export const UPGRADE_FLOW_STEPS = {
  PLAN_SELECTION: 0,
  ORDER_REVIEW: 1,
  PAYMENT_FROM: 2,
}

export const getTiersData = ({ toggleStaffSeatUpdates } = {}) => {
  const freemiumSeats = toggleStaffSeatUpdates
    ? '<strong>3</strong> Users'
    : '<strong>5</strong> Users'

  return [
    {
      name: 'free',
      header: 'Basic',
      color: '#7A94F6',
      supportText: 'Email & Learn.thryv.com',
      code: CC_RECURLY_PLAN_CODES.free,
      isCurrent: true,
      // selected free by default
      selected: true,
      price: 0,
      features: [
        {
          name: freemiumSeats,
          added: true,
        },
        {
          name: 'Any <strong>3 Inbox Channels</strong>',
          added: true,
        },
        {
          name: '<strong>30 Days</strong> Teamchat History',
          added: true,
        },
        {
          name: '<strong>60 minutes</strong> of video/voice',
          added: true,
        },
      ],
    },
    {
      name: 'plus',
      header: 'Plus',
      code: CC_RECURLY_PLAN_CODES.plus,
      supportText: '24/7 Phone, Chat, Email & Online & Learn.thryv.com',
      color: '#7A94F6',
      isCurrent: false,
      selected: false,
      price: 20,
      features: [
        {
          name: '<strong>Unlimited</strong> Users',
          added: true,
        },
        {
          name: 'Any <strong>6 Inbox Channels</strong>',
          added: true,
        },
        {
          name: '<strong>12 months</strong> Teamchat History',
          added: true,
        },
        {
          name: '<strong>300 minutes</strong> of video/voice',
          added: true,
        },
      ],
    },
    {
      name: 'pro',
      header: 'Professional',
      code: CC_RECURLY_PLAN_CODES.pro,
      supportText: '24/7 Phone, Chat, Email & Online & Learn.thryv.com',
      color: '#7A94F6',
      isCurrent: false,
      selected: false,
      price: 30,
      features: [
        {
          name: '<strong>Unlimited</strong> Users',
          added: true,
        },
        {
          name: '<strong>Unlimited Inbox Channels</strong>',
          added: true,
        },
        {
          name: '<strong>Unlimited</strong> Teamchat History',
          added: true,
        },
        {
          name: '<strong>600 minutes</strong> of video/voice',
          added: true,
        },
      ],
    },
  ]
}
