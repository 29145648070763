const createColors = (color1, hexOpacity) => {
  return {
    primary: color1,
    secondary: color1 + hexOpacity,
  }
}

// List of available HEX opacities: https://tinyurl.com/55cd8r6w
const AVAILABLE_VARIANTS = {
  doc: { colors: createColors('#2C5897', '26') },
  docx: { colors: createColors('#2C5897', '26') },
  xls: { colors: createColors('#207347', '26') },
  xlsx: { colors: createColors('#207347', '26') },
  xml: { colors: createColors('#207347', '26') },
  ppt: { colors: createColors('#D04727', '26') },
  pptx: { colors: createColors('#D04727', '26') },
  psd: { colors: createColors('#1976D2', '26') },
  webp: { colors: createColors('#D1A625', '26') },
  ai: { colors: createColors('#D1A625', '26') },
  indd: { colors: createColors('#C54786', '26') },
  pdf: { colors: createColors('#CF0A0A', '26') },
}

const getVariantColors = (variantOrFileName) => {
  const AVAILABLE_VARIANT = AVAILABLE_VARIANTS[variantOrFileName]
  if (AVAILABLE_VARIANT) return AVAILABLE_VARIANT

  const FILE_TYPE = variantOrFileName?.slice(variantOrFileName?.lastIndexOf('.') + 1)

  const SELECTED_VARIANT = AVAILABLE_VARIANTS[FILE_TYPE]

  // this will return the default color if the variant is undefined
  if (SELECTED_VARIANT !== undefined) return SELECTED_VARIANT.colors
  const DEFAULT_COLORS = createColors('#A5A5A5', '26')
  return DEFAULT_COLORS
}

export const getFileColors = (variantOrFileName) => {
  const COLORS = getVariantColors(variantOrFileName)

  return COLORS
}
