import { useState, useEffect } from 'react'
import { GET_S3_DOWNLOAD_LINK } from '@graphql'
import { useAuth0 } from '@auth0/auth0-react'
import { useLazyQuery } from '@apollo/client'
const { VITE_NYLAS_HOST } = import.meta.env

/**
 * Checks the existence of a voicemail and returns related data.
 *
 * @param {object} voicemail - The voicemail object to check.
 * @returns {object} {isVoicemailExist, audioBlob, transcript, isLoadingAudio} - An object containing:
 *   - {boolean} isVoicemailExist: Whether the voicemail exists.
 *   - {Blob} audioBlob: The audio data of the voicemail (or null if none).
 *   - {string} transcript: The transcript of the voicemail (or null if none).
 *   - {boolean} isLoadingAudio: Whether the audio is loading.
 */
const useCheckVoicemailExistence = (voicemail) => {
  const [isVoicemailExist, setIsVoicemailExist] = useState(null)
  const [audioBlob, setAudioBlob] = useState(null)
  const [transcript, setTranscript] = useState(null)
  const [isLoadingAudio, setIsLoadingAudio] = useState(true)
  const { user } = useAuth0()
  const [getS3Link, { loading }] = useLazyQuery(GET_S3_DOWNLOAD_LINK)
  const formatTranscription = () => {
    const voicemailText = voicemail?.text || voicemail?.body
    try {
      const parsedData = JSON.parse(voicemailText)
      setTranscript(parsedData.transcription)
    } catch (err) {
      const parsedData = voicemail.text?.substring(
        voicemailText.indexOf('=') + 1,
        voicemailText.indexOf('link=') - 2,
      )
      setTranscript(parsedData)
    }
  }

  const fetchAudioBlob = async (link) => {
    const myHeaders = new Headers()
    myHeaders.append('Accept', 'audio/mpeg')
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }
    try {
      const response = await fetch(link, requestOptions)
      if (response.ok) {
        setIsVoicemailExist(true)
        setAudioBlob(await response.blob())
      } else {
        return false
      }
    } catch (err) {
      return false
    }
    return true
  }

  const checkVoicemailExistence = async () => {
    // 1. try nexmo Link first
    const voicemailText = voicemail?.text || voicemail?.body
    const nexmoLink = voicemailText.substring(
      voicemailText.indexOf('link=') + 5,
      voicemailText.length - 1,
    )
    const audioUUID = nexmoLink.substring(nexmoLink.indexOf('files/') + 6)
    const nexmoDownloadLink = `${VITE_NYLAS_HOST}/voicemail/${audioUUID}`
    let res = await fetchAudioBlob(nexmoDownloadLink)
    if (res) {
      setIsLoadingAudio(false)
      return
    }
    // 2. if 404, try s3 link
    const filename = voicemail?.threadpk
      ? `${user.cc_id}/${voicemail.chanpk}/${voicemail.threadpk.split('#')[0]}/${
          voicemail.msgpk
        }.mp3`
      : null

    let { data } = await getS3Link({
      variables: {
        filename,
      },
    })
    let s3Link = data?.getSafeS3DownloadURL || '404'
    if (s3Link !== '404') {
      s3Link = s3Link.substring(s3Link.indexOf('=') + 1, s3Link.length - 1)
      res = await fetchAudioBlob(s3Link)
    }
    setIsVoicemailExist(res)
    setIsLoadingAudio(false)
  }
  useEffect(() => {
    if (
      voicemail &&
      !loading &&
      audioBlob === null &&
      transcript === null &&
      (voicemail?.variant === 'VOICEMAIL' || voicemail?.item_type === 'VOICEMAIL')
    ) {
      formatTranscription()
      checkVoicemailExistence()
    }

    // todo: cleanup async task to avoid updating state
  }, [voicemail?.text, voicemail?.body, audioBlob, transcript, loading])

  return { isVoicemailExist, audioBlob, transcript, isLoadingAudio }
}

export default useCheckVoicemailExistence
