import { useState } from 'react'
import { ModalContainer } from '../connect-channels-modal/ModalComponent.styles'
import ManageChannelsModal from '../manage-channels-modal/ManageChannelsModal'
import DeleteChannelModal from '../delete-channel-modal/DeleteChannelModal'
import ConnectChannelsModal from '../connect-channels-modal/ConnectChannelsModal'
import { DowngradePlanModal } from '../downgrade-plan-modal/DowngradePlanModal'
import { useSelector, useDispatch } from 'react-redux'
import {
  disconnectChannel,
  setCardData,
  setChannelDisconnected,
  setChannelDisconnecting,
  setChannelIsConnected,
  setDisableBackdropClick,
  setMaxChannelsReached,
  setModalContent,
  setShowFirstChannelModal,
  setShowModal,
  setShowWelcomeChannelModal,
} from '../slices/connectChannelsSlice'
import { ModalWrapper } from '@thryvlabs/maverick'
import { useEffect } from 'react'
import { GET_CONNECTED_CHANNELS_BY_USER } from '../../../../graphql/queries'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import UpgradePlanModal from '../upgrade-plan-modal/UpgradePlanModal'
import ImapModal from '../imap-modal/ImapModal'
import { ConnectPhoneModal } from '../phone-certification-modal/connect-phone-modal/ConnectPhoneModal'
import { DISCONNECT_CHANNEL } from '../../../../graphql/mutations/disconnect-channel-mutation'
import { VideoChannelModal } from '../connect-video-channel-modal/video-channel-modal'
import WebChatModal from '../webchat-modal/WebChatModal'
import { setMaxChannelsAllowed } from '../slices/connectChannelsSlice'
import { GET_PACKAGE_INFO } from '../../../../graphql/queries'
import ConnectChannelsModalMobile from '../connect-channels-modal/ConnectChannelsModalMobile'
import { useMediaQuery } from 'react-responsive'
import { batch } from 'react-redux'
import OnboardingModal from '../../../onboarding/OnboardingModal'

export const useDeleteChannel = (clickedID) => {
  const dispatch = useDispatch()
  const { user: authUser } = useAuth0()
  const { cardData, channelInfo } = useSelector((state) => state.connectChannels)
  const [getChannelsByUser, { data: channelData, refetch: refetchChannelData }] =
    useLazyQuery(GET_CONNECTED_CHANNELS_BY_USER)

  const [deleteChannel, { loading }] = useMutation(DISCONNECT_CHANNEL, {
    refetchQueries: [GET_CONNECTED_CHANNELS_BY_USER],
    onCompleted: (data) => {
      if (data.deleteChannel) {
        refetchChannelData()
        dispatch(disconnectChannel(clickedID))
      }
    },
  })

  const updateActiveChannel = (
    activeChannels,
    channelsData,
    provider = '',
    callingDelete = false,
  ) => {
    const channelsConnected = {
      //'gmail' : 0
      //'outlook' : 0
      //'hotmail' : 0
    }

    activeChannels?.forEach((channel) => {
      if (channelsConnected[channel.Provider] === undefined) {
        channelsConnected[channel.Provider] = 0
      }

      channelsConnected[channel.Provider] += 1
    })

    Object.entries(channelsConnected).forEach(([key]) => {
      let channelId
      const channelCardData = channelsData.filter((data, i) => {
        let tempKey
        if (key === 'phone') {
          tempKey = 'calls'
        } else if (key === 'facebook') {
          tempKey = 'facebookMessenger'
        } else {
          tempKey = key
        }
        if (data.iconName === tempKey) {
          channelId = i
          return true
        }
      })

      if (
        key === provider &&
        Object.values(channelsConnected)?.at(0)?.toString() === '1' &&
        callingDelete
      ) {
        dispatch(
          setCardData({
            channelId,
            channelsConnected: 0,
            showCard: false,
          }),
        )
        return
      }

      const numOfActiveChannelsLocal = channelsConnected[key]
      const numOfActiveChannelsRedux = channelCardData[0]?.channelsConnected
      if (numOfActiveChannelsLocal !== numOfActiveChannelsRedux)
        dispatch(
          setCardData({
            channelId,
            channelsConnected: channelsConnected[key],
            showCard: true,
          }),
        )
    })
  }

  const handleDelete = (clickedID) => {
    deleteChannel({
      variables: {
        channel_id: channelInfo[clickedID]?.ChannelID,
        locationID: authUser.businessId,
        provider: channelInfo[clickedID]?.VendorID,
        status: '0',
        ccid: authUser.cc_id,
      },
    }).then(() => {
      batch(() => {
        dispatch(setChannelDisconnecting(channelInfo[clickedID]))
        dispatch(setChannelIsConnected(false))
        dispatch(setShowFirstChannelModal(false))
      })
    })
    // setting the state to show the first modal to false here, bc when the delete funcion runs and the channelsConnected.length is 2 it will toggle the showfirst modal to true and close out the main modal

    const activeChannels = channelData?.queryConnectedChannelsByUser?.filter(
      (channel) => channel.Provider !== 'CC' && channel.Status !== '0',
    )

    updateActiveChannel(
      activeChannels,
      cardData,
      channelInfo[clickedID]?.Provider,
      true,
    )

    if (channelInfo.length === 1) {
      dispatch(setModalContent(1))
    } else dispatch(setModalContent(2))
  }

  useEffect(() => {
    getChannelsByUser({
      variables: {
        loc_id: authUser.businessId,
        user_id: authUser.cc_uid,
      },
    })
    const inactiveChannels = channelData?.queryConnectedChannelsByUser?.filter(
      (channel) => channel.Status === '99' && channel.Provider !== 'CC',
    )
    const activeChannels = channelData?.queryConnectedChannelsByUser?.filter(
      (channel) => channel.Provider !== 'CC' && channel.Status !== '0',
    )
    if (activeChannels) {
      updateActiveChannel(activeChannels, cardData)
      // dispatch(setChanelInfo(activeChannels)) // adding this so it doesnt set the channelInfo to null while loading
      dispatch(setChannelDisconnected(inactiveChannels)) // adding this so it doesnt set the disconnected chanels to null while loading
    }
  }, [channelData])

  return { handleDelete, deleteLoading: loading, refetchChannelData }
}

export const ChannelsModalComponent = ({ onClose = () => {}, renderAsPage }) => {
  const [clickedID, setClickedID] = useState()
  const isMobile = useMediaQuery({ maxWidth: 835 })

  const { modalContent, showModal, showWelcomeChannelModal } = useSelector(
    (state) => state.connectChannels,
  )
  const dispatch = useDispatch()

  const {
    data: packageInfoData,
    loading: packageInfoLoading,
    error: packageInfoError,
  } = useQuery(GET_PACKAGE_INFO, {
    onCompleted: () => {
      dispatch(
        setMaxChannelsAllowed(packageInfoData.queryPackageInfoByCCID.InboxChannels),
      )
    },
    onError: () => {
      // eslint-disable-next-line no-console
      console.log('PACKAGE INFO ERROR:', packageInfoError)
    },
  })

  const { handleDelete, deleteLoading, refetchChannelData } =
    useDeleteChannel(clickedID)

  const handleClose = () => {
    dispatch(setShowWelcomeChannelModal(false))
    dispatch(setShowModal(false))
    dispatch(setDisableBackdropClick(false))
    onClose()
  }

  const handleClosePhoneModal = () => {
    dispatch(setDisableBackdropClick(false))
    dispatch(setModalContent(1))
    onClose()
  }

  const openDeleteModal = (ID) => {
    dispatch(setModalContent(3))
    setClickedID(ID)
  }

  const manageChannel = () => {
    dispatch(setModalContent(2))
    dispatch(setMaxChannelsReached(false))
    dispatch(setChannelIsConnected(false))
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') handleClose()
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const renderModals = () => {
    return (
      <>
        {modalContent === 2 ? (
          <ManageChannelsModal
            openDeleteModal={openDeleteModal}
            handleClose={handleClose}
            loading={deleteLoading}
            clickedID={clickedID}
          />
        ) : null}

        {modalContent === 3 ? (
          <DeleteChannelModal
            handleClose={handleClose}
            handleDelete={handleDelete}
            clickedID={clickedID}
          />
        ) : null}

        {modalContent === 4 ? (
          <UpgradePlanModal
            close={handleClose}
            handleDelete={handleDelete}
            clickedID={clickedID}
          />
        ) : null}

        {modalContent === 6 ? (
          <ModalWrapper show={showModal} close={handleClose}>
            <ImapModal clickedID={clickedID} />
          </ModalWrapper>
        ) : null}

        {modalContent === 7 ? (
          <ConnectPhoneModal
            showModal={showModal}
            setShowModal={handleClosePhoneModal}
          />
        ) : null}

        {modalContent === 8 ? (
          <VideoChannelModal
            clickedID={clickedID}
            refetchChannelData={refetchChannelData}
          />
        ) : null}

        {modalContent === 9 && (
          <ModalWrapper show={showModal} close={handleClose}>
            <WebChatModal clickedID={clickedID} />
          </ModalWrapper>
        )}

        {modalContent === 151 ? (
          <ConnectChannelsModalMobile
            manageChannel={manageChannel}
            setClickedID={setClickedID}
            subscriptionTierLoading={packageInfoLoading}
          />
        ) : null}

        {modalContent === 23 && (
          <DowngradePlanModal show={showModal} handleClose={handleClose} />
        )}

        {modalContent === 24 && (
          <OnboardingModal show={showModal} close={handleClose} completed={true} />
        )}
      </>
    )
  }

  if (renderAsPage && showWelcomeChannelModal && isMobile) {
    return (
      <>
        {modalContent === 1 && (
          <ConnectChannelsModal
            manageChannel={manageChannel}
            setClickedID={setClickedID}
            handleClose={handleClose}
            renderAsPage={renderAsPage}
          />
        )}

        {renderModals()}
      </>
    )
  }

  return (
    <ModalWrapper
      show={showModal}
      close={() => handleClose()}
      disableBackdropClick
      className={
        'inline-block transform overflow-hidden align-middle bg-white p-3 shadow-xl rounded-lg undefined md:m-auto md:w-[100%] md:my-[10px] '
      }
    >
      <ModalContainer>
        {modalContent === 1 ? (
          <ConnectChannelsModal
            manageChannel={manageChannel}
            setClickedID={setClickedID}
            handleClose={handleClose}
          />
        ) : null}

        {renderModals()}

        {/* modalContent 10,11,12 reserved for meta reauth modals that live in inbox */}
      </ModalContainer>
    </ModalWrapper>
  )
}
