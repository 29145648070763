import { gql } from '@apollo/client'

export const GET_INITIAL_QUERY = gql`
  query get_initial_query(
    $CCID: String!
    $loc_id: String!
    $input: StaffQuery = {}
  ) {
    queryPackageInfoByCCID {
      CCID
      PackageID
      PackageName
      InboxChannels
      TeamChatRetention
      CallMinutes
      SupportLevel
      FreeSeats
    }

    staff: queryStaff(input: $input) {
      CC_UserID
      CCID
      FirstName
      LastName
      Phone
      Email
      Role
      Status
      CountryCode
      permittedChannels {
        ChannelID
        AccessType
      }
    }

    queryCallsMinutesByCCID {
      BaseSeconds
      SecondsUsed
      SecondsRemaining
      AddOnQuantity
    }

    queryRecurlyCommandCenterPlans(CCID: $CCID) {
      code
      name
      price
    }

    queryRecurlyAccountInfo(CCID: $CCID) {
      code
      firstName
      lastName
      state
      activePlanCode
      billingInfo {
        id
        accountId
        primaryPaymentMethod
        paymentMethod {
          cardType
          firstSix
          lastFour
          expMonth
          expYear
        }
        address {
          street1
          street2
          city
          region
          postalCode
        }
      }
    }

    queryUnreadPills {
      numThreads
      numCalls
      numVoicemails
    }

    queryBusinessInfoByLocation(LocationID: $loc_id) {
      Name
      BusinessCategory
      Address
      City
      CountryCode
      Phone
      State
      Zip
      Email
      Website
    }

    queryAgentLanguage
    queryAutoTranslation
  }
`
