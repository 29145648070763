import { gql } from '@apollo/client'

export const FETCH_PAYMENT_METHODS = gql`
  query fetch_payment_methods($loc_id: String!, $refresh: Boolean) {
    queryPaymentMethodsByLocation(loc_id: $loc_id, refresh: $refresh) {
      data {
        BILLING_INFO_ID
        ACCOUNT_CODE
        CC_TYPE
        CC_LAST_4
        PAYMENT_METHOD
        PRIMARY_PAYMENT_METHOD
        BILLING_ADDRESS1
        BILLING_ADDRESS2
        BILLING_CITY
        BILLING_COUNTRY
        BILLING_FIRST_NAME
        BILLING_LAST_NAME
        BILLING_PHONE
        BILLING_POSTAL_CODE
        BILLING_STATE
      }
    }
  }
`

export const FETCH_PAYMENT_HISTORY = gql`
  query fetch_payment_history($loc_id: String!) {
    queryPaymentHistoryByLocation(input: { loc_id: $loc_id }) {
      data {
        ACCOUNT_CODE
        BILLED_DATE
        CLOSED_AT
        DUE_ON
        ID
        INVOICE_BALANCE
        INVOICE_NUMBER
        INVOICE_BALANCE_MODIFIED_AT
        INVOICE_TOTAL
        STATUS
      }
    }
  }
`

export const FETCH_ACTIVE_SUBSCRIPTIONS = gql`
  query fetch_active_subscriptions($loc_id: String!) {
    queryActiveSubscriptionsByLocation(loc_id: $loc_id) {
      data {
        subscription {
          SUBSCRIPTION_UUID
          SUBSCRIPTION_PLAN_CODE
          SUBSCRIPTION_PLAN_NAME
          SUBSCRIPTION_ACCOUNT_CODE
          SUBSCRIPTION_ACCOUNTING_CODE
          ADD_ON_ACCOUNTING_CODE
          ADD_ON_CODE
          ADD_ON_NAME
          ADD_ON_QUANTITY
          SUBSCRIPTION_QUANTITY
          QUANTITY_TIER_PRICE
        }
        addons {
          SUBSCRIPTION_UUID
          SUBSCRIPTION_PLAN_CODE
          SUBSCRIPTION_PLAN_NAME
          SUBSCRIPTION_ACCOUNT_CODE
          SUBSCRIPTION_ACCOUNTING_CODE
          ADD_ON_ACCOUNTING_CODE
          ADD_ON_CODE
          ADD_ON_NAME
          ADD_ON_QUANTITY
          SUBSCRIPTION_QUANTITY
          QUANTITY_TIER_PRICE
        }
        entitlements {
          SUBSCRIPTION_UUID
          SUBSCRIPTION_PLAN_CODE
          SUBSCRIPTION_PLAN_NAME
          SUBSCRIPTION_ACCOUNT_CODE
          SUBSCRIPTION_ACCOUNTING_CODE
          ADD_ON_ACCOUNTING_CODE
          ADD_ON_CODE
          ADD_ON_NAME
          ADD_ON_QUANTITY
          SUBSCRIPTION_QUANTITY
          QUANTITY_TIER_PRICE
        }
      }
    }
  }
`

export const FETCH_LATEST_PAYMENT = gql`
  query fetch_latest_payment($loc_id: String!) {
    queryLatestPaymentInfoByLocation(loc_id: $loc_id) {
      data {
        last_payment_date
        last_payment_amount
        next_payment_date
      }
    }
  }
`
export const FETCH_LANGUAGE = gql`
  query fetch_language {
    queryAgentLanguage
    queryAutoTranslation
  }
`
