const isSelected = (day, value) => {
  if (value) {
    return value.isSame(day, 'day')
  }
  return false
}

const isToday = (day, value) => {
  if (!value) {
    return day.isSame(new Date(), 'day')
  } else {
    return day.isSame(value, 'day')
  }
}

const afterToday = (day, value) => {
  if (!value) {
    return day.isAfter(new Date(), 'day')
  } else {
    return day.isAfter(value, 'day')
  }
}

const hasMessages = (day, dates) => {
  return dates.some((date) => day.isSame(date, 'day'))
}
export const styleDates = ({ day, value, currentMonth, dates }) => {
  if (isSelected(day, value)) return `bg-black rounded-md text-white cursor-pointer`

  if (isToday(day))
    return `border border-thryv-orange-500 text-thryv-orange-500 rounded-md cursor-pointer`

  if (day.format('MMMM') !== currentMonth) return 'text-[#C2C2C2] cursor-pointer'

  if (afterToday(day)) return `text-[#C2C2C2]`

  if (!hasMessages(day, dates)) {
    return `text-[#C2C2C2]`
  }
  return `text-thryv-black-500 cursor-pointer hover:border hover:border-black hover:rounded-md`
}
