import { gql } from '@apollo/client'

export const CREATE_DRAFT_MUTATION = gql`
  mutation create_draft_mutation(
    $body: String!
    $email_snippet: String!
    $email_subject: String!
    $email_from: EmailParticipantInput
    $email_to: [EmailParticipantInput]
    $email_cc: [EmailParticipantInput]
    $email_bcc: [EmailParticipantInput]
    $thread_id: String!
    $channel_id: String!
  ) {
    createDraftMessage(
      input: {
        thread_id: $thread_id
        conv_id: "137ab"
        acct_id: "1ab"
        body: $body
        email_from: $email_from
        email_to: $email_to
        email_cc: $email_cc
        email_bcc: $email_bcc
        email_subject: $email_subject
        email_snippet: $email_snippet
        channel_id: $channel_id
      }
    ) {
      pk1
      sk1
      body
      chanpk
    }
  }
`

export const CONFIRM_ATTACHMENT = gql`
  mutation confirm_attachment_mutation(
    $content_type: String!
    $thread_id: String!
    $filename: String!
    $sk1: Long!
    $hash: String
  ) {
    confirmAttachment(
      input: {
        thread_id: $thread_id
        content_type: $content_type
        filename: $filename
        sk1: $sk1
        hash: $hash
      }
    ) {
      pk1
      sk1
      body
    }
  }
`
