import { Header } from '@thryvlabs/maverick'
import moment from 'moment'
import {
  CallsOutgoingCallIcon,
  CallsIncomingCallIcon,
  MissedCallIcon,
  VoicemailIcon,
  VideoCallIcon,
} from '../../../command-center-icon/icons/icons'

/**
 * type      variant          color
 * incoming  "incomingCall"   "#4D4D4D"
 * video     "videoCall"      "#4D4D4D"
 * outgoing  "outgoingCall"   "#4D4D4D"
 * missed    "missedCall"     "#DF2A2A"
 */

// const CallIcon = ({ type }) => {
//   const variant = {
//     missed: 'missedCall',
//     incoming: 'incomingCall',
//     outgoing: 'outgoingCall',
//     video: 'videoCall',
//   }[type]
//   return (
//     <CommandCenterIcon
//       className="h-full"
//       variant={variant}
//       type="regular"
//       height="20px"
//       width="20px"
//       color={variant === 'missedCall' ? '#DF2A2A' : '#4D4D4D'}
//     />
//   )
// }

const CallTypeIcon = ({ type, hasVoicemail }) => {
  if (hasVoicemail) {
    return <VoicemailIcon fill={'#DF2A2A'} />
  } else if (type === 'incoming') {
    return <CallsIncomingCallIcon />
  } else if (type === 'outgoing') {
    return <CallsOutgoingCallIcon />
  } else if (type === 'missed') {
    return <MissedCallIcon fill={'#DF2A2A'} width={'22px'} height={'22px'} />
  } else if (type === 'video') {
    return <VideoCallIcon />
  }
  return <></>
}

const RecentCall = ({ recentCallData }) => {
  const getCallType = () => {
    if (
      recentCallData?.chan_type === 'CC' &&
      recentCallData?.body.includes('started a video chat with')
    ) {
      return 'video'
    } else if (
      recentCallData?.agent_id &&
      !recentCallData?.body.includes('Missed')
    ) {
      return 'outgoing'
    } else if (recentCallData?.agent_id && recentCallData?.body.includes('Missed')) {
      return 'missed'
    } else if (
      !recentCallData?.agent_id &&
      recentCallData?.body.includes('Missed')
    ) {
      return 'missed'
    } else if (
      !recentCallData?.agent_id &&
      !recentCallData?.body.includes('Missed')
    ) {
      return 'incoming'
    }
  }
  const type = getCallType()

  return (
    <div className="flex flex-row justify-between items-center">
      <CallTypeIcon type={type} />
      <div className="flex flex-col ml-[18px]">
        <Header fontWeight="medium" variant="h9">
          {type}
        </Header>
      </div>
      <div className="flex flex-col ml-auto items-end">
        <Header fontWeight="medium" variant="h9">
          {moment(recentCallData.sk1).local().format('ll')}
        </Header>
        <Header fontWeight="normal" variant="h9">
          {moment(recentCallData.sk1).local().format('h:mm A')}
        </Header>
      </div>
    </div>
  )
}

export default RecentCall
