import { useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { step1Images, step2Images, step3Images } from './OnboardingCarouselImages'

const OnboardingCarousel = ({ step }) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const settings = {
    arrows: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: false,
    autoplay: true,
    autoplaySpeed: 7000,
    infinite: true,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
  }

  let imagesToMap = []

  switch (step) {
    case 1:
      imagesToMap = step1Images
      break
    case 2:
      imagesToMap = step2Images
      break
    case 3:
      imagesToMap = step3Images
      break
    default:
      imagesToMap = step1Images
      break
  }

  return (
    <div
      data-testid={'onboardingCarousel'}
      className={`${
        step === 1
          ? `bg-onboarding-carousel-gradient-${step1Images[currentSlide]?.id} `
          : step === 2
            ? `bg-[#BCC9FA1A]`
            : step === 3
              ? ``
              : ``
      } h-full`}
    >
      <Slider {...settings}>
        {imagesToMap.map((item) => (
          <div key={item.id} className={`${step === 1 && 'mt-[20%]'} relative`}>
            <div>
              <img
                src={item.src}
                alt={item.alt}
                style={
                  step === 3 && item.id === 1 ? { objectPosition: '80% center' } : {}
                }
                className={`m-auto   ${
                  step === 1
                    ? 'h-[358px] w-[234px] rounded-[200px] object-cover'
                    : step === 2
                      ? 'h-[968px]'
                      : step === 3
                        ? 'h-[968px] object-cover  w-auto'
                        : ''
                }  mb-5`}
              />
            </div>
            {/* STEP 1 */}
            {step === 1 && (
              <>
                {' '}
                <div className="flex gap-1 justify-center mb-[30px]">
                  <h1 className="text-white mt-4 font-['Open_Sans'] font-semibold text-[28px] leading-[20px] ">
                    {item.header}
                  </h1>
                </div>
                <div className="w-[348px] flex wrap text-center flex-col my-0 mx-auto items-center">
                  <span className="text-white mt-3 font-['Open_Sans'] font-normal text-[28px] leading-[20px]">
                    ~
                  </span>{' '}
                </div>
                <div className="w-[500px] flex wrap text-center flex-col my-0 mx-auto items-center pb-[80px]">
                  <span className="text-white mt-3 font-['Open_Sans'] font-normal text-[24px] ">
                    {item.txt}{' '}
                  </span>{' '}
                </div>
              </>
            )}

            {/* STEP 3 */}
            {step === 3 && (
              <div className="flex flex-col gap-1 justify-center absolute 2xl:top-[70%] top-[77%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 right-0 bottom-0 rounded-xl w-[486px] h-[240px] 2xl:w-[386px] px-8 bg-[#4D4D4DB2]">
                <h1 className="text-[#FF5000] font-['Open_Sans'] font-semibold text-[28px] 2xl:text-[22px] leading-[20px]">
                  {item.header}
                </h1>
                <span className="text-white mt-3 font-['Open_Sans'] font-normal text-[24px] 2xl:text-[20px]">
                  {item.txt}{' '}
                </span>
              </div>
            )}
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default OnboardingCarousel
