import { useQuery } from '@apollo/client'
import { Header } from '@thryvlabs/maverick'
import RecentCall from './RecentCall'
import { FETCH_MESSAGES_BY_THREAD_ID } from '../../../../graphql'

const RecentCalls = ({ selectedContact }) => {
  const { data: messages } = useQuery(FETCH_MESSAGES_BY_THREAD_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      threadid: selectedContact?.threadpk?.split('#')?.at(0),
    },
  })

  const recentCallsList = messages?.queryMessagesByThread?.items?.filter(
    (x) =>
      (x?.chan_type === 'phone' && x.item_type === 'CALL') ||
      (x.chan_type === 'CC' && x.body.includes('started a video chat with')),
  )

  return (
    <>
      {recentCallsList && recentCallsList?.length > 0 ? (
        <div className="mt-6">
          <Header className="mb-3" fontWeight="semibold" variant="h8">
            Recent Calls
          </Header>
          <div className="flex flex-col gap-y-4">
            {recentCallsList?.length > 0 ? (
              recentCallsList
                .slice(0, 4)
                .map((recentCall, key) => (
                  <RecentCall key={key} recentCallData={recentCall} />
                ))
            ) : (
              <div className="font-normal text-sm text-[#4D4D4D] font-['Open Sans']">
                You have no recent calls with {selectedContact.username}.
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default RecentCalls
