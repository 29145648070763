import { useState } from 'react'
import { scrollToMsgInThread } from '../../../inbox/global-search-section/sections/utils/scrollToMsgInThread'

// Components
import ColoredFileIcon from '../../../common/colored-file-icon/colored-file-icon'
import { TooltipWithCenteredArrow } from '../../../common/tooltip-with-centered-arrow/tooltip-with-centered-arrow'
import { Icon } from '@thryvlabs/maverick'

// REMOVE IF NECESSARY
// const truncateFileName = (name, maxLength = 15) => {
//   const parts = name.split('.')
//   const extension = parts.pop()
//   const truncatedName = parts.join('.')

//   if (truncatedName.length > maxLength) {
//     const front = truncatedName.substring(0, maxLength / 2)
//     const back = truncatedName.substring(truncatedName.length - maxLength / 2)
//     return `${front}....${back}.${extension}`
//   }

//   return name
// }

const ActionButton = ({ className, tooltipText, iconVariant, ...rest }) => {
  const [isHover, setIsHover] = useState(false)

  return (
    <TooltipWithCenteredArrow
      variant="top"
      title={tooltipText}
      disableVisibleOnClick
      visible={true}
      width="max-content"
    >
      <button
        type="button"
        className={`${className} h-[28px] w-[28px] flex items-center justify-center`}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        {...rest}
      >
        <Icon
          variant={iconVariant}
          type="regular"
          height="16px"
          color={isHover ? '#057AFF' : '#808080'}
        />
      </button>
    </TooltipWithCenteredArrow>
  )
}

const Text = ({ className, children }) => {
  return (
    <p
      className={`${className} font-normal text-[14px] font-open-sans truncate text-start`}
    >
      {children}
    </p>
  )
}

const AttachmentCard = ({
  file = {
    sk: '',
    name: '',
    size: '',
    type: '',
    download: () => {},
  },
}) => {
  const [showButtons, setShowButtons] = useState(false)
  return (
    <div
      className="hover:bg-[#FF50000D] transition-all duration-300 rounded-lg px-3 py-2 flex justify-between"
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      <div className={`flex items-center flex-1 overflow-hidden`}>
        <ColoredFileIcon
          className="flex-shrink-0"
          variant={file.name}
          text={file.type}
          height={'38px'}
          width={'32px'}
        />

        <div className={`flex flex-col ml-[16px] overflow-hidden`}>
          <Text className="text-[#323232]">{file.name}</Text>

          <Text className="text-[#969696]">
            {file.size === 'NaN gb' ? '–' : file.size}
          </Text>
        </div>
      </div>

      {/* ACTION  BUTTONS */}
      <div
        className={`
          ${showButtons ? '' : 'hidden'}
          flex items-center gap-[4px] transition-all duration-300
        `}
      >
        <ActionButton
          tooltipText="Go to file"
          onClick={() => scrollToMsgInThread(file.sk)}
          data-testid="import-photo"
          aria-label="Go to file"
          iconVariant={'fileImport'}
        />

        <ActionButton
          tooltipText="Download"
          onClick={file.download}
          data-testid="download-attachment"
          aria-label="Download file"
          iconVariant={'download'}
        />
      </div>
    </div>
  )
}

export default AttachmentCard
