import {
  ModalWrapper,
  Button,
  ParagraphText as Text,
  Header,
} from '@thryvlabs/maverick'
import { ThryvCCOrangeLogoIcon } from '../command-center-icon/icons'
import { ModalContainer } from '../common/modals/connect-channels-modal/ModalComponent.styles'

const MobileAppCTA = ({ isOpen, close }) => {
  return (
    <ModalWrapper
      show={isOpen}
      close={() => close()}
      disableBackdropClick
      className="inline-block transform overflow-hidden align-bottom bg-white p-3 shadow-xl rounded-lg m-auto w-[100%] "
    >
      <ModalContainer>
        <div className="flex flex-col justify-between p-2 gap-y-7">
          <div className="flex flex-row gap-x-[10px]">
            <ThryvCCOrangeLogoIcon width={38} height={38} />
            <Header
              fontWeight="semibold"
              variant="h5"
              className="text-left pt-[5px]"
            >
              Continue in the app
            </Header>
          </div>

          <Text variant="reg" className="text-left">
            Our Command Center app gives you a smoother mobile experience
          </Text>
          <div className="flex flex-row gap-x-[20px] justify-end">
            <Button variant="text" level={1} textVariant="light" onClick={close}>
              Stay on web
            </Button>
            <Button
              onClick={() => {
                window.location.href = 'https://commandcenter.app.link'
              }}
              variant="primary"
            >
              Open App
            </Button>
          </div>
        </div>
      </ModalContainer>
    </ModalWrapper>
  )
}

export default MobileAppCTA
