import { useState } from 'react'
import {
  CirclePauseIcon,
  PlayButtonIcon,
} from '../../../../command-center-icon/icons'
import CallAvatar from '../../recent-and-voicemail-components/CallAvatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { FETCH_CONTACT_BY_THREAD } from '../../../../../graphql'
import formatContactData from '../../../../inbox/hooks/formatContactData'
import { LoadingSpinner } from '../../../../common/loading-spinner'

const VoicemailDate = ({ voicemail }) => {
  const voicemailDate = moment(voicemail?.ts).local().format('ll')

  return (
    <div className="flex items-center pr-[16px] pl-[10px] ">
      <span className="flex pb-[2px]">{voicemailDate}</span>
    </div>
  )
}
const VoicemailContent = ({
  voicemail,
  voiceMailOpen,
  setVoicemailOpen,
  isVoicemailExist,
  audioBlob,
  isLoadingAudio,
}) => {
  // const [voicemailPlayed] = useState(false)

  const { data: contact } = useQuery(FETCH_CONTACT_BY_THREAD, {
    variables: { threadid: voicemail?.threadpk?.split('#')?.at(0) },
  })

  const body =
    typeof contact?.queryContactsByThread?.items?.at(0)?.body === 'string'
      ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)?.body
        ? JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body).body
        : JSON.parse(contact?.queryContactsByThread?.items?.at(0)?.body)
      : null

  const firstName = body ? body.given_name : ''
  const lastName = body ? body.surname : ''

  const fullName = firstName + ' ' + lastName

  const formattedPhoneNumber = contact
    ? contact?.queryContactsByThread?.items?.at(0)?.contactsk3 === 'Anonymous'
      ? 'Unknown Caller'
      : formatContactData(body?.phone_numbers?.at(0)?.number)
    : null

  return (
    <div className="flex flex-wrap w-full items-center">
      <div className="flex pr-[8.5px] pl-[20px] items-center shrink-0">
        <CallAvatar call={voicemail} firstName={firstName} lastName={lastName} />
      </div>

      <div className="flex flex-col grow px-4 min-w-0">
        <div
          className="block w-[150px] max-w-[1352px] truncate font-semibold"
          title={
            contact && fullName.length > 1 && !/^\d/.test(firstName)
              ? fullName
              : formattedPhoneNumber
          }
        >
          {contact && fullName.length > 1 && !/^\d/.test(firstName)
            ? fullName
            : formattedPhoneNumber}
        </div>
      </div>

      <div className="flex items-center justify-end shrink-0 space-x-4 w-full sm:w-auto">
        {voicemail?.ts && (
          <span className="flex text-thryv-steel text-[14px] py-1 pr-4">
            {isVoicemailExist ? `Available` : `Expired`}
          </span>
        )}

        <div className="border-r-[1px] border-[#ccc] h-full mx-2" />

        <div className="flex text-thryv-steel text-[14px] py-1 pr-4">
          <VoicemailDate voicemail={voicemail} />
        </div>

        <div className="flex items-center py-1 pr-4">
          <AudioPlayer
            isVoicemailExist={isVoicemailExist}
            audioBlob={audioBlob}
            isLoadingAudio={isLoadingAudio}
          />
        </div>

        <div className="flex items-center">
          <button onClick={() => setVoicemailOpen(!voiceMailOpen)} className="ml-2">
            <FontAwesomeIcon
              className={`${
                voiceMailOpen
                  ? 'transform rotate-180 duration-300'
                  : 'transform duration-300'
              } hover:cursor-pointer`}
              size="lg"
              icon={faChevronDown}
              data-testid="voicemail-chevron"
            />
          </button>
        </div>
      </div>
    </div>
  )
}

const AudioPlayer = ({ isVoicemailExist, audioBlob, isLoadingAudio }) => {
  const [audio, setAudio] = useState()
  const [playingAudio, setPlayingAudio] = useState(false)

  const playAudio = () => {
    const audioURL = URL.createObjectURL(audioBlob)
    const audio = new Audio(audioURL)
    setAudio(audio)
    audio.play()
    audio.addEventListener('play', () => setPlayingAudio(true))
    audio.addEventListener('ended', () => setPlayingAudio(false))
  }

  return (
    <div className="flex pr-[16px]">
      {isLoadingAudio ? (
        <LoadingSpinner widthAndHeight={'24px'} />
      ) : !playingAudio ? ( //play btn
        <button
          disabled={!isVoicemailExist}
          onClick={() => playAudio()}
          className={`${
            !isVoicemailExist ? 'opacity-40 pointer-events-none' : 'opacity-100'
          }`}
        >
          <PlayButtonIcon />
        </button>
      ) : (
        //pause btn
        <button
          onClick={() => {
            audio.pause()
            setPlayingAudio(false)
          }}
        >
          <CirclePauseIcon width={24} height={24} active={true} />
        </button>
      )}
    </div>
  )
}

export default VoicemailContent
