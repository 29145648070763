import { ModalContainer } from '../common/modals/connect-channels-modal/ModalComponent.styles'
import {
  ModalWrapper,
  Header,
  ParagraphText,
  Button,
  RegularX,
} from '@thryvlabs/maverick'
import { ProgressBar } from './ProgressBar'
import { useSelector } from 'react-redux'
import onboardingFailureThryvGuy from '../../assets/onboarding-images/thryvguy1.png'
import onboardingSuccessThryvGuy from '../../assets/onboarding-images/thryvguy2.png'
import onboardingCompletedThryvGuy from '../../assets/onboarding-images/thryvguy3.png'
import usePhoneChannel from '../../hooks/use-phone-channel'
import formatContactData from '../inbox/hooks/formatContactData'

const OnboardingModal = ({ show, close, successConnection, completed }) => {
  const { connectedChannelInfo, channelInfo } = useSelector(
    (state) => state.connectChannels,
  )
  const [phoneChannel] = usePhoneChannel()

  return (
    <>
      {completed ? (
        <div>
          <Button variant="text" className="absolute right-5 md:pt-1" level={2}>
            <RegularX
              type="regular"
              variant="x"
              height="18"
              fill="#A3A5A7"
              onClick={close}
            />
          </Button>
          <div className="w-[860px] h-[536px] md:w-[340px] md:h-[492px] flex flex-col items-center text-center justify-center">
            <div className="mb-[24px] md:mb-[12px] md:pt-5">
              <ProgressBar step={4} channelInfo={channelInfo} />
            </div>
            <div className="flex flex-col items-center text-center justify-center w-[515px] md:w-[300px]">
              <Header fontWeight="semibold" variant="h1" className="mb-5">
                Start using Command Center! 🎉
              </Header>
              <ParagraphText
                variant="lg"
                className="text-center font-normal leading-[22px] w-[672px] md:w-[300px]"
              >
                Try scrolling through messages from all three apps, now in one place.
              </ParagraphText>
              <div className="h-[252px]">
                <img
                  src={onboardingCompletedThryvGuy}
                  alt={'Onboarding Success'}
                  className="w-[234px]"
                />
              </div>
              <Button onClick={close} className="w-[204px]" variant="primary">
                Awesome, let's go
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <ModalWrapper show={show} close={close} disableBackdropClick>
          <ModalContainer className="md:w-[310px] md:h-auto md:p-0 ">
            <Button variant="text" className="absolute right-5" level={2}>
              <RegularX
                type="regular"
                variant="x"
                height="18"
                fill="#A3A5A7"
                onClick={close}
              />
            </Button>
            <div className="w-[860px] h-[536px] md:w-[310px] md:h-[492px] flex flex-col items-center text-center justify-center">
              <div className="flex flex-col items-center text-center justify-center w-[535px] md:pt-[34px]">
                <Header
                  fontWeight="semibold"
                  variant="h1"
                  className="mb-5 md:w-[310px] md:mb-2"
                >
                  {successConnection ? (
                    connectedChannelInfo?.provider === 'video' ? (
                      'Congratulations, video conferencing has been added.'
                    ) : connectedChannelInfo?.provider === 'phone' ? (
                      <>
                        <span className="md:block hidden">Congratulations,</span>
                        <span className="md:inline">
                          Congratulations, your new business phone number is:{' '}
                          {formatContactData(phoneChannel?.AccountUserValue)}.
                        </span>
                      </>
                    ) : (
                      `${
                        connectedChannelInfo?.provider || 'Your channel'
                      } is connecting... 🎉`
                    )
                  ) : (
                    `It looks like there was an issue connecting your ${
                      connectedChannelInfo?.provider || ''
                    } account. ⚙️`
                  )}
                </Header>
                <ParagraphText
                  variant="lg"
                  className="text-center font-normal leading-[22px] w-[672px] md:w-[310px]"
                >
                  {successConnection ? (
                    connectedChannelInfo?.provider === 'video' ? (
                      'Videochat with clients from anywhere. No download required.'
                    ) : connectedChannelInfo?.provider === 'phone' ? (
                      'Enjoy calls and voicemail right away. Register* to send SMS.'
                    ) : (
                      <>
                        This may take a moment, while Command Center
                        <br />
                        requests data from{' '}
                        {connectedChannelInfo?.provider || 'your provider'}.
                      </>
                    )
                  ) : (
                    <>
                      Please retry adding your account. If the problem persists,{' '}
                      <b>contact support</b> for further assistance.
                    </>
                  )}
                </ParagraphText>
                <div className="h-[252px] md:h-[210px]">
                  {successConnection ? (
                    <img
                      src={onboardingSuccessThryvGuy}
                      alt={'Channel Connected Successfully'}
                      className="w-[234px]"
                    />
                  ) : (
                    <img
                      src={onboardingFailureThryvGuy}
                      alt={'Channel Connected Unsuccessfully'}
                      className="w-[234px]"
                    />
                  )}
                </div>
                <Button onClick={close} className="w-[204px]" variant="primary">
                  {successConnection
                    ? 'Add the next channel'
                    : 'Back to adding channels'}
                </Button>
              </div>
            </div>
          </ModalContainer>
        </ModalWrapper>
      )}
    </>
  )
}

export default OnboardingModal
