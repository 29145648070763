import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import usePhoneChannel from '../../../../hooks/use-phone-channel'
import { GET_SUNSHINE_SESSION_STATUS } from '../../../../graphql/queries/channels-queries'
import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

export const useAvailableChannelsByThread = (channelInfo, recipient) => {
  const { toggleTenDlc } = useFlags()
  const { countryIso2 } = useSelector((state) => state.countryCode)
  const [phoneChannel] = usePhoneChannel()
  const brandStatus = useSelector(
    (state) => state.phoneRegistration.brand?.brand_status,
  )
  const campaignStatus = useSelector(
    (state) => state.phoneRegistration.brand?.campaign_status,
  )
  const threadId = useSelector((state) => state.inboxThreads.selectedThread.id)
  const [fetchSesionStatus] = useLazyQuery(GET_SUNSHINE_SESSION_STATUS)

  const activeChannels = channelInfo?.filter(
    (channel) => channel.Status === '1' && channel.Provider !== 'video',
  )

  // if (skip) {
  //   return { activeWithoutPhone: [], disabledWithoutPhone: [] }
  // }

  const isActive =
    toggleTenDlc ||
    countryIso2 === 'AU' ||
    countryIso2 === 'CA' ||
    countryIso2 === 'NZ' ||
    brandStatus === 'PENDING' ||
    brandStatus === 'ACTIVE' ||
    brandStatus === 'VERIFIED' ||
    brandStatus === 'UNVERIFIED' ||
    campaignStatus === 'UPDATES_REQUIRED' ||
    campaignStatus === 'PENDING_REVIEW' ||
    campaignStatus === 'ACTIVE' ||
    phoneChannel?.ChannelAccessLevel === '2'

  let channelsByThread = []
  let disabledChannelsByThread = []
  let [sunshineChls, setSunshineChls] = useState({})

  // make sure to update the session status of the sunshine channels
  const handleChannelProcessing = async () => {
    // only run query for sunshine channels
    let filteredChannels = activeChannels.filter((chl) => chl.VendorID === '3')
    try {
      for (const chl of filteredChannels) {
        const {
          data: {
            querySunshineSessionStatus: { Status },
          },
        } = await fetchSesionStatus({
          variables: {
            ChannelId: chl.ChannelID,
            ThreadId: threadId,
          },
        })
        setSunshineChls((prev) => ({ ...prev, [chl.ChannelID]: Status }))
      }
    } catch (error) {
      // console.error('Error processing channels:', error)
    }
  }
  useEffect(() => {
    // if threadId is empty, don't run the query since it's a new conversation
    if (channelInfo && threadId) handleChannelProcessing()
  }, [channelInfo, threadId])

  activeChannels.forEach((channel) => {
    if (
      (channel.VendorID === '1' &&
        recipient.email &&
        recipient.email !== 'Unallocated') ||
      recipient.email === 'Facebook Messenger' ||
      (channel.VendorID === '2' && recipient.phoneNumber) ||
      (channel.VendorID === '3' && recipient.email === 'Facebook Messenger') ||
      (channel.VendorID === '3' && recipient.email === 'Unallocated')
    ) {
      if (channel.VendorID === '3' && sunshineChls[channel.ChannelID] === 'false') {
        disabledChannelsByThread.push(channel)
      } else {
        channelsByThread.push(channel)
      }
    } else {
      disabledChannelsByThread.push(channel)
    }
  })

  const activeWithoutPhone = !isActive
    ? channelsByThread.filter(
        (x) => x.Provider !== 'phone' && x.ChannelAccessLevel === '2',
      )
    : channelsByThread

  const disabledWithoutPhone = !isActive
    ? disabledChannelsByThread.filter(
        (x) => x.Provider !== 'phone' && x.ChannelAccessLevel === '2',
      )
    : disabledChannelsByThread
  return {
    channelsByThread: activeWithoutPhone,
    disabledChannelsByThread: disabledWithoutPhone,
    isActive,
  }
}
