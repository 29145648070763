import { createSlice } from '@reduxjs/toolkit'

const selectedInboxMobilePage = localStorage.getItem('selected-inbox-mobile-page')

if (!selectedInboxMobilePage) {
  localStorage.setItem('selected-inbox-mobile-page', 'inbox-menu')
}

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState: {
    startNewMessage: false,
    recipient: {
      id: '',
      imgUrl: '',
      fullName: '',
      email: '',
      handle: {
        instagram: null,
      },
      phoneNumber: null,
    },
    hasBusinessCenter: true,
    locationId: '1',
    channelId: '1',
    isLoading: false,
    justNowTimer: false,
    confirmAttachment: [],
    selectedInboxMobilePage: selectedInboxMobilePage || 'inbox-menu',
    error: { status: false, message: '' },
    moderationError: false,
    reloadMessage: false,
    draftPk1: '',
    draftChanPk: '',
    viewFullMessageOpen: false,
    messageInModal: '',
    incomingContactName: '',
    ccRecurlyPlan: '',
    updatedContactMergeNotification: '',
    conversationLimit: 10,
    numOfDates: 100,
    timeThreads: {
      todayMessageThreads: [],
      yesterdayMessageThreads: [],
      lastWeekMessageThreads: [],
      lastMonthMessageThreads: [],
      lastYearMessageThreads: [],
      overOneYearMessageThreads: [],
    },
    pinnedMessageThreads: [],
    customMessageThreads: [],
    threadData: [],
    hideThreadsMenu: false, //used to hide threads when using the global search,
    searchingDate: '',
    initialDate: '',
    openCalendar: false,
    currentDate: null,
  },
  reducers: {
    setThreadData(state, action) {
      state.threadData = action.payload
    },
    setConversationLimit(state, action) {
      state.conversationLimit = action.payload
    },
    setNumOfDates(state, action) {
      state.numOfDates = action.payload
    },
    setPinnedThreads(state, action) {
      state.pinnedMessageThreads = action.payload
    },
    setTimeThreads(state, action) {
      const {
        todayMessageThreads,
        yesterdayMessageThreads,
        lastWeekMessageThreads,
        lastMonthMessageThreads,
        lastYearMessageThreads,
        overOneYearMessageThreads,
      } = action.payload
      state.timeThreads = {
        todayMessageThreads,
        yesterdayMessageThreads,
        lastWeekMessageThreads,
        lastMonthMessageThreads,
        lastYearMessageThreads,
        overOneYearMessageThreads,
      }
    },
    setCustomThreads(state, action) {
      state.customMessageThreads = action.payload
    },
    handleStartNewMessage(state, action) {
      state.startNewMessage = action.payload
    },
    setRecipient(state, action) {
      const newRecipient = {
        id: action.payload.id || '',
        imgUrl: action.payload.imgUrl || '',
        fullName: action.payload.fullName || '',
        email: action.payload.email || '',
        handle: action.payload.handle || {
          instagram: '',
        },
        phoneNumber: action.payload.phoneNumber || '',
      }

      state.recipient = newRecipient
    },
    setHasBusinessCenter(state, action) {
      state.hasBusinessCenter = action.payload
    },
    setLocationId(state, action) {
      state.locationId = action.payload
    },
    setChannelId(state, action) {
      state.channelId = action.payload
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload
    },
    setSelectedInboxMobilePage(state, action) {
      const newSelectedPageName = action.payload
      state.selectedInboxMobilePage = newSelectedPageName
    },
    setError(state, action) {
      const errorObject = {
        status: action.payload.status || false,
        message: action.payload.message || '',
      }
      state.error = errorObject
    },
    handleReloadMessage(state, action) {
      state.reloadMessage = action.payload
    },
    setDraftPk1(state, action) {
      state.draftPk1 = action.payload
    },
    setDraftChanPk(state, action) {
      state.draftChanPk = action.payload
    },
    setViewFullMessageOpen(state, action) {
      state.viewFullMessageOpen = action.payload
    },
    setMessageInModal(state, action) {
      state.messageInModal = action.payload
    },
    setIncomingContactName(state, action) {
      state.incomingContactName = action.payload
    },
    setCCRecurlyPlan(state, action) {
      state.ccRecurlyPlan = action.payload
    },
    setUpdatedContactMergeNotification(state, action) {
      state.updatedContactMergeNotification = action.payload
    },
    setHideThreadsMenu(state, action) {
      state.hideThreadsMenu = action.payload
    },
    setJustNowTimer(state, action) {
      state.justNowTimer = action.payload
    },
    setConfirmAttachmentData(state, action) {
      const attachmentObject = {
        body: action.payload.body,
        pk1: action.payload.pk1,
        sk1: action.payload.sk1,
      }
      state.confirmAttachment.push(attachmentObject)
    },
    clearConfirmAttachmentData(state) {
      state.confirmAttachment = []
    },
    setSearchingDate(state, action) {
      const { searchingDate, initialDate } = action.payload
      state.searchingDate = searchingDate
      state.initialDate = initialDate
    },
    setOpenCalendar(state, action) {
      state.openCalendar = action.payload
    },
    setCurrentDate(state, action) {
      state.currentDate = action.payload
    },
  },
})

export const {
  handleStartNewMessage,
  setRecipient,
  setHasBusinessCenter,
  setLocationId,
  setChannelId,
  setIsLoading,
  setCreatedMessageId,
  setError,
  handleReloadMessage,
  setDraftPk1,
  setSelectedInboxMobilePage,
  setViewFullMessageOpen,
  setMessageInModal,
  setIncomingContactName,
  setCCRecurlyPlan,
  setUpdatedContactMergeNotification,
  setUpdatedContactErrorNotification,
  setPinnedThreads,
  setTimeThreads,
  setCustomThreads,
  setConversationLimit,
  setNumOfDates,
  setThreadData,
  setDraftChanPk,
  setHideThreadsMenu,
  setJustNowTimer,
  setConfirmAttachmentData,
  clearConfirmAttachmentData,
  setSearchingDate,
  setOpenCalendar,
  setCurrentDate,
} = inboxSlice.actions

export default inboxSlice.reducer
