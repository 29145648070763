import step1Image1 from '../../assets/onboarding-images/step1-image1.jpg'
import step1Image2 from '../../assets/onboarding-images/step1-image2.jpg'
import step1Image3 from '../../assets/onboarding-images/step1-image3.jpg'

import step2Image1 from '../../assets/onboarding-images/step2-image1.png'
import step2Image2 from '../../assets/onboarding-images/step2-image2.png'
import step2Image3 from '../../assets/onboarding-images/step2-image3.png'
import step2Image4 from '../../assets/onboarding-images/step2-image4.png'
import step2Image5 from '../../assets/onboarding-images/step2-image5.png'

import step3Image1 from '../../assets/onboarding-images/step3-image1.jpg'
import step3Image2 from '../../assets/onboarding-images/step3-image2.jpg'

export const step1Images = [
  {
    id: 1,
    src: step1Image1,
    alt: 'Onboarding Carousel Image 1',
    header: 'Contact Information',
    txt: "All your messages from a contact shown in one conversation, regardless of where they came from - there's no need to go searching!",
  },
  {
    id: 2,
    src: step1Image2,
    alt: 'Onboarding Carousel Image 2',
    header: 'Accessible Client History',
    txt: "View a customer's full communication history in one place, so you never miss an opportunity.",
  },
  {
    id: 3,
    src: step1Image3,
    alt: 'Onboarding Carousel Image 3',
    header: 'Unified Communications',
    txt: 'Access emails, texts, and voicemails in one powerful Inbox, making Inbox clutter a thing of the past.',
  },
]

export const step2Images = [
  {
    id: 1,
    src: step2Image1,
    alt: 'Onboarding Carousel Image 1',
    header: 'Contact Information',
    txt: "All your messages from a contact shown in one conversation, regardless of where they came from - there's no need to go searching!",
  },
  {
    id: 2,
    src: step2Image2,
    alt: 'Onboarding Carousel Image 2',
    header: 'Accessible Client History',
    txt: "View a customer's full communication history in one place, so you never miss an opportunity.",
  },
  {
    id: 3,
    src: step2Image3,
    alt: 'Onboarding Carousel Image 3',
    header: 'Unified Communications',
    txt: 'Access emails, texts, and voicemails in one powerful Inbox, making Inbox clutter a thing of the past.',
  },
  {
    id: 4,
    src: step2Image4,
    alt: 'Onboarding Carousel Image 3',
    header: 'Unified Communications',
    txt: 'Access emails, texts, and voicemails in one powerful Inbox, making Inbox clutter a thing of the past.',
  },
  {
    id: 5,
    src: step2Image5,
    alt: 'Onboarding Carousel Image 3',
    header: 'Unified Communications',
    txt: 'Access emails, texts, and voicemails in one powerful Inbox, making Inbox clutter a thing of the past.',
  },
]

export const step3Images = [
  {
    id: 1,
    src: step3Image1,
    alt: 'Onboarding Carousel Image 1',
    header: 'Thryv Command Center®',
    txt: 'organizes all of your conversations in one centralized inbox so you can effortlessly communicate with customers and team members.',
  },
  {
    id: 2,
    src: step3Image2,
    alt: 'Onboarding Carousel Image 2',
    header: 'Thryv Command Center®',
    txt: 'organizes all of your conversations in one centralized inbox so you can effortlessly communicate with customers and team members.',
  },
  {
    id: 3,
    src: step1Image2,
    alt: 'Onboarding Carousel Image 3',
    header: 'Thryv Command Center®',
    txt: 'organizes all of your conversations in one centralized inbox so you can effortlessly communicate with customers and team members.',
  },
]
