import { createSlice } from '@reduxjs/toolkit'
import { inboxSorts } from '../inbox-menu/data/sort-options'
import inboxFilters from '../inbox-menu/data/filter-options.json'

let selectedThread = JSON.parse(localStorage.getItem('inbox-selected-thread'))
let selectedSort = localStorage.getItem('inbox-menu-selected-sort')
let selectedFilter = localStorage.getItem('inbox-menu-selected-filter')
let advancedFilters = JSON.parse(localStorage.getItem('inbox-menu-advanced-filters'))
const DEFAULT_THREAD_CONVERSATION_LIMIT_DATA = {
  limit: 50,
  allLoaded: false,
}

if (!selectedThread) {
  selectedThread = { id: '', name: '', spamsk1: '', trashsk: '' }
  localStorage.setItem('inbox-selected-thread', JSON.stringify(selectedThread))
}

if (!selectedFilter) {
  selectedFilter = inboxFilters[0]
  localStorage.setItem('inbox-menu-selected-filter', inboxFilters[0])
}

if (!selectedSort) {
  selectedSort = inboxSorts[2]
  localStorage.setItem('inbox-menu-selected-sort', selectedSort)
}

{
  /* Uncomment the if statement if we want the filters to persist */
}
// if (!advancedFilters) {
advancedFilters = {
  folder: inboxFilters[0],
  channels: [], // Empty array means "Any", view AVAILABLE_CHANNELS for example of channels
  date: {
    type: 'Any',
    dates: ['', ''],
  },
  labels: [], // Array of labels, each in this format: { id: String, value: String, checked: Boolean }
  attachment: false, //Boolean
}
localStorage.setItem('inbox-menu-advanced-filters', JSON.stringify(advancedFilters))
// }

export const pinnedThreadsActionsSlice = createSlice({
  name: 'inboxThreads',
  initialState: {
    inboxNotification: {
      show: false,
      timeoutId: undefined,
    },
    selectedThread: selectedThread,
    mostRecentThread: [
      {
        id: '',
        name: '',
      },
      {
        id: '',
        name: '',
      },
    ],
    messageSent: false,
    inboxFilter: selectedFilter,
    inboxSort: selectedSort,
    inboxAdvancedFilters: advancedFilters,
    inboxThreadIds: [],
    inboxThreadSk1s: [],
    inboxPinnedThreadIds: [],
    inboxPinnedThreadSk1s: [],
    timeframe: '',
    selectedThreadCompleted: false,
    selectedThreadSpam: false,
    selectedThreadTrash: false,
    threadLimit: DEFAULT_THREAD_CONVERSATION_LIMIT_DATA,
    customThreadLimit: DEFAULT_THREAD_CONVERSATION_LIMIT_DATA,
    threadsDoneLoading: {
      today: false,
      yesterday: false,
      lastWeek: false,
      lastMonth: false,
      lastYear: false,
      overOneYear: false,
    },
    bulkEditBtnClicked: false,
    bulkSelectAllBtnChecked: false,
    bulkSelectedThreadIds: [],
    bulkSelectedThreadSk1s: [],
    showMarkAsMenu: false,
    unreadThreadIds: [],
  },
  reducers: {
    setShowInboxNotification(state, action) {
      const show = action.payload
      const notification = state.inboxNotification

      switch (show) {
        case true:
          notification.show = true
          break
        case false:
          notification.show = false
      }
    },

    updateInboxNotificationTimeoutId(state, action) {
      const newTimeoutId = action.payload
      const notification = state.inboxNotification

      if (notification.timeoutId) {
        clearTimeout(notification.timeoutId)
      }

      notification.timeoutId = newTimeoutId
    },

    clearInboxNotificationTimeout(state) {
      const notification = state.inboxNotification
      if (notification.timeoutId) {
        clearTimeout(notification.timeoutId)
      }
    },

    setSelectedThread(state, action) {
      state.selectedThread = action.payload
    },

    setMostRecentThread(state, action) {
      state.mostRecentThread = action.payload
    },

    setSelectedThreadCompleted(state, action) {
      state.selectedThreadCompleted = action.payload
    },

    setSelectedThreadSpam(state, action) {
      state.selectedThreadSpam = action.payload
    },

    setSelectedThreadTrash(state, action) {
      state.selectedThreadTrash = action.payload
    },

    setMessageSent(state, action) {
      state.messageSent = action.payload
    },

    setInboxSort(state, action) {
      // VALID SORTS available at inboxSorts.js
      const newSort = action.payload
      state.inboxSort = newSort
    },

    setTimeframe(state, action) {
      state.timeframe = action.payload
    },

    setInboxFilter(state, action) {
      state.inboxFilter = action.payload
    },

    setInboxAdvancedFilters(state, action) {
      const currentFilters = state.inboxAdvancedFilters
      const newFilters = action.payload

      const newAdvancedFilters = {
        folder: newFilters.folder || currentFilters.folder,
        channels: newFilters.channels || currentFilters.channels,
        date: newFilters.date || currentFilters.date,
        labels: newFilters.labels || currentFilters.labels,
        attachment: newFilters.attachment || currentFilters.attachment,
      }

      state.inboxAdvancedFilters = newAdvancedFilters
    },

    setInboxThreadIds(state, action) {
      state.inboxThreadIds = action.payload
    },
    setPinnedInboxThreadIds(state, action) {
      state.inboxPinnedThreadIds = action.payload
    },
    clearInboxThreadIds(state, action) {
      state.inboxThreadIds = action.payload
    },
    setInboxThreadSk1s(state, action) {
      state.inboxThreadSk1s = action.payload
    },
    setPinnedInboxThreadSk1s(state, action) {
      state.inboxPinnedThreadSk1s = action.payload
    },

    incrementThreadLimit(state, action) {
      const { incrementAmount } = action.payload

      if (!state.threadLimit.allLoaded) {
        const currLimit = state.threadLimit.limit
        const newLimit = currLimit + incrementAmount || 0
        state.threadLimit.limit = newLimit
      }
    },

    setThreadsAllLoaded(state, action) {
      const newAllAreLoaded = action.payload.allLoaded

      if (typeof newAllAreLoaded === 'boolean') {
        state.threadLimit.allLoaded = newAllAreLoaded
      }
    },

    toggleThreadsDoneLoading(state, action) {
      const threadName = action.payload.thread
      const isLoaded = action.payload.loading
      state.threadsDoneLoading[threadName] = isLoaded
    },

    addLabel(state, action) {
      const newLabel = action.payload

      if (newLabel.value && newLabel.id) {
        state.inboxAdvancedFilters.labels.push({
          id: newLabel.id,
          value: newLabel.value,
          checked: newLabel.checked || false,
        })
      } else {
        //
      }
    },

    removeLabel(state, action) {
      const labelToDeleteId = action.payload
      const filteredLabels = state.inboxAdvancedFilters.labels.filter(
        (label) => label.id !== labelToDeleteId,
      )

      state.inboxAdvancedFilters.labels = filteredLabels
    },

    updateLabelChecked(state, action) {
      const { id: labelId, checked: newCheckedValue } = action.payload

      const updatedLabels = state.inboxAdvancedFilters.labels.map((label) => {
        if (label.id === labelId) {
          label.checked = newCheckedValue || label.checked
        }
        return label
      })

      state.inboxAdvancedFilters.labels = updatedLabels
    },
    setBulkEditBtnClicked(state, action) {
      state.bulkEditBtnClicked = action.payload
    },

    setBulkSelectAllBtnChecked(state, action) {
      state.bulkSelectAllBtnChecked = action.payload
    },
    setBulkSelectedThreadIds(state, action) {
      const id = action.payload

      if (state.bulkSelectAllBtnChecked) {
        if (Array.isArray(id)) {
          if (id.length === 0) {
            state.bulkSelectedThreadIds = []
          }
          id.map((threadId) => {
            if (!state.bulkSelectedThreadIds.includes(threadId)) {
              state.bulkSelectedThreadIds.push(threadId)
            }
          })
        } else {
          if (state.bulkSelectedThreadIds.includes(id)) {
            state.bulkSelectedThreadIds = state.bulkSelectedThreadIds.filter(
              (item) => item !== id,
            )
          } else {
            state.bulkSelectedThreadIds.push(id)
          }
        }
      } else {
        if (Array.isArray(id)) {
          if (id.length === 0) {
            state.bulkSelectedThreadIds = []
          }
          id.map((threadId) => {
            if (state.bulkSelectedThreadIds.includes(threadId)) {
              state.bulkSelectedThreadIds = state.bulkSelectedThreadIds.filter(
                (item) => item !== threadId,
              )
            } else {
              state.bulkSelectedThreadIds.push(threadId)
            }
          })
        } else {
          if (state.bulkSelectedThreadIds.includes(id)) {
            state.bulkSelectedThreadIds = state.bulkSelectedThreadIds.filter(
              (item) => item !== id,
            )
          } else {
            state.bulkSelectedThreadIds.push(id)
          }
        }
      }
    },
    setBulkSelectedThreadSk1s(state, action) {
      const threads = action.payload

      if (state.bulkSelectAllBtnChecked) {
        if (Array.isArray(threads)) {
          if (threads.length === 0) {
            state.bulkSelectedThreadSk1s = []
          }
          threads.map((sk1) => {
            if (!state.bulkSelectedThreadSk1s.map((x) => x.sk1).includes(sk1.sk1)) {
              state.bulkSelectedThreadSk1s.push(sk1)
            }
          })
        } else {
          if (state.bulkSelectedThreadSk1s.map((x) => x.sk1).includes(threads.sk1)) {
            state.bulkSelectedThreadSk1s = state.bulkSelectedThreadSk1s.filter(
              (item) => item.sk1 !== threads.sk1,
            )
          } else {
            state.bulkSelectedThreadSk1s.push(threads)
          }
        }
      } else {
        if (Array.isArray(threads)) {
          if (threads.length === 0) {
            state.bulkSelectedThreadSk1s = []
          }
          threads.map((sk1) => {
            if (state.bulkSelectedThreadSk1s.map((x) => x.sk1).includes(sk1.sk1)) {
              state.bulkSelectedThreadSk1s = state.bulkSelectedThreadSk1s.filter(
                (item) => item.sk1 !== sk1.sk1,
              )
            } else {
              state.bulkSelectedThreadSk1s.push(sk1)
            }
          })
        } else {
          if (state.bulkSelectedThreadSk1s.map((x) => x.sk1).includes(threads.sk1)) {
            state.bulkSelectedThreadSk1s = state.bulkSelectedThreadSk1s.filter(
              (item) => item.sk1 !== threads.sk1,
            )
          } else {
            state.bulkSelectedThreadSk1s.push(threads)
          }
        }
      }
    },
    setShowMarkAsMenu(state, action) {
      state.showMarkAsMenu = action.payload
    },
    setUnreadThreadIds(state, action) {
      state.unreadThreadIds = action.payload
    },
  },
})

export const {
  setMessageSent,
  setSelectedThread,
  setMostRecentThread,
  setInboxSort,
  setInboxFilter,
  setInboxThreadIds,
  setTimeframe,
  setInboxAdvancedFilters,
  setThreadsAllLoaded,
  incrementThreadLimit,
  addLabel,
  removeLabel,
  updateLabelChecked,
  toggleThreadsDoneLoading,
  setShowInboxNotification,
  updateInboxNotificationTimeoutId,
  clearInboxNotificationTimeout,
  setSelectedThreadCompleted,
  setSelectedThreadSpam,
  setSelectedThreadTrash,
  setBulkEditBtnClicked,
  setBulkSelectAllBtnChecked,
  setBulkSelectedThreadIds,
  setShowMarkAsMenu,
  setUnreadThreadIds,
  clearInboxThreadIds,
  setPinnedInboxThreadIds,
  setBulkSelectedThreadSk1s,
  setPinnedInboxThreadSk1s,
  setInboxThreadSk1s,
} = pinnedThreadsActionsSlice.actions

export default pinnedThreadsActionsSlice.reducer
