import { useDispatch, useSelector } from 'react-redux'
import { selectedThreadSelector } from '../../../inbox/slices/inboxThreads.selectors'
import { useContactsContext } from '../contexts/contacts-context'
import {
  setOpenVideoCallLinkModal,
  setPrepopulatedNumber,
} from '../../../calls/slices/callSlice'
import { useNavigate } from 'react-router-dom'
import { useVideoAndPhoneButtonData } from '../hooks/useVideoAndPhoneButtonData'
import { useVideoRoomData } from '../hooks/useVideoRoomData'
import { batch } from 'react-redux'
import { connectChannelsSelector } from '../../../common/modals/slices/connectedChannels.selectors'

// NOTE: WHAT DOES THIS MEAN? WHAT KIND OF MODAL? WHAT ARE THESE IMPORTS? I PROPOSE RECREATING THIS
import {
  setShowModal,
  setModalContent,
} from '../../../common/modals/slices/connectChannelsSlice'

// Components
import { Header } from '@thryvlabs/maverick'
import { UserAvatar } from '../../mobile/components/UserAvatar'
import { PhoneButton } from '../../mobile/components/PhoneButton'
import { VideoButton } from '../../mobile/components/VideoButton'
import RightPanelUserProfile from '../user-profile/RightPanelUserProfile'
import { HorizontalLine } from '../../mobile/components/HorizontalLine'
import { Button } from '@thryvlabs/maverick'
import ViewInBusinessCenterLink from './components/ViewInBusinessCenterLink'

const { VITE_ENV } = import.meta.env

// ***** CONSTANTS *****
const BUSINESS_CENTER_BASE_URL =
  VITE_ENV === 'prod' ? 'https://go.thryv.com' : 'https://emp.thryv.com'

const GreyButton = ({ children, ...rest }) => {
  return (
    <Button
      className="max-w-[196px] w-full h-[38px] text-[#231F20] text-[14px]"
      variant="secondary"
      {...rest}
    >
      {children}
    </Button>
  )
}

export const ContactPanel = ({
  className,
  show,
  // isMobile, // UNCOMMENT IF WE NEED TO USE THIS.
  onEditClick,
  openConnectPhoneModal,
}) => {
  const navigate = useNavigate()
  const { contact, isThryvSupportEmail } = useContactsContext()

  const dispatch = useDispatch()

  const { hasBusinessCenter } = useSelector((state) => state.userPreferences)
  const hasContactInVcita = contact.body?.vclientid
  const showViewInBusinessCenterButton = hasBusinessCenter && hasContactInVcita

  const { selectedThreadId } = useSelector(selectedThreadSelector)
  const { channelInfo, maxChannelsAllowed } = useSelector(connectChannelsSelector)
  const usersChannels = channelInfo

  const {
    videoButton, // This is a object with the following keys: isVisible, isDisabled
    phoneButton, // This is a object with the following keys: isVisible, isDisabled
  } = useVideoAndPhoneButtonData(contact)

  const { isVideoCallActive } = useVideoRoomData()

  const contactName = isThryvSupportEmail
    ? 'Thryv Support Center'
    : contact.fullName || 'Unknown'

  const setOnCall = () => {
    dispatch(setPrepopulatedNumber(`+${contact?.phone}` || ''))
  }

  return (
    <div
      className={`${className} ${!show && 'hidden'} flex flex-col justify-between`}
    >
      {/* CONTACT INFORMATION */}
      <div>
        <div className="relative mb-[8px]">
          <UserAvatar
            isThryvGuy={isThryvSupportEmail}
            imageUrl={contact?.pictureUrl}
            userName={contact.fullName.toUpperCase()}
          />
        </div>

        <Header
          fontWeight="semibold"
          variant="h7"
          className={`normal-case text-[14px] text-center ${
            showViewInBusinessCenterButton &&
            !phoneButton.isVisible &&
            !videoButton.isVisible
              ? 'mb-[16px]'
              : 'mb-[24px]'
          }`}
        >
          {contactName}
        </Header>

        <div
          className={`flex justify-center 
            ${!phoneButton.isVisible && !videoButton.isVisible && 'hidden'}
            ${showViewInBusinessCenterButton ? 'mb-[16px]' : 'mb-[24px]'}`}
        >
          <div className="flex gap-[32px]">
            {phoneButton.isVisible && (
              <PhoneButton
                onClick={setOnCall}
                disabled={phoneButton.isDisabled}
                aria-label="Go to Calls Page"
              />
            )}

            {videoButton.isVisible && (
              <VideoButton
                onClick={() => {
                  if (isVideoCallActive) {
                    navigate('/meetings')
                  } else {
                    navigate({ pathname: '/inbox' })
                    dispatch(setOpenVideoCallLinkModal(true))
                  }
                }}
                disabled={videoButton.isDisabled}
                aria-label="Initiate Video Call"
                tooltipVisible={isVideoCallActive}
                isActive={isVideoCallActive}
              />
            )}
          </div>
        </div>

        {showViewInBusinessCenterButton && (
          <ViewInBusinessCenterLink
            className="mb-[24px]"
            href={`${BUSINESS_CENTER_BASE_URL}/app/clients/${contact.body.vclientid}`}
          />
        )}

        <HorizontalLine className="mb-[24px]" />

        <RightPanelUserProfile
          onEditClick={onEditClick}
          contact={{
            phone: contact.phone,
            email: contact.email,
            threadpk: selectedThreadId.split('#')[0],
          }}
        />
      </div>

      {/* ADD CALLS/VIDEO BUTTONS */}
      <div className="flex flex-col gap-4 items-center mt-[50px]">
        {!phoneButton.isVisible && (
          <GreyButton
            onClick={() => {
              if (usersChannels?.length >= parseInt(maxChannelsAllowed)) {
                dispatch(setShowModal(true))
                dispatch(setModalContent(4)) // Opens the Upgrade CC Modal
              } else {
                openConnectPhoneModal()
              }
            }}
          >
            Add Calls
          </GreyButton>
        )}

        {!videoButton.isVisible && (
          <GreyButton
            onClick={() => {
              batch(() => {
                dispatch(setShowModal(true))
                if (usersChannels?.length >= parseInt(maxChannelsAllowed)) {
                  dispatch(setModalContent(4)) // Opens the Upgrade CC Modal
                } else {
                  dispatch(setModalContent(8)) // Opens the Add Video Channel Modal
                }
              })
            }}
          >
            Add Video
          </GreyButton>
        )}
      </div>
    </div>
  )
}
