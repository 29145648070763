import {
  getMonth,
  getYear,
  getPrevMonth,
  getNextMonth,
} from './utils/headerFunctions'

const ArrowLeft = ({ children, ...rest }) => (
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {children}
    <path
      d="M11 5l-7 7 7 7M4 12h16"
      stroke="#231F20"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const ArrowRight = ({ children, ...rest }) => (
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {children}
    <path
      d="M4 12h16M13 5l7 7-7 7"
      stroke="#231F20"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const DatePickerHeader = ({ calendarMoment, setCalendarMoment }) => {
  const handleSetDateValuePrevMonth = () => {
    if (calendarMoment && setCalendarMoment) {
      setCalendarMoment(getPrevMonth(calendarMoment))
    }
  }

  const handleSetDateValueNextMonth = () => {
    if (calendarMoment && setCalendarMoment) {
      setCalendarMoment(getNextMonth(calendarMoment))
    }
  }

  return (
    <div className="flex w-full justify-between items-center">
      <span
        className="cursor-pointer"
        onClick={() => handleSetDateValuePrevMonth()}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleSetDateValuePrevMonth()
          }
        }}
        role="button"
        tabIndex={0}
        aria-label="Previous Month"
      >
        <ArrowLeft />
      </span>
      <span className="text-black font-open-sans font-semibold text-base">
        {calendarMoment && getMonth(calendarMoment)}{' '}
        {calendarMoment && getYear(calendarMoment)}
      </span>
      <span
        className="cursor-pointer"
        onClick={() => handleSetDateValueNextMonth()}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleSetDateValueNextMonth()
          }
        }}
        role="button"
        tabIndex={0}
        aria-label="Next Month"
      >
        <ArrowRight />
      </span>
    </div>
  )
}
