import { useContext, useState, useEffect } from 'react'
import { ParagraphText, Icon, Tooltip, Button } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../../../../command-center-icon'
import moment from 'moment'
import { IncomingMessageContext } from '../../../../../../context/message-context'
import { LabelOnMessage } from '../../../labels/label-on-message'
import { useSelector } from 'react-redux'

export const WarningNotification = ({ children }) => {
  return (
    <div className={`flex gap-1 items-center`}>
      <Icon
        variant="triangleExclamation"
        type="solid"
        color="#FAC02B"
        height="16"
        width="16"
      />
      <ParagraphText
        variant="sm"
        className="font-['Montserrat'] text-[10px] italic !font-medium"
        color="thryv-night"
      >
        {children}
      </ParagraphText>
    </div>
  )
}

const buttonLocalization = {
  en: 'Translate',
  es: 'Traducir',
  fr: 'Traduire',
  it: 'Tradurre',
  ko: '번역',
}

export const MessageFooter = ({
  variant = '',
  timestamp,
  text = '',
  id,
  labels,
  isDeletedMessage,
  showTranslation,
  showSwapTranslation,
  swapTranslation,
}) => {
  const incomingMessage = useContext(IncomingMessageContext)
  const [isCustomDate, setIsCustomDate] = useState(null)
  const [reminderSet, setReminderSet] = useState(false)
  const [isSameDatePersisted, setIsSameDatePersisted] = useState(null)
  const [customDate, setCustomDate] = useState()
  const { language } = useSelector((state) => state.user)

  const persistReminderTime = moment
    .utc(incomingMessage.remindsk)
    .local()
    .format('h:mm A')

  useEffect(() => {
    const persistCustomDate = moment
      .utc(incomingMessage.remindsk)
      .local()
      .format('ddd MMM D')
    setCustomDate(persistCustomDate)
  }, [customDate, incomingMessage.remindsk])

  useEffect(() => {
    const newReminderSkToDate = moment.utc(incomingMessage.remindsk).local()
    if (newReminderSkToDate !== 'Invalid date') {
      const futureDate = moment().add(2, 'days')
      setIsCustomDate(newReminderSkToDate.isSameOrAfter(futureDate, 'day'))
    }
  }, [isCustomDate, incomingMessage])

  useEffect(() => {
    if (incomingMessage.remindsk === null || incomingMessage.msgpk !== id) {
      return
    }
    const currentTime = Date.now()
    setReminderSet(currentTime < incomingMessage.remindsk)
    const timeRemaining = incomingMessage.remindsk - currentTime
    const removeReminder = setTimeout(() => {
      setReminderSet(false)
    }, timeRemaining)
    return () => clearTimeout(removeReminder)
  }, [incomingMessage.remindsk, incomingMessage.msgpk])

  useEffect(() => {
    const reminderSkToDate = moment.utc(incomingMessage.remindsk).local()
    if (reminderSkToDate !== 'Invalid date') {
      const isToday = moment()
      const futureDate = moment().add(2, 'days')
      setIsSameDatePersisted(reminderSkToDate.isSame(isToday, 'day'))
      setIsCustomDate(reminderSkToDate.isSameOrAfter(futureDate, 'day'))
    }
  }, [isSameDatePersisted, isCustomDate, incomingMessage])

  const renderWarningNotification = () => (
    <WarningNotification>{text}</WarningNotification>
  )

  const renderDefaultText = () => (
    <ParagraphText
      variant="sm"
      className="font-['Montserrat'] text-[10px] italic !font-medium sm:max-w-[295px]"
      color="thryv-night"
    >
      {text}
    </ParagraphText>
  )

  return (
    <div
      className={`${
        incomingMessage.chan_type === 'phone' ? 'flex-col ' : ''
      } mx-4 flex flex-wrap justify-between min-h-[20px] mb-[15px] max-w-[465px]`}
    >
      <div className="flex gap-2 items-center min-h-[20px]">
        {incomingMessage.remindsk !== null &&
        incomingMessage.msgpk === id &&
        reminderSet ? (
          <Tooltip
            variant="top"
            title={
              id === incomingMessage.msgpk && isSameDatePersisted && !isCustomDate
                ? `Today at ${persistReminderTime}`
                : id === incomingMessage.msgpk &&
                    !isSameDatePersisted &&
                    !isCustomDate
                  ? `Tomorrow at ${persistReminderTime}`
                  : id === incomingMessage.msgpk && isCustomDate
                    ? `${customDate} at ${persistReminderTime}`
                    : null
            }
            width={'150px'}
          >
            <CommandCenterIcon type="regular" variant="alarmClock" />
          </Tooltip>
        ) : null}

        <ParagraphText
          variant="sm"
          className="font-['Montserrat'] text-[10px] font-medium whitespace-nowrap"
          color="thryv-night"
        >
          {timestamp}
        </ParagraphText>

        {variant === 'default' && renderDefaultText()}
        {variant === 'warning' && renderWarningNotification()}
      </div>
      <div className="flex flex-row flex-wrap space-x-1">
        {!isDeletedMessage &&
          labels &&
          labels.map((label, i) => {
            return <LabelOnMessage key={i} label={label} />
          })}
      </div>
      {showSwapTranslation && (
        <Button variant="text" level={1} onClick={swapTranslation}>
          {showTranslation ? 'See Original' : buttonLocalization[language]}
        </Button>
      )}
    </div>
  )
}
