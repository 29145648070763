import Labels from './Labels'
import TranslationSettings from './TranslationSettings'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const ConversationsTab = () => {
  const { toggleUniversalTranslator } = useFlags()
  return (
    <div className="flex flex-col gap-6">
      <div className="sm:col-span-12 md:col-span-6 p-6 min-h-[348px] bg-[white] rounded border border-[#EFEFEF] shadow-[0px_4px_4px_0px_#0000000A] sm:w-[95%] sm:m-auto">
        <Labels />
      </div>
      {toggleUniversalTranslator && (
        <div className="sm:col-span-12 md:col-span-6 p-6 pb-8 bg-[white] rounded border border-[#EFEFEF] shadow-[0px_4px_4px_0px_#0000000A] sm:w-[95%] sm:m-auto">
          <TranslationSettings />
        </div>
      )}
    </div>
  )
}
