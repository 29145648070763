/* eslint-disable no-undef */
import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'

/*
######################
##     Reducers     ##
######################
*/
import inboxReducer from '../components/inbox/slices/inboxSlice'
import messageComposeReducer from '../components/inbox/slices/messageComposeSlice'
import messageContentReducer from '../components/inbox/slices/messageContentSlice'
import callsReducer from '../components/calls/slices/callSlice'
import contacts from './slices/calls/contacts/contacts-slice'
import remindMeReducer from '../components/inbox/slices/remindMeSlice'
import participantsReducer from '../components/inbox/slices/participants/participants-slice'
import countryCodeReducer from '../components/common/country-code-select/countryCodeSlice'
import businessInfoReducer from '../components/top-navigation-bar/slices/businessInfoSlice'
import leftNavigationReducer from '../components/left-navigation-bar/slices/left-nav-slice'
import connectChannelsSlice from '../components/common/modals/slices/connectChannelsSlice'
import planInfoSlice from '../components/common/modals/upgrade-plan-modal/slices/planInfoSlice'
import notificationReducer from './slices/notifications/notifications-slice'
import inboxThreadsReducer from '../components/inbox/slices/inboxThreadsSlice'
import inboxSearchReducer from '../components/inbox/slices/inbox-search/inbox-search-slice'
import userPreferenceReducer from './slices/user-preferences/user-preferences'
import meetings from './slices/meetings/meetings-slice'
import userReducer from './slices/user-info/user-info.slice'
import phoneRegistrationReducer from '../components/common/modals/slices/phoneRegistrationSlice'
import inboxToastNotificationReducer from '../components/inbox/slices/inboxToastNotificationSlice'
import welcomeReducer from '../components/inbox/slices/welcomeSlice'
import subscriptionReducer from '../modules/settings/MyAccount/ProductAndServices/subscription-slices'
import modalSlice from '../components/inbox/message-content/message-header/header-menu/buttons/tri-dot-button-with-menu/tri-dot-menu/modalSlice'
import inboxNotificationsSlice from '../components/inbox/slices/inbox-notifications/inbox-notifications-slice'

/*
#####################
##     Actions     ##
#####################
*/
import {
  setSelectedThread,
  setInboxSort,
  setInboxFilter,
  setInboxAdvancedFilters,
  setShowInboxNotification,
  updateInboxNotificationTimeoutId,
  clearInboxNotificationTimeout,
} from '../components/inbox/slices/inboxThreadsSlice'
import { setSelectedInboxMobilePage } from '../components/inbox/slices/inboxSlice'
import {
  updateInboxNotif,
  clearInboxNotifTimeout,
  updateInboxNotifTimeoutId,
} from '../components/inbox/slices/inbox-notifications/inbox-notifications-slice'

/*
##################################
##     Listener Middlewares     ##
##################################

// LM = Listener Middleware
*/

/* ----- INBOX MIDDLEWARES START ----- */

const LMForInboxSelectedThread = createListenerMiddleware()
LMForInboxSelectedThread.startListening({
  actionCreator: setSelectedThread,
  effect: (action) => {
    const newSelectedThread = {
      id: action.payload.id,
      name: action.payload.name,
      spamsk: action.payload.spamsk || '',
      trashsk: action.payload.trashsk || '',
    }

    localStorage.setItem('inbox-selected-thread', JSON.stringify(newSelectedThread))
  },
})

const LMForInboxMobilePage = createListenerMiddleware()
LMForInboxMobilePage.startListening({
  actionCreator: setSelectedInboxMobilePage,
  effect: (action) => {
    const newSelectedInboxMobilePage = action.payload
    localStorage.setItem('selected-inbox-mobile-page', newSelectedInboxMobilePage)
  },
})

const LMForInboxMenuSelectedSort = createListenerMiddleware()
LMForInboxMenuSelectedSort.startListening({
  actionCreator: setInboxSort,
  effect: (action) => {
    const newSort = action.payload
    localStorage.setItem('inbox-menu-selected-sort', newSort)
  },
})

const LMForInboxMenuSelectedFilter = createListenerMiddleware()
LMForInboxMenuSelectedFilter.startListening({
  actionCreator: setInboxFilter,
  effect: (action) => {
    const newFilter = action.payload
    localStorage.setItem('inbox-menu-selected-filter', newFilter)
  },
})

const LMForInboxMenuAdvancedFilters = createListenerMiddleware()
LMForInboxMenuAdvancedFilters.startListening({
  actionCreator: setInboxAdvancedFilters,
  effect: (action, listenerApi) => {
    const currentFilters = listenerApi.getState().inboxThreads.inboxAdvancedFilters

    const newFilters = action.payload

    const newAdvancedFilters = {
      folder: newFilters.folder || currentFilters.folder,
      channels: newFilters.channels || currentFilters.channels,
      date: newFilters.date || currentFilters.date,
      labels: newFilters.labels || currentFilters.labels,
    }

    localStorage.setItem(
      'inbox-menu-advanced-filters',
      JSON.stringify(newAdvancedFilters),
    )
  },
})

/* This restarts the inboxNotification timeout depending on payload. */
const LMForInboxMenuNotification = createListenerMiddleware()
LMForInboxMenuNotification.startListening({
  actionCreator: setShowInboxNotification,
  effect: (action, listenerApi) => {
    const dispatch = listenerApi.dispatch
    const notification = listenerApi.getState().inboxThreads.inboxNotification

    if (action.payload === true) {
      const timeoutId = setTimeout(() => {
        if (notification.show) {
          dispatch(setShowInboxNotification(false))
        }
      }, 5000)

      dispatch(updateInboxNotificationTimeoutId(timeoutId))
    } else if (action.payload === false && notification.timeoutId) {
      dispatch(clearInboxNotificationTimeout())
    }
  },
})

const LMForInboxMessageContentNotifications = createListenerMiddleware()
LMForInboxMessageContentNotifications.startListening({
  actionCreator: updateInboxNotif,
  effect: (action, listenerApi) => {
    const dispatch = listenerApi.dispatch
    const notification = action.payload

    const updateContactCreateNotifError = () => {
      if (notification.show) {
        const timeoutId = setTimeout(() => {
          dispatch(
            updateInboxNotif({
              name: 'contactCreateNotifError',
              show: false,
            }),
          )
        }, 5000)

        dispatch(
          updateInboxNotifTimeoutId({
            notifType: 'contactCreateNotifError',
            newTimeoutId: timeoutId,
          }),
        )
      } else if (!notification.show) {
        dispatch(clearInboxNotifTimeout('contactCreateNotifError'))
      }
    }

    switch (notification.name) {
      case 'contactCreateNotifError':
        updateContactCreateNotifError()
        break
      default:
        // eslint-disable-next-line no-console
        console.log(
          `WARNING: Notification: "${notification.name}" does not exist in redux store.`,
        )
    }
  },
})

/* ----- INBOX MIDDLEWARES START ----- */

/*
###################
##     Store     ##
###################
*/

const isProd = process.env.NODE_ENV === 'prod'

export const store = configureStore({
  reducer: {
    userPreferences: userPreferenceReducer,
    inbox: inboxReducer,
    messageCompose: messageComposeReducer,
    messageContent: messageContentReducer,
    calls: callsReducer,
    contacts,
    participants: participantsReducer,
    remindMe: remindMeReducer,
    countryCode: countryCodeReducer,
    businessInfo: businessInfoReducer,
    leftNavigation: leftNavigationReducer,
    connectChannels: connectChannelsSlice,
    notifications: notificationReducer,
    inboxSearch: inboxSearchReducer,
    inboxThreads: inboxThreadsReducer,
    planInfo: planInfoSlice,
    meetings,
    phoneRegistration: phoneRegistrationReducer,
    inboxToastNotification: inboxToastNotificationReducer,
    welcome: welcomeReducer,
    subscription: subscriptionReducer,
    modal: modalSlice,
    inboxNotifications: inboxNotificationsSlice,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) => {
    if (isProd) {
      return getDefaultMiddleware({ serializableCheck: false }).concat(
        LMForInboxSelectedThread.middleware,
        LMForInboxMobilePage.middleware,
        LMForInboxMenuSelectedSort.middleware,
        LMForInboxMenuSelectedFilter.middleware,
        LMForInboxMenuAdvancedFilters.middleware,
        LMForInboxMenuNotification.middleware,
        LMForInboxMessageContentNotifications.middleware,
      )
    } else {
      return getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        LMForInboxSelectedThread.middleware,
        LMForInboxMobilePage.middleware,
        LMForInboxMenuSelectedSort.middleware,
        LMForInboxMenuSelectedFilter.middleware,
        LMForInboxMenuAdvancedFilters.middleware,
        LMForInboxMenuNotification.middleware,
        LMForInboxMessageContentNotifications.middleware,
        logger,
      )
    }
  },
  devTools: process.env.NODE_ENV !== 'prod',
})
