import { Popover } from '@thryvlabs/maverick'

export const SecurityStaffSectionPopover = ({ className, children }) => {
  return (
    <Popover
      className={`bg-[white] rounded p-6 border border-[#EFEFEF] shadow-[0px_4px_4px_0px_#0000000A] relative ${className}`}
    >
      {children}
    </Popover>
  )
}
