import { Header, ParagraphText, Icon, Tooltip } from '@thryvlabs/maverick'
import ChannelsIcon from '../channels-icon/channels-icon'
import { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateRight } from '@fortawesome/pro-solid-svg-icons'
import { useSelector } from 'react-redux'

const ChannelsContainer = ({
  selectedChannels,
  channelConnected,
  clicked,
  setClicked,
  error,
  meta400Error,
}) => {
  const handleClick = ({ channel, idx }) => {
    if (channelConnected?.includes(channel)) {
      return
    }
    setClicked({ channel, idx })
    localStorage.setItem('onboardingSelectedChannel', channel)
  }

  const onboardingSelectedChannel = localStorage.getItem('onboardingSelectedChannel')

  useEffect(() => {
    if (
      channelConnected?.includes('phone') ||
      channelConnected?.includes('calls') ||
      channelConnected?.includes('video')
    ) {
      setClicked(null)
    }
  }, [channelConnected])

  return (
    <div className="flex flex-col gap-[24px]">
      {selectedChannels?.map((channel, idx) => {
        const uppercaseChannelName = channel?.toUpperCase()
        const channelTitle = channel?.charAt(0).toUpperCase() + channel?.slice(1)
        return (
          <div
            className={`flex flex-col gap-[24px] ${
              channelConnected?.includes(channel)
                ? 'cursor-default'
                : 'cursor-pointer'
            }`}
            key={idx}
            data-testid={`selectedChannel-${channel}`}
          >
            <div
              onClick={() => handleClick({ channel, idx })}
              className={`w-[340px] h-[92px]  md:w-[calc(340px-36px)] flex gap-[24px] items-center justify-between px-4 bg-white rounded-lg border-[1.5px] ${
                channelConnected?.includes(channel) && 'hover:border-[#ECEEF1]'
              } ${
                clicked?.idx === idx
                  ? 'border-[#FF5000] hover:border-[#FF5000]'
                  : 'border-[#ECEEF1] hover:border-[#CCCCCC]'
              }  drop-shadow-2xl`}
            >
              <div className="flex gap-7 items-center">
                <ChannelsIcon variant={channel} width={36} height={36} />
                <Header fontWeight="semibold" variant="h5">
                  {uppercaseChannelName === 'FACEBOOKMESSENGER'
                    ? 'MESSENGER'
                    : uppercaseChannelName === 'PHONE'
                      ? 'CALLS'
                      : uppercaseChannelName}
                </Header>
              </div>

              {channelConnected?.includes(channel) ? (
                <div className="relative w-[50px] h-[50px]">
                  <Icon
                    type="solid"
                    variant="circleCheck"
                    width={20}
                    height={20}
                    color={'#16A085'}
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    data-testid="greenCheck"
                  />
                </div>
              ) : (error || meta400Error) &&
                onboardingSelectedChannel &&
                onboardingSelectedChannel?.includes(channel) ? (
                <div className="flex justify-center items-center w-[50px] h-[50px]">
                  <Tooltip
                    variant="top"
                    title={
                      meta400Error
                        ? meta400Error
                        : `Error adding ${channelTitle}. Please try again.`
                    }
                    disableVisibleOnClick={true}
                    width={175}
                  >
                    <FontAwesomeIcon
                      icon={faArrowRotateRight}
                      className="text-[#DF2A2A]"
                      data-testid="errorIcon"
                    />
                  </Tooltip>
                </div>
              ) : (
                <div
                  className={`relative w-[50px] h-[50px] rounded-full ${
                    clicked && idx === clicked.idx ? 'bg-[#D9D9D980]' : ''
                  }`}
                >
                  <Icon
                    type="regular"
                    variant="arrowRight"
                    width={20}
                    height={20}
                    color={` ${
                      clicked && idx === clicked.idx ? '#adadad' : '#808080'
                    }`}
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  />
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const OnboardingConnectChannels = ({
  selectedChannels,
  clicked,
  setClicked,
  channelConnected,
  setChannelConnected,
}) => {
  localStorage.setItem('onboardingStep', 2)

  const { channelInfo, connectionError, meta400Error } = useSelector(
    (state) => state.connectChannels,
  )

  useEffect(() => {
    const matchingProviders = channelInfo
      ?.filter((channel) =>
        selectedChannels?.some((selected) => channel.Provider.includes(selected)),
      )
      .map((channel) => channel.Provider)
    if (matchingProviders.length > 0) {
      setChannelConnected(matchingProviders)
    }
  }, [channelInfo])

  return (
    <div className="flex flex-col items-center text-center justify-center bg-white ">
      <Header fontWeight="semibold" variant="h1" className="mb-5">
        {channelInfo?.length > 0
          ? 'Select the next app and sign-in'
          : 'Select one app to sign-in'}
      </Header>
      <ParagraphText
        variant="reg"
        className=" text-center font-normal leading-[22px] mb-[68px] w-[250px]"
      >
        {channelInfo?.length < 0
          ? 'The previous app is now connected. Repeat the process for each app.'
          : 'Command Center will safely connect with the app through a secure API'}
      </ParagraphText>

      <ChannelsContainer
        selectedChannels={selectedChannels}
        channelConnected={channelConnected}
        clicked={clicked}
        setClicked={setClicked}
        error={connectionError}
        meta400Error={meta400Error}
      />
    </div>
  )
}

export default OnboardingConnectChannels
