import moment from 'moment'

export const buildDatePicker = (value) => {
  const startDay = moment(value).clone().startOf('month').startOf('week')
  const endDay = moment(value).clone().endOf('month').endOf('week')
  const day = startDay.clone().subtract(1, 'day')
  const calendarArray = []

  while (day.isBefore(endDay, 'day')) {
    calendarArray.push(
      Array(7)
        .fill(0)
        .map(() => day.add(1, 'day').clone()),
    )
  }

  return calendarArray
}
