import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import { GET_CONNECTED_CHANNELS_BY_USER, GET_LOCATION_BY_ID } from '../graphql'
import useRedux from '../hooks/use-redux'
import {
  setUserPhoneNumber,
  setUserphoneChannelID,
} from '../redux-toolkit/slices/calls/contacts/contacts-slice'
import { setCountryIso2 } from '../components/common/country-code-select/countryCodeSlice'
import { connectVonageChannel } from './vonageAPI'
import { useVonageClient } from './useVonageClient'
import getApolloLink from '../utils/apollo-link'
import { useApolloClient } from '@apollo/client'
import {
  setChanelInfo,
  setConnectedChannelInfo,
  setShowModal,
  setConnectionError,
} from '../components/common/modals/slices/connectChannelsSlice'

export const useProvisionNumber = () => {
  const client = useApolloClient()
  const [isLoading, setIsLoading] = useState(false)
  const [number, setNumber] = useState(null)
  const [isRefetching, setIsRefetching] = useState(false)
  const [dispatch, useSelector] = useRedux()
  const { countryIso2 } = useSelector((state) => state.countryCode)
  const {
    contacts: { connection },
  } = useSelector((state) => state.contacts)
  const {
    user: { businessId, cc_id, dirCode, employee, cc_uid },
    getAccessTokenSilently,
  } = useAuth0()
  useVonageClient()
  const { data: locData } = useQuery(GET_LOCATION_BY_ID, {
    variables: { location_id: businessId },
  })
  const {
    data: chlData,
    // loading: loadingChls,
    refetch: refetchChls,
  } = useQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: {
      loc_id: businessId,
      user_id: cc_uid,
    },
    onCompleted: (chlData) => {
      if (isRefetching && countryIso2) {
        const { queryConnectedChannelsByUser: chls } = chlData
        setIsRefetching(false)
        setConnectedChannel(chls)
      }
      const activeChannels = chlData?.queryConnectedChannelsByUser?.filter(
        (channel) => channel.Provider !== 'CC' && channel.Status !== '0',
      )

      if (activeChannels) {
        dispatch(setChanelInfo(activeChannels))
      }
    },
  })

  const setConnectedChannel = async (chls, isCheckingExistingChannels) => {
    // one cc account will have only one phone channel
    const connectedChannel = chls.find((chl) => chl.Provider === 'phone')
    // if found one, let the app know vonage client can init
    if (connectedChannel) {
      const { AccountUserValue, ChannelID } = connectedChannel
      dispatch(setUserPhoneNumber(AccountUserValue))
      setNumber(AccountUserValue)
      dispatch(setUserphoneChannelID(ChannelID))
      // if not found when provision number
    } else if (!isCheckingExistingChannels) {
      //   console.log('no available number or something happen🚨')
      setNumber('provision failed')
    }

    setIsLoading(false)
  }

  useEffect(() => {
    // only execute once when no connection
    if (locData && chlData && !connection) {
      let {
        queryLocationByID: { CountryCode },
      } = locData
      dispatch(setCountryIso2(CountryCode))
      const { queryConnectedChannelsByUser: chls } = chlData
      setConnectedChannel(chls, true)
    }
  }, [locData, chlData])

  const hardReloadAuthToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently({ cacheMode: 'off' })
      const link = getApolloLink(accessToken)
      client.setLink(link)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error calling hardReloadAuthToken function:', error)
    }
  }

  const provisionNumber = async (areacode) => {
    try {
      setIsLoading(true)
      // provision TFN for us thryv employee
      const accessToken = await getAccessTokenSilently()
      // TODO: Confirm which of the following area codes are allowed
      const validTFNList = ['800', '833', '844', '855', '866', '877', '888']
      // US thryv employee
      if (dirCode === 1 && countryIso2 === 'US' && employee === 'thryv') {
        if (validTFNList.includes(areacode)) {
          await connectVonageChannel(
            accessToken,
            cc_id,
            businessId,
            countryIso2,
            areacode,
            'landline-toll-free',
          )
        }
        // user is not a thryv employee
      } else {
        await connectVonageChannel(
          accessToken,
          cc_id,
          businessId,
          countryIso2,
          areacode,
        )
      }
      setIsRefetching(true)
      refetchChls()
      await hardReloadAuthToken()
    } catch (e) {
      setIsLoading(false)
      dispatch(setConnectedChannelInfo({ provider: 'phone' }))
      dispatch(setShowModal(false))
      dispatch(setConnectionError(true))
      setNumber('provision failed')
    }
  }

  return [number, provisionNumber, isLoading]
}
