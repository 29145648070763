import moment from 'moment'
import { useState, useRef, useContext } from 'react'
import {
  OutgoingCallButton,
  PlayButton,
  ExpandCollapseButton,
} from './voicemail-buttons'
import { VoicemailTranscript } from './voicemail-transcript'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setPrepopulatedNumber } from '../../../../../calls/slices/callSlice'
import phoneFormatter from '../../../../../../utils/phoneFormatter'
import './voicemail-section-styles.css'

import { RenderWaveSurfer } from './render-waveForm'
import { IncomingMessageContext } from '../../../../../../context/message-context'
import { useMediaQuery } from 'react-responsive'

// const fs = require('fs')

/* ----- Reference ---- */
/* https://stackoverflow.com/questions/31337370/how-to-convert-seconds-to-hhmmss-in-moment-js */
export const formatSecondsToMinutes = (seconds) => {
  return moment.utc(seconds * 1000).format('mm:ss')
}

export const formatDateReceived = (date) => {
  return moment(date).local().format('ddd, MMM MM')
}

export const VoicemailSection = ({ className, voicemail, audioInfo }) => {
  const { isVoicemailExist, audioBlob, transcript, isLoadingAudio } = audioInfo
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 835 })

  const [showTranscript, setShowTranscript] = useState(false)

  const [isPlaying, setIsPlaying] = useState(false)
  const [audioLoaded, setAudioLoaded] = useState(false)
  const [replayAudio, setReplayAudio] = useState(false)
  const [loading, setLoading] = useState(false)
  const waveformRef = useRef(null)

  const incomingMessageData = useContext(IncomingMessageContext)

  const handlePlayButtonClick = async () => {
    if (!audioLoaded) {
      setLoading(true)
      setAudioLoaded(true)
    } else {
      //this is for replaying the audio already loaded
      setReplayAudio(!replayAudio)
    }
  }

  const handleOutgoingCallButtonClick = () => {
    const missedCallPhoneNumber = incomingMessageData.number_from
    dispatch(setPrepopulatedNumber(missedCallPhoneNumber))
    navigate('/calls')
  }

  return (
    <div
      className={`${className} justify-evenly px-2 ${isMobile ? 'w-[320px]' : ''}`}
    >
      <div className="flex items-center">
        <OutgoingCallButton
          className="mr-2.5"
          onClick={handleOutgoingCallButtonClick}
        />
        <PlayButton
          className="mr-4"
          onClick={() => {
            handlePlayButtonClick()
          }}
          disabled={!isVoicemailExist || isLoadingAudio}
          showPauseButton={isPlaying}
        />
        {loading && !audioLoaded ? (
          <div className="flex items-center h-[28px]">
            <div
              className={`absolute voicemail-indeterminate-progress-bar opacity-100 left-30`}
            >
              <div className="voicemail-indeterminate-progress-bar__progress"></div>
            </div>
          </div>
        ) : !audioLoaded ? (
          <div className="w-[50%] bg-[#d1d5db] rounded h-1 relative overflow-hidden mr-[8px]"></div>
        ) : (
          <RenderWaveSurfer
            setIsPlaying={setIsPlaying}
            audio={audioBlob}
            ref={waveformRef}
            replayAudio={replayAudio}
            audioLoading={loading}
            setLoading={setLoading}
          />
        )}
        <ExpandCollapseButton
          className={`${loading ? 'absolute right-10' : null}`}
          disabled={transcript === '' ? true : false}
          onClick={() => setShowTranscript(!showTranscript)}
          active={showTranscript}
        />
      </div>

      <VoicemailTranscript
        show={showTranscript}
        phoneNumber={phoneFormatter(incomingMessageData.number_from)}
        dateReceived={moment(voicemail.sk1).local().format('LL')}
        transcript={transcript}
      />
    </div>
  )
}
