import { useState, useEffect } from 'react'
import { ThryvCommandCenterIcon } from '../command-center-icon/icons'
import OnboardingConnectChannels from './OnboardingConnectChannels'
import OnboardingIndustryPage from './OnboardingIndustryPage'
import OnboardingChannelSelector from './OnboardingChannelSelector'
import OnboardingModal from './OnboardingModal'
import OnboardingCarousel from './OnboardingCarousel'
import { useDispatch, useSelector } from 'react-redux'
import {
  setChanelInfo,
  setShowModal,
} from '../common/modals/slices/connectChannelsSlice'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@apollo/client'
import { GET_CONNECTED_CHANNELS_BY_USER } from '../../graphql'
import { VideoChannelModal } from '../common/modals/connect-video-channel-modal/video-channel-modal'
import ImapModal from '../common/modals/imap-modal/ImapModal'
import WebChatModal from '../common/modals/webchat-modal/WebChatModal'
import { ModalWrapper } from '@thryvlabs/maverick'
import { useConnectPhoneModal } from '../common/modals/phone-certification-modal/connect-phone-modal/ConnectPhoneModal'
import { ProgressBar } from './ProgressBar'
import { Footer } from './Footer'

const OnboardingMain = () => {
  const dispatch = useDispatch()
  const [selectedChannels, setSelectedChannels] = useState([])
  const [showConnectionModal, setShowConnectionModal] = useState(false)
  const [successConnection, setSuccessConnection] = useState(true)
  const [step, setStep] = useState(1)
  const [clicked, setClicked] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null) // set to particular channelName or index
  const [channelConnected, setChannelConnected] = useState(null)
  const {
    cardData,
    connectedChannelInfo,
    connectionError,
    modalContent,
    showModal,
    channelInfo,
  } = useSelector((state) => state.connectChannels)
  const { user, getAccessTokenSilently } = useAuth0()
  const [openConnectPhoneModal, , ConnectPhoneModal] = useConnectPhoneModal()

  useEffect(() => {
    if (connectionError) {
      setSuccessConnection(false)
    }
  }, [connectionError])

  useEffect(() => {
    if (connectedChannelInfo) {
      setShowConnectionModal(true)
    }
  }, [connectedChannelInfo])

  const [clickedID, setClickedID] = useState(null)
  useEffect(() => {
    if (clicked?.channel) {
      const matchingCard =
        clicked.channel === 'phone'
          ? cardData?.find((card) => card?.iconName === 'calls')
          : cardData?.find((card) => card?.iconName === clicked.channel)

      if (matchingCard) {
        setClickedID(matchingCard.id)
      }
    }
  }, [clicked, cardData])

  useEffect(() => {
    if (localStorage.getItem('onboardingStep')) {
      setStep(Number(localStorage.getItem('onboardingStep')))
      setSelectedChannels(JSON.parse(localStorage.getItem('onboardingChannels')))
    } else {
      localStorage.setItem('onboarding', true)
      localStorage.setItem('onboardingStep', 1)
      localStorage.setItem('onboardingChannels', JSON.stringify([]))
    }
  }, [])

  const { data } = useQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: {
      loc_id: user.businessId,
      user_id: user.cc_uid,
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const activeChannels = data?.queryConnectedChannelsByUser?.filter(
        (channel) => channel.Provider !== 'CC' && channel.Status !== '0',
      )

      if (activeChannels) {
        dispatch(setChanelInfo(activeChannels))

        if (activeChannels.length === 3) {
          setSelectedChannels([...activeChannels.map((x) => x.Provider)])
          return
        }
        setSelectedChannels([
          ...new Set([
            ...activeChannels.map((x) => x.Provider),
            ...JSON.parse(localStorage.getItem('onboardingChannels') || '[]'),
          ]),
        ])
      }
    },
  })

  return (
    <div
      className="flex md:block fixed inset-0 z-10 bg-gradient-to-b from-transparent to-white"
      data-testid={'onboardingMain'}
    >
      <div className="h-3/4 bg-onboarding-carousel-gradient-1"></div>
      <div
        className="h-1/4 bg-white -mt-12"
        style={{
          clipPath: 'polygon(0% 20%, 100% 0%, 100% 100%, 0% 100%)',
        }}
      ></div>
      <ThryvCommandCenterIcon
        width={180}
        className="hidden md:block md:top-0 md:absolute md:left-1/2 md:transform md:-translate-x-1/2 md:mt-11"
        fill={'white'}
      />
      <div className="flex flex-col w-[57%] md:w-[calc(100%-46px)] md:rounded-lg md:h-[calc(100vh-120px)]  md:bottom-0 md:absolute md:left-1/2 md:transform md:-translate-x-1/2 bg-white h-screen mx-auto z-50 relative overflow-hidden">
        <div className="flex flex-col items-center h-[calc(100%-80px)] overflow-auto ">
          <div className="py-8 flex flex-col gap-2 md:gap-4 items-center">
            <ThryvCommandCenterIcon width={180} className="md:hidden" />
            <ProgressBar
              step={step}
              channelInfo={channelInfo}
              selectedChannels={selectedChannels}
            />
          </div>

          <div className="flex flex-col flex-1 w-full max-w-[580px] items-center bg-cyan-500 px-4 md:px-6 min-h-0">
            {step === 1 && (
              <OnboardingChannelSelector
                setSelectedChannels={setSelectedChannels}
                selectedChannels={selectedChannels}
                setClicked={setClicked}
              />
            )}
            {step === 2 && (
              <OnboardingConnectChannels
                clicked={clicked}
                setClicked={setClicked}
                selectedChannels={selectedChannels}
                user={user}
                setChanelInfo={setChanelInfo}
                channelConnected={channelConnected}
                setChannelConnected={setChannelConnected}
              />
            )}
            {step === 3 && (
              <OnboardingIndustryPage clicked={clicked} setClicked={setClicked} />
            )}
            {modalContent === 6 ? (
              <ModalWrapper
                show={showModal}
                close={() => dispatch(setShowModal(false))}
              >
                <ImapModal clickedID={clickedID} />
              </ModalWrapper>
            ) : null}

            {modalContent === 8 ? (
              <ModalWrapper
                show={showModal}
                close={() => dispatch(setShowModal(false))}
              >
                <VideoChannelModal clickedID={clickedID} />
              </ModalWrapper>
            ) : null}

            {modalContent === 9 && (
              <ModalWrapper
                show={showModal}
                close={() => dispatch(setShowModal(false))}
              >
                <WebChatModal clickedID={clickedID} />
              </ModalWrapper>
            )}
          </div>
        </div>

        <Footer
          step={step}
          setStep={setStep}
          clicked={clicked}
          setClicked={setClicked}
          cardData={cardData}
          dispatch={dispatch}
          selectedChannels={selectedChannels}
          channelInfo={channelInfo}
          getAccessTokenSilently={getAccessTokenSilently}
          user={user}
          openConnectPhoneModal={openConnectPhoneModal}
          channelConnected={channelConnected}
        />
      </div>

      <div className="w-[43%] h-full bg-white md:hidden">
        <OnboardingCarousel step={step} />
      </div>

      <OnboardingModal
        show={showConnectionModal}
        completed={false}
        close={() => {
          setShowConnectionModal(false)
        }}
        successConnection={successConnection}
      />
      {ConnectPhoneModal}
    </div>
  )
}

export default OnboardingMain
