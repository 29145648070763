import { CardTitle, ParagraphText, Toggle, Select } from '@thryvlabs/maverick'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useQuery, useMutation } from '@apollo/client'
import { FETCH_LANGUAGE } from '../../../graphql/queries/settings-queries'
import {
  UPDATE_LANGUAGE,
  UPDATE_AUTO_TRANSLATE,
} from '../../../graphql/mutations/user-preferences-mutation'
import { useSelector } from 'react-redux'
import { setUserInfo } from '../../../redux-toolkit/slices/user-info/user-info.slice'

const options = [
  { value: 'en', name: 'English' },
  { value: 'es', name: 'Spanish' },
  { value: 'fr', name: 'French' },
  { value: 'it', name: 'Italian' },
  { value: 'ko', name: 'Korean' },
]

export default function TranslationSettings() {
  const [selectedOption, setSelectedOption] = useState({ name: '', value: '' })
  const { data, refetch } = useQuery(FETCH_LANGUAGE)
  const [updateLanguage] = useMutation(UPDATE_LANGUAGE)
  const [updateAutoTranslate] = useMutation(UPDATE_AUTO_TRANSLATE)
  const [autoTranslate, setAutoTranslate] = useState(false)
  const user = useSelector((state) => state.user)

  useEffect(() => {
    const language = data?.queryAgentLanguage
    setSelectedOption(options.find((option) => option.value === language))

    const translateSetting = data?.queryAutoTranslation
    setAutoTranslate(translateSetting)

    setUserInfo({
      ...user,
      language: language,
      translate: translateSetting,
    })
  }, [data])

  const onChange = async (value) => {
    setSelectedOption(value)
    await updateLanguage({ variables: { language: value.value } })
    refetch()
  }

  const onChangeTranslate = async (value) => {
    setAutoTranslate(value)
    await updateAutoTranslate({ variables: { autoTranslate: value } })
    refetch()
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="">
        <CardTitle variant="primary">AI Translate</CardTitle>
      </div>
      <ParagraphText variant="reg" color="thryv-night">
        Enables users to translate text to your preferred language.
      </ParagraphText>
      <div className="flex justify-between my-5">
        <ParagraphText variant="lg" color="thryv-night">
          Translate Automatically
        </ParagraphText>
        <StyledToggle toggled={autoTranslate} onChange={onChangeTranslate} />
      </div>
      {autoTranslate && (
        <Select
          className="select-none"
          options={options}
          selectLabel="Preferred Language"
          width="lg"
          selectedOption={selectedOption}
          setSelectedOption={onChange}
          withLabel
        />
      )}
    </div>
  )
}

const StyledToggle = styled(Toggle)`
  & > button {
    ${({ toggled }) => !toggled && 'border-color: #808080;'}

    ${({ toggled }) =>
      toggled ? 'background-color: #16A085;' : 'background-color: #808080'}
  }
`
