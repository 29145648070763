import { useState, useEffect } from 'react'
import { ParagraphText } from '@thryvlabs/maverick'

export const ProgressBar = ({ step, channelInfo, selectedChannels = [] }) => {
  const totalBars = 5
  const activeChannels = channelInfo?.filter(
    (channel) => channel.Provider !== 'CC' && channel.Status !== '0',
  )
  const connectedChannels = activeChannels?.filter((x) => x.Provider).length
  const selectedChannelsCount = selectedChannels.length

  const [percentage, setPercentage] = useState(20)
  const [savedProgress, setSavedProgress] = useState(0)
  const [step2Progress, setStep2Progress] = useState(0)

  useEffect(() => {
    let newPercentage = savedProgress

    if (selectedChannelsCount === 0) {
      if (step === 4) {
        newPercentage = 100
        newPercentage = Math.round(newPercentage)
        setPercentage(Math.round(newPercentage))
      }
      return
    }

    if (step === 1) {
      if (savedProgress === 0 || step2Progress === 0) {
        newPercentage = 20
      } else {
        newPercentage = step2Progress
      }
    }

    if (step === 2) {
      newPercentage = step2Progress
    }

    if (step === 3) {
      newPercentage = 80
    }

    if (step === 4) {
      newPercentage = 100
    }

    newPercentage = Math.round(newPercentage)

    if (newPercentage === 47) {
      newPercentage = 50
    }

    setSavedProgress((prevProgress) => Math.max(prevProgress, newPercentage))
    setPercentage(Math.round(newPercentage))
  }, [step, connectedChannels, selectedChannelsCount, savedProgress, step2Progress])

  useEffect(() => {
    if (step !== 2) return

    if (selectedChannelsCount > 0) {
      const progressPerChannel = 40 / selectedChannelsCount
      const channelProgress = connectedChannels * progressPerChannel
      const updatedProgress = 20 + channelProgress
      setStep2Progress(updatedProgress)
      setSavedProgress(Math.max(savedProgress, updatedProgress))
    }
  }, [selectedChannelsCount, connectedChannels, step])

  useEffect(() => {
    if (step === 2) {
      if (Math.round(step2Progress) === 47) {
        setPercentage(50)
      } else {
        setPercentage(Math.round(step2Progress))
      }
    }
  }, [step, step2Progress])

  const fullBars = Math.floor(percentage / 20)
  const partialBarWidth = ((percentage % 20) * 72) / 20

  return (
    <div
      className="w-[444px] md:w-[290px] h-[20px] flex gap-[8px] items-center"
      data-testId={'onboardingProgressBar'}
    >
      {Array(totalBars)
        .fill()
        .map((_, index) => {
          if (index < fullBars) {
            return (
              <div
                key={index}
                className="w-[72px] h-[8px] rounded-[10px] bg-[#057AFF]"
              ></div>
            )
          } else if (index === fullBars && percentage % 20 !== 0) {
            return (
              <div
                key={index}
                className="w-[72px] h-[8px] rounded-[10px] bg-[#E3E6E8]"
              >
                <div
                  className="h-full rounded-[10px] bg-[#057AFF]"
                  style={{ width: `${partialBarWidth}px` }}
                ></div>
              </div>
            )
          } else {
            return (
              <div
                key={index}
                className="w-[72px] h-[8px] rounded-[10px] bg-[#E3E6E8]"
              ></div>
            )
          }
        })}
      <ParagraphText
        variant="reg"
        color="utility-link-info"
        className="pl-[16px] md:hidden"
      >
        {percentage}%
      </ParagraphText>
    </div>
  )
}
