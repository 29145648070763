import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStop } from '@fortawesome/pro-solid-svg-icons'
import { faMicrophone } from '@fortawesome/pro-regular-svg-icons'
import {
  setEditorView,
  handleClickFormatText,
  setShowFormattingToolbar,
} from '../../../slices/messageComposeSlice'
import { useEffect, useState } from 'react'
// Components
import { CommandCenterIcon } from '../../../../command-center-icon'
import { EmojiMenu } from '../emojis/emoji-menu'
import { HyperlinkModal } from '../hyperlinks/hyperlink-modal'
import { ActionButton } from './action-button'
import { useInboxMenuConversations } from '../../../inbox-menu/hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import { DELETE_MESSAGE } from '../../../../../graphql'
import { useMutation } from '@apollo/client'
import { setError } from '../../../slices/inboxSlice'
import { useMediaQuery } from 'react-responsive'
import { useLazyQuery } from '@apollo/client'
import { FETCH_THREADS } from '../../../../../graphql'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { setEditorContents } from '../../../slices/messageComposeSlice'

export const LeftActionButtons = ({
  disableButtons,
  getInputProps,
  quillEditorRef,
  limitAttachmentError,
}) => {
  const iconFill = '#808080'

  const { transcript, listening, resetTranscript } = useSpeechRecognition()

  const { editorView, isEditorFocused, showFormattingToolbar, selectedChannel } =
    useSelector((s) => s.messageCompose)
  const createMessageError = useSelector((state) => state.inbox.error.status)
  const { draftPk1, draftChanPk } = useSelector((state) => state.inbox)

  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )

  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 640 })
  const [hideToolbar, setHideToolbar] = useState(false)

  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()

  useEffect(() => {
    if (listening) {
      dispatch(setEditorContents({ text: transcript, html: `<p>${transcript}</p>` }))
    }
  }, [transcript, listening])

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const [deleteMessage] = useMutation(DELETE_MESSAGE, {
    variables: {
      pk1: draftPk1,
      sk1: 9999999999999,
      channel_id: draftChanPk,
      thread_id: selectedThreadId,
    },
    onCompleted: () => {
      dispatch(setError({ status: false, message: '' }))
      getThreads()
    },
  })

  const handleUploadAfterError = () => {
    if (createMessageError) {
      deleteMessage()
    }
  }

  // This fixes the horizontal scroll issue for inbox in mobile view
  useEffect(() => {
    if (isMobile && editorView === 'closed') {
      setHideToolbar(true)
    } else {
      setHideToolbar(false)
    }
  }, [isMobile, editorView])

  return (
    <div
      id="text-action-buttons"
      className={hideToolbar ? 'hidden' : 'flex items-center justify-evenly gap-x-1'}
    >
      {listening ? (
        <div className="pt-1">
          <ActionButton
            title="Stop Listening"
            disabled={
              (disableButtons && selectedChannel?.Provider !== 'webchat') ||
              !isEditorFocused
            }
            isActive={showFormattingToolbar}
          >
            <FontAwesomeIcon
              onClick={() => {
                SpeechRecognition.stopListening()
              }}
              color={iconFill}
              className="h-[22px] w-[22px] cursor-pointer"
              icon={faStop}
            />
          </ActionButton>
        </div>
      ) : (
        <div className="pt-1">
          <ActionButton
            title="Speech to Text"
            disabled={
              (disableButtons && selectedChannel?.Provider !== 'webchat') ||
              !isEditorFocused
            }
            isActive={showFormattingToolbar}
          >
            <FontAwesomeIcon
              color={iconFill}
              onClick={() => {
                resetTranscript()
                SpeechRecognition.startListening({
                  continuous: true,
                  language: 'es-US',
                })
              }}
              className="h-[20px] w-[20px] cursor-pointer"
              icon={faMicrophone}
            />
          </ActionButton>
        </div>
      )}

      {selectedChannel?.Provider !== 'webchat' ? (
        <>
          {/* FORMATTING TOOLBAR */}
          <ActionButton
            dataTestID="format-text"
            title="Format Text"
            disabled={disableButtons || !isEditorFocused}
            isActive={showFormattingToolbar}
            onBtnClick={() => dispatch(handleClickFormatText())}
          >
            <CommandCenterIcon
              variant="fontFormat"
              type="regular"
              width={20}
              height={20}
              fill={iconFill}
            />
          </ActionButton>
          {/* ATTACH FILES */}
          <ActionButton
            dataTestID="attach-files"
            title="Attach Files"
            disabled={disableButtons || limitAttachmentError || !isEditorFocused}
            onBtnClick={() => {
              showFormattingToolbar
                ? dispatch(setShowFormattingToolbar(false))
                : null
              handleUploadAfterError()
            }}
          >
            <label
              htmlFor="message-compose-upload-file"
              style={{
                cursor: `${
                  disableButtons || !isEditorFocused ? 'default' : 'pointer'
                }`,
              }}
            >
              <CommandCenterIcon
                variant="paperClip"
                type="regular"
                width={20}
                height={20}
                fill={iconFill}
              />
              <input
                disabled={disableButtons || !isEditorFocused}
                id="message-compose-upload-file"
                {...getInputProps()}
                onChangeCapture={() =>
                  editorView === 'closed' && dispatch(setEditorView('open'))
                }
              />
            </label>
          </ActionButton>
          {/* HYPERLINKS */}
          <HyperlinkModal
            quillEditorRef={quillEditorRef}
            disableButtons={disableButtons || !isEditorFocused}
          />
          {/* EMOJIS */}
          <EmojiMenu
            quillEditorRef={quillEditorRef}
            disableButtons={disableButtons || !isEditorFocused}
          />
        </>
      ) : null}

      {/* LABELS */}
      {/* Disable Labels Unless Create Message Has Label Added To Mutation */}
      {/* <LabelsButtonWithMenu /> */}

      <CommandCenterIcon variant="verticalLine" type="regular" className="mr-2" />
    </div>
  )
}
