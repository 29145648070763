import { Transition } from '@headlessui/react'

// Components
import { FaCircleExclamation } from 'react-icons/fa6'
import { IoCloseSharp } from 'react-icons/io5'
import { FaExclamationTriangle } from 'react-icons/fa'

const CloseButton = ({ onClick, className, disable, ...rest }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${className} ${disable && 'hidden'} h-[20px] w-[20px]`}
      {...rest}
    >
      <IoCloseSharp color="#808080" size={20} />
    </button>
  )
}

const ExclamationMarkIcon = () => {
  return (
    <div className="h-[16px] w-[16px]">
      <FaCircleExclamation color="#DF2A2A" size={16} />
    </div>
  )
}

const ExclamationTriangleIcon = () => {
  return (
    <div className="h-[16px] w-[16px]">
      <FaExclamationTriangle color="#FAC02B" size={16} />
    </div>
  )
}

const NotificationContainer = ({ className, children, show }) => {
  return (
    <Transition
      show={show}
      className={`${className} rounded-[10px]`}
      enter="transition ease-in duration-300 relative"
      enterFrom="transform opacity-0"
      enterTo="transform opacity-100"
      leave="transition ease-out duration-200"
      leaveFrom="transform opacity-100"
      leaveTo="transform opacity-0"
      unmount={false}
      style={{
        transformOrigin: 'center',
      }}
    >
      {children}
    </Transition>
  )
}

const Text = ({ children }) => (
  <p className="text-[14px] font-open-sans text-[#231F20] font-normal text-start leading-[20px] [overflow-wrap:anywhere]">
    {children}
  </p>
)

export const CommandCenterNotification = ({
  className,
  show,
  onCloseClick,
  variant = 'success', // success, warning, failure
  disableCloseButton,
  children,
}) => {
  let defaultStyles =
    'rounded py-[8px] px-[12px] rounded border border-[1px] border-solid flex justify-between'

  const closeToast = () => {
    onCloseClick()
  }

  if (variant === 'failure') {
    return (
      <NotificationContainer show={show} className={className}>
        <div className={`${defaultStyles} border-[#EF9595] bg-[#FCEAEA]`}>
          <div className="flex gap-[8px]">
            <ExclamationMarkIcon />
            <Text>{children}</Text>
          </div>

          <CloseButton
            aria-label="Close Error Toast"
            onClick={closeToast}
            disable={disableCloseButton}
          />
        </div>
      </NotificationContainer>
    )
  }

  if (variant === 'warning') {
    return (
      <NotificationContainer show={show} className={className}>
        <div className={`${defaultStyles} border-[#FDDF95] bg-[#FFF9EA]`}>
          <div className="flex items-start gap-[8px]">
            <ExclamationTriangleIcon />
            <Text>{children}</Text>
          </div>

          <CloseButton
            aria-label="Close Error Toast"
            onClick={closeToast}
            disable={disableCloseButton}
          />
        </div>
      </NotificationContainer>
    )
  }
}
